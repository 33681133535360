import { Column } from "./Types";
import React from "react";
import styles from "./DataGridView.css";
import { OperationMode } from "./OperationMode";
import { isNumber } from "lodash";

type RowItemProps<T> = {
  rowData: T;
  column: Column<T>;
  index: number;
};

export const RowItem = <T,>({ rowData, column, index }: RowItemProps<T>) => {
  return (
    <div
      key={column.field}
      className={index ? styles.valueItem : styles.operationItem}
      style={column.customStyleFn ? column.customStyleFn(rowData) : {}}
    >
      {column.field === "operationMode" ? (
        <OperationMode profile={rowData[column.field] as string} />
      ) : (
        <>
          {getValue(rowData, column)}
          {!!column.unit && <span>{column.unit}</span>}
        </>
      )}
    </div>
  );
};

const getValue = <T,>(data: T, column: Column<T>): number | string => {
  const value = data[column.field];
  return column.toFixed && value && isNumber(value)
    ? (value as number).toFixed(column.toFixed)
    : (value as number | string);
};
