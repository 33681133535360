import { Option } from "src/common/components/RadioButton";
import { CIIScores } from "src/onshore/views/FleetCIIScore/Types";

export interface IState {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  pageContext: any;
  fuelEmissions: IFuelEmissionsState;
}

export interface IFuelEmissionsState {
  fuelConsumption: IFuelConsumptionState;
  donutData: IDonutDataState;
  emissionsTrend: IEmissionsTrendState;
  emissionsData: IEmissionsByOperationsState;
  fuelAndTarget: IFuelAndTargetState;
  setTargetFuelDataDialog: ISetTargetFuelDataDialogState;
  isLoading: boolean;
}

// =============Target Fuel Data Dialog State================
export interface ISetTargetFuelDataDialogState {
  visible: boolean;
  data?: IFuelTargetsData;
  hasChanges?: boolean;
  isLoading?: boolean;
  error?: string;
}

export interface IFuelTargetsData {
  lastUpdate: string;
  fuels: string[];
  data: IOperationTargetsData[];
}

export interface IOperationTargetsData {
  operationMode: string;
  targets: ITarget[];
  hasChanges?: boolean;
}

export interface ITarget {
  fuelType: string;
  value: number;
}

// =================Fuel Consumption State====================

export interface IFuelConsumptionState {
  fuels: Option[];
  consumptions: IFuelConsumption[];
  selected: IFuelConsumptionPerOperationGroup[];
  isLoading: boolean;
}

export interface IFuelConsumptionPerOperationGroup {
  operationsGroup: string;
  operationsGroupName: string;
  operations: string[];
  operationsValue: IOperationsValue;
  trendData: ITrendData[];
}
interface IFuelConsumption {
  fuel: string;
  consumptions: number;
}
interface IOperationsValue {
  value: number;
  unit: string;
  performance: number;
}
export interface ITrendData {
  x: number;
  y: number;
}

// ======================FuelAndTarget State==========================

export interface IFuelAndTargetState {
  fuels: Option[];
  filteredData: IFuelAndTargetItem[];
  data: object;
  isLoading: boolean;
  error?: string;
}

export interface IFuelAndTargetItem {
  [key: string]: number | string; // Not in use, this is just to help typescript with dynamic property access
  operationMode: string;
  runTime: number | string;
  actualFuelUse: number | string;
  targetFuelUse: number | string;
}

// ======================Emissions State==========================

export interface IEmissionsByOperationsState {
  data: IEmissionsData[];
  isLoading: boolean;
}

export interface IEmissionsData {
  cO2: number;
  nOx: number;
  sOx: number;
  operationMode: string;
}

// ======================Donut State==========================

export interface IDonutDataState {
  data: IDonutData[];
  isLoading: boolean;
}

export interface IDonutData {
  type: string;
  title: string;
  itemTypeMetricId: string;
  metricId: string;
  unit: string;
  total: number;
  fromDate: string;
  toDate: string;
  data: IDonutOperationProfileData[];
}

interface IDonutOperationProfileData {
  value: number;
  operationalProfileId: string;
  operationalProfile: IOperationalProfile;
}

interface IOperationalProfile {
  id: string;
  category: string;
  profile: string;
  legend: string;
  sortKey: number;
}

// ======================Emissions Trend State==========================
export interface IEmissionsTrendState {
  options: Option[];
  selected: keyof typeof EmissionsTrendSelected;
  series: IEmissionsSeries;
  isLoading: boolean;
}

export interface IEmissionsSeries {
  cii: CIIScores;
  emissions: IPoint[][];
}
export const EmissionsTrendSelected = {
  Emissions: "Emissions",
  CiiTrend: "CII Trend",
};

export interface IPoint {
  x: string;
  y: number;
}
