import React from "react";
import styles from "./OperationHeaderCell.css";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../../../store/rootState";
import { OperationId } from "../../../../../../common/operation/operationId";
import { sortByOperation } from "../../../reducer.fleetPerformance";
import { Direction } from "../../../../../common/direction";
import { Triangle } from "../../Triangle";
import { OperationCircle } from "../../../../../../common/components/Operation/OperationCircle";

interface Props {
  operationId: OperationId;
}

export const OperationHeaderCell = ({ operationId }: Props) => {
  const dispatch = useDispatch();
  const sortingOrder = useAppSelector(
    (state) => state.fleetPerformance.sortingOrder
  );
  const isActive = useAppSelector(
    (state) =>
      state.fleetPerformance.sorting.type === "operation" &&
      state.fleetPerformance.sorting.sorting === operationId
  );
  const onClick = () =>
    dispatch(
      sortByOperation({
        sortingOrder:
          isActive && sortingOrder === Direction.LowToHigh
            ? Direction.HighToLow
            : Direction.LowToHigh,
        operationId,
      })
    );

  return (
    <div className={styles.container}>
      <button className={styles.button} onClick={onClick}>
        <div className={styles.text}>
          <OperationCircle operationId={operationId} />
        </div>
        {isActive && <Triangle direction={sortingOrder} />}
      </button>
    </div>
  );
};
