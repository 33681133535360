import React from "react";
import t from "prop-types";
import classNames from "../../classNames";
import styles from "./Layout.css";
import User from "../User";
import { Nav } from "../Nav";
import brandLogo from "../../../style/img/logo.png";

const Layout = ({
  appTitle,
  user,
  menu,
  pages,
  isNavOpen,
  toggleNav,
  logOut,
  navigateToUserProfile,
  navigateToPage,
  children,
  versionInfo,
}) => {
  const navigateAndToggleNav = (event, page) => {
    if (navigateToPage) {
      event.preventDefault();
      navigateToPage(page);
    }
    // toggle nav when link clicked
    toggleNav();
  };

  return (
    <div className={classNames(styles.body, isNavOpen && styles.navActive)}>
      <div className={styles.bodyInner}>
        <aside className={styles.mainAside}>
          <div onClick={toggleNav}>
            <div
              className={styles.brandLogo}
              style={{ backgroundImage: `url(${brandLogo})` }}
            />
            <h1 className={styles.appTitle}>{appTitle}</h1>
          </div>
          <div className={styles.navContainer}>
            <Nav
              navClickAction={navigateAndToggleNav}
              menu={menu}
              views={pages}
              disabled={!isNavOpen}
            />
          </div>
          {Boolean(user) && (
            <User
              user={user}
              logOut={logOut}
              userNameClicked={navigateToUserProfile}
              disabled={!isNavOpen}
            />
          )}
          {versionInfo?.()}
        </aside>
        <main id="viewContainer" className={styles.viewContainer}>
          <div className={styles.viewItem}>
            <div className={styles.closeNavOverlay} onClick={toggleNav} />
            {children}
          </div>
        </main>
      </div>
      <div className={styles.popupContainer} id={"popupContainer"} />
    </div>
  );
};

Layout.propTypes = {
  appTitle: t.string.isRequired,
  user: t.object,
  pages: t.object.isRequired,
  menu: t.array.isRequired,
  isNavOpen: t.bool.isRequired,
  toggleNav: t.func.isRequired,
  logOut: t.func,
  navigateToUserProfile: t.func,
  children: t.oneOfType([t.object, t.array]),
  versionInfo: t.object,
};

export default Layout;
