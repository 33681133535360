import { FETCH } from "../actions/action.types";
import { isEqual, get } from "lodash";

// Helper functions
const key = (action) => action.key || action.actionType;
const actionData = (action) => action.actionData || {};

const inProgress = (actionIndex, action) => {
  const candidate = actionIndex[key(action)];
  return (
    candidate &&
    candidate.url === action.url &&
    isEqual(candidate.payload, action.payload)
  );
};

const handleResponse =
  (actionIndex, action, dispatch, createResponseAction, isSuccess) => (res) => {
    const responseAction = createResponseAction(action, res);
    if (action.takeAll) {
      dispatch(responseAction);
    } else if (actionIndex[key(action)] === action) {
      delete actionIndex[key(action)];
      dispatch(responseAction);
    }
    if (isSuccess && action.onSuccess) {
      action.onSuccess(responseAction);
    } else if (!isSuccess && action.onError) {
      action.onError(responseAction);
    }
  };

const requestAction = (baseAction) => ({
  type: baseAction.actionType,
  ...actionData(baseAction),
});

const successAction = (baseAction, res) => ({
  type: `${baseAction.actionType}_SUCCESS`,
  data: res.data,
  ...actionData(baseAction),
});

const errorAction = (baseAction, err) => ({
  type: `${baseAction.actionType}_ERROR`,
  error: err.message,
  status: get(err, ["response", "status"]),
  data: get(err, ["response", "data"]),
  ...actionData(baseAction),
});

const makeHttpRequest = (http, action, store) => {
  http({
    method: action.method || "get",
    url: action.url,
    data: action.payload,
  })
    .then(
      handleResponse(
        store.actionIndex,
        action,
        store.dispatch,
        successAction,
        true
      )
    )
    .catch((err) => {
      handleResponse(
        store.actionIndex,
        action,
        store.dispatch,
        errorAction,
        false
      )(err);
      return Promise.reject(err);
    });
};

// Main function
export default ({ http }) => {
  const actionIndex = {};

  return (store) => (next) => (action) => {
    if (!action || action.type !== FETCH) {
      return next(action);
    }
    if (inProgress(actionIndex, action)) {
      return;
    }

    store.dispatch(requestAction(action));

    if (!action.takeAll) {
      actionIndex[key(action)] = action;
    }

    makeHttpRequest(http, action, { ...store, actionIndex });
  };
};
