import React from "react";
import { Column } from "./Types";
import { RowItem } from "./RowItem";

type RowProps<T> = {
  rowData: T;
  rowIdSelector: (row: T) => string;
  columns: Column<T>[];
};

export const Row = <T,>({ rowData, columns, rowIdSelector }: RowProps<T>) => {
  return (
    <>
      {columns.map((column, index) => {
        const key = `column_${rowIdSelector(rowData)}_${column.field}`;

        return (
          <RowItem
            rowData={rowData}
            column={column}
            index={index}
            key={`column_${key}`}
          />
        );
      })}
    </>
  );
};
