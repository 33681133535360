import React from "react";
import styles from "./RenderDot.css";

type RenderDotProps = {
  status: number;
};

const RenderDot: React.FC<RenderDotProps> = ({ status }) => {
  let dotClass: string;
  switch (status) {
    case 0:
      dotClass = styles.greyDot;
      break;
    case 1:
      dotClass = styles.redDot;
      break;
    case 2:
      dotClass = styles.greenDot;
      break;
    case 3:
      dotClass = styles.emptySpace;
      break;
    default:
      dotClass = styles.defaultDot;
      break;
  }
  return <div className={dotClass}></div>;
};

export default RenderDot;
