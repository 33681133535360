import React, { useMemo } from "react";
import { LogoProps } from "./Types";
import styles from "./Logo.css";
import {
  operationClassToOperations,
  operationName,
} from "../../../common/operation/operationId";
import {
  operationClassLegend,
  operationClassTitle,
} from "../../../common/operation/operationClassID";
import { OperationClassCircles } from "../../../common/components/Operation/OperationClassCircles";
import { LogoModes } from "../../components/Common/LogoModes/LogoModes";

const Logo: React.FC<LogoProps> = ({ operationType }: LogoProps) => {
  const modesString: string = useMemo(
    () =>
      operationClassToOperations[operationType]
        .map((operationId) => operationName[operationId])
        .join(", "),
    [operationType]
  );

  const titleString = useMemo(
    () => `${operationClassTitle[operationType]} performance`,
    [operationType]
  );
  const legend = useMemo(
    () => operationClassLegend[operationType],
    [operationType]
  );

  return (
    <div className={styles.logoContainer} data-test-id={`${legend}-logo`}>
      <OperationClassCircles operationClassId={operationType} />
      <div>
        <LogoTitle titleString={titleString} testId={legend} />
        <LogoModes
          modesString={modesString}
          titleStringLength={titleString.length * 2.2}
          testId={legend}
        />
      </div>
    </div>
  );
};

type LogoTitleProps = {
  titleString: string;
  testId: string;
};

const LogoTitle: React.FC<LogoTitleProps> = ({
  titleString,
  testId,
}: LogoTitleProps) => (
  <div className={styles.logoTitle} data-test-id={`${testId}-title`}>
    {titleString}
  </div>
);

export default Logo;
