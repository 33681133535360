import React, { useMemo } from "react";
import styles from "./OperationClassCircles.module.css";
import {
  operationClassColor,
  OperationClassID,
  operationClassLegend,
} from "../../operation/operationClassID";

interface Props {
  readonly operationClassId: OperationClassID;
}

export const OperationClassCircles = ({ operationClassId }: Props) => {
  const backgroundColor = useMemo(
    () => operationClassColor[operationClassId],
    [operationClassId]
  );

  const legend = useMemo(
    () => operationClassLegend[operationClassId],
    [operationClassId]
  );

  return (
    <div className={styles.circlesWrapper} data-test-id={`${legend}-icon`}>
      <div
        className={styles.circle}
        style={{ backgroundColor, opacity: 0.2 }}
      ></div>
      <div
        className={styles.circle}
        style={{ backgroundColor, opacity: 0.4 }}
      ></div>
      <div className={styles.circle} style={{ backgroundColor }}>
        <span>{legend}</span>
      </div>
    </div>
  );
};
