import styles from "./DataGridView.css";
import React from "react";
import { Column } from "./Types";

type HeadersProps<T> = {
  columns: Column<T>[];
};

export const Headers = <T,>({ columns }: HeadersProps<T>) => {
  return (
    <>
      {columns.map((c) => (
        <div className={styles.headerItem} key={c.field}>
          {c.name ?? " "}
        </div>
      ))}
    </>
  );
};
