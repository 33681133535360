import React, { useEffect, useRef, useState } from "react";

/**
 *
 * Get the height and width of a html element which also updates whenever the size changes.
 */
export const useRefSize = <T extends HTMLElement>(): [
  React.RefObject<T>,
  number,
  number
] => {
  const ref = useRef<T>(null);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const measure = () => {
    const width = ref.current?.offsetWidth ?? 0;
    const height = ref.current?.offsetHeight ?? 0;
    setWidth(width);
    setHeight(height);
  };

  useEffect(() => {
    if (!ref.current) {
      console.warn(
        "The ref was not set when trying to set up resizeObserver so no observer will be created. Make sure that the ref element is not conditionally rendered and not null on first render!"
      );
      return;
    }
    const resizeObserver = new ResizeObserver(measure);
    resizeObserver.observe(ref.current);
    return () => resizeObserver.disconnect();
  }, []);

  return [ref, width, height];
};
