import React from "react";
import styles from "./LogoModes.css";
import { truncate } from "../../../../common/strings";

type LogoModesProps = {
  modesString: string;
  titleStringLength: number;
  testId: string;
};

export const LogoModes: React.FC<LogoModesProps> = ({
  modesString,
  titleStringLength,
  testId,
}: LogoModesProps) => (
  <div
    className={styles.logoModes}
    data-test-id={`${testId}-modes`}
    title={modesString
      .split(",")
      .map((mode) => mode.trim())
      .join("\n")}
  >
    <span className={styles.displayText}>
      {truncate(modesString, titleStringLength)}
    </span>
  </div>
);
