import React, { useMemo } from "react";
import styles from "./FuelConsumptionRow.css";
import { FuelConsumptionTrend } from "./FuelConsumptionTrend";
import { IFuelConsumptionPerOperationGroup } from "../commonTypes";
import { PerformanceIndicator } from "./PerformanceIndicator";
import { OperationClassCircles } from "../../../../common/components/Operation/OperationClassCircles";
import { getOperationFromLegend } from "../sortByOperationLegend";
import {
  OperationClassID,
  operationClassLegend,
  operationClassTitle,
} from "../../../../common/operation/operationClassID";
import { LogoModes } from "../../Common/LogoModes/LogoModes";

interface Props {
  fuelConsumption: IFuelConsumptionPerOperationGroup;
}

export function FuelConsumptionRow({ fuelConsumption }: Props): JSX.Element {
  const {
    operationsGroup,
    operationsGroupName,
    operationsValue,
    trendData,
    operations,
  } = fuelConsumption;

  const { classId, title } = useMemo(
    () =>
      getOperationGroupAttributes(
        getOperationFromLegend(operationsGroup, operationClassLegend)
      ),
    [operationsGroup]
  );

  return (
    <div style={{ display: "flex" }}>
      <div className={styles.blockContainer}>
        <div className={styles.operationsGroupContainer}>
          <OperationClassCircles operationClassId={classId} />
        </div>
        <div className={styles.fuelConsumptionValueContainer}>
          <div className={styles.fuelConsumptionTitle}>{title}</div>
          <div className={styles.fuelConsumptionValue}>
            <div>
              <span>{operationsValue.value}</span>
              <span>{operationsValue.unit}</span>
            </div>
            <LogoModes
              modesString={operations.join(", ")}
              titleStringLength={title.length * 2.2}
              testId={operationsGroupName}
            />
          </div>
        </div>
        <PerformanceIndicator performance={operationsValue.performance} />
      </div>
      <div className={styles.blockContainer} style={{ padding: "0 1rem" }}>
        <FuelConsumptionTrend data={trendData} />
      </div>
    </div>
  );
}

const getOperationGroupAttributes = (classId: OperationClassID) => ({
  classId,
  title: operationClassTitle[classId] + " " + "modes",
});

export default FuelConsumptionRow;
