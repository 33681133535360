import React from "react";
import AutoSizer from "../../../common/components/AutoSizer/AutoSizer";
import Sparkline from "../../../common/components/Sparkline/Sparkline";
import SparklineCustomElement from "../../../common/components/Sparkline/SparklineCustomElement";
import SparklineGrid from "../../../common/components/Sparkline/SparklineGrid";
import styles from "./TrendGraphView.css";
import { prepareOperationGraphData } from "../../reducers/reducer.trend";
import memoize from "memoize-one";
import { OPERATION_MODES } from "../../../common/config";
import * as d3 from "d3";
import SparklineHover from "../../../common/components/Sparkline/SparklineHover";
import { secondsToTimeString } from "../../common/dates";
import { get } from "lodash";

const renderOperations = function (data, scaleX, height, hoveredData) {
  if (!data?.[0]) return null;

  const domainArray = Array.from(
    {
      length: Math.max(
        1,
        d3.max(data[0], (d) => d.y)
      ),
    },
    (_, i) => i
  );

  const scaleY = d3
    .scaleBand()
    .range([height, 0])
    .domain(domainArray)
    .padding(0)
    .round(true);

  const yDiff = scaleY.bandwidth() / 2;

  return data[0].map((d, di) => (
    <g key={`operation_group_${di}`}>
      {d?.operations?.map((o) => (
        <line
          key={`operation_${o.operationId}`}
          x1={scaleX(d.x)}
          x2={scaleX(o.endTime)}
          y1={(scaleY(o.z) || 0) + yDiff}
          y2={(scaleY(o.z) || 0) + yDiff}
          stroke={this.getOperationColor(o.operationLegend)}
          strokeOpacity={!hoveredData || hoveredData === d ? 1 : 0.25}
          strokeWidth={`${scaleY.bandwidth()}px`}
          data-test-id="trend-operationDataLine"
        />
      ))}
    </g>
  ));
};

const customValueFunction = ({ mVal }, aggregated) => {
  const date = new Date(mVal);
  if (aggregated) {
    date.setUTCHours(0, 0, 0, 0);
  } else {
    date.setUTCSeconds(0, 0);
  }
  return date;
};

export default class TrendOperationsGraph extends React.PureComponent {
  constructor(props) {
    super(props);
    this.prepareGraphData = memoize(prepareOperationGraphData);
    this.state = { hoveredXValue: null };
  }

  getOperationColor = (legend) => {
    for (const operation of OPERATION_MODES) {
      for (const profile of operation.profiles) {
        if (profile.name === legend) {
          return profile.color;
        }
      }
    }
    return "red";
  };

  findHoveredData = (data, hoveredXValue) => {
    if (!hoveredXValue) return null;
    return data.find(
      (d) =>
        d.x <= hoveredXValue &&
        d.operations &&
        d.operations.some((o) => o.endTime > hoveredXValue)
    );
  };

  handleHoverChanged = (hoveredXValue) => {
    this.setState({ hoveredXValue });
  };

  renderOperationDescription(hoveredData) {
    if (!hoveredData) {
      return;
    } else {
      return (
        <div
          className={styles.operationsHeaderInfoContainer}
          data-test-id="trend-graphContainer"
        >
          {hoveredData.operations &&
            hoveredData.operations
              .sort((a, b) => b.z - a.z)
              .map((o, i) => (
                <div
                  key={`operation_${i}`}
                  className={styles.operationInfoContainer}
                >
                  <div
                    className={styles.operationIndicator}
                    style={{
                      backgroundColor: this.getOperationColor(
                        o.operationLegend
                      ),
                    }}
                  >
                    <span className={styles.operationIndicatorName}>
                      {o.operationName}:
                    </span>
                    <span>{secondsToTimeString(o.secondCount)}</span>
                  </div>
                </div>
              ))}
        </div>
      );
    }
  }

  componentDidUpdate() {
    if (this.autoSizer) this.autoSizer.measure();
  }

  getChartSize = (elementCount) => {
    if (elementCount > 10) {
      return styles.trendGraphOperationsChartContainerLarge;
    } else if (elementCount > 1) {
      return styles.trendGraphOperationsChartContainerMedium;
    } else {
      return styles.trendGraphOperationsChartContainerSmall;
    }
  };

  render() {
    const { xDomain, tickData, operations, hoverContext, timeOffset } =
      this.props;
    const { hoveredXValue } = this.state;
    const preparedData = this.prepareGraphData(
      operations.data,
      operations.aggregated,
      timeOffset
    );
    const maxY = get(
      d3.max(preparedData, (y) => y),
      "y",
      0
    );
    const hoveredData = this.findHoveredData(preparedData, hoveredXValue);
    const { aggregated } = operations;
    return (
      <div
        className={[
          styles.trendGraphOperationsChartContainer,
          this.getChartSize(maxY),
        ].join(" ")}
      >
        <div
          className={styles.trendGraph}
          data-test-id="trend-viewContainerHeader"
        >
          <div className={styles.operationsHeader}>
            Operational Profile
            {this.renderOperationDescription(hoveredData)}
          </div>
          <div className={styles.trendOperationGraphContent}>
            <AutoSizer ref={(el) => (this.autoSizer = el)}>
              {({ width, height: sparklineHeight }) => {
                return (
                  <Sparkline
                    height={sparklineHeight}
                    width={width}
                    xDomain={xDomain}
                    data={preparedData}
                  >
                    <SparklineGrid xTicks={tickData.xTicks} showY={false} />
                    <SparklineCustomElement>
                      {({ data, scaleX, height }) =>
                        renderOperations.bind(this)(
                          data,
                          scaleX,
                          height,
                          hoveredData
                        )
                      }
                    </SparklineCustomElement>
                    <SparklineHover
                      context={hoverContext}
                      customValueFunction={(data) =>
                        customValueFunction.bind(this)(data, aggregated)
                      }
                      onHoveredXValueChanged={this.handleHoverChanged}
                    />
                  </Sparkline>
                );
              }}
            </AutoSizer>
          </div>
        </div>
      </div>
    );
  }
}
