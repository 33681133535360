import { combineReducers } from "redux";
import appConfig from "./reducer.appConfig";
import { user } from "./reducer.user";
import pageConfig from "./reducer.pageConfig";
import { nav } from "./reducer.nav";
import { gauges } from "./reducer.gauges";
import fetchEngineLoad from "./reducer.fetchEngineLoad";
import { fetchMonthlyFuelConsumption } from "./reducer.fetchMonthlyFuelConsumption";
import { fetchVesselFuelConsumption } from "./reducer.fetchVesselFuelConsumption";
import fetchMapData from "./reducer.fetchMapData";
import itemTableData from "./reducer.itemTableData";
import { donuts } from "./reducer.donuts";
import equinor from "./reducer.equinor";
import fetchPositionDetails from "./reducer.fetchPositionDetails";
import { legs, voyages } from "./reducer.vesselStates";
import fetchFleetTable from "./reducer.fetchFleetTable";
import { fetchBestPerformance } from "./reducer.fetchBestPerformance";
import fetchCompare from "./reducer.compare";
import fetchGroups from "./reducer.fetchGroups";
import mapView from "./reducer.mapView";
import { fetchVesselItemDetails } from "./reducer.fetchVesselItemDetails";
import trend from "./reducer.trend";
import { vesselCount } from "./reducer.vesselCount";
import { fleetActivity } from "./reducer.fleetActivity";
import { emissionsPerVessel } from "./reducer.emissionsState";
import { connectionStatus } from "./reducer.connectionStatus";
import { healthEvents } from "./reducer.healthEvents";
import {
  fuelEfficiencyToday,
  fuelEfficiencyYesterday,
} from "./reducer.fuelEfficiency";
import { vesselStatus } from "./reducer.vesselStatus";
import vesselStatusHistory from "./reducer.vesselStatusHistory";
import calendar from "./reducer.calendar";
import layout from "./reducer.layout";
import energyBudgetResult from "./reducer.energyBudgetResult";
import energyBudgetOverview from "./reducer.energyBudgetOverview";
import operationalProfile from "./reducer.operationalProfile";
import operationalProfilePlan from "./reducer.operationalProfilePlan";
import userProfile from "./reducer.userProfile";
import datePicker from "./datePicker";
import admin from "./admin";
import machineryReport from "./reducer.machineryReport";
import analysis from "./reducer.analysis";
import dashboard from "./reducer.dashboard";
import { documents } from "./reducer.documents";
import pageContext from "./reducer.pageContext";
import locations from "./reducer.locations";
import hullPerformance from "./reducer.hullPerformance";
import { excelExport } from "./reducer.export";
import fuelEmissions from "./reducer.fuelEmissions";
import { emissionsByOperationModes } from "./reducer.fetchEmissionsByOperationModes";
import { ciiScores } from "./reducer.fetchCIIScores";
import fleetPerformance from "../views/FleetPerformance/reducer.fleetPerformance";
import auth from "./reducer.auth";
import { baseApi } from "../store/baseApi";
import { companiesStatus, fleetStatus } from "./admin/reducer.fleetStatus";
import { singleVesselStatus } from "./admin/reducer.singleVesselStatus";

export default combineReducers({
  appConfig,
  layout,
  user,
  pageConfig,
  pageContext,
  nav,
  gauges,
  donuts,
  equinor,
  itemTableData,
  fuelEfficiencyToday,
  fuelEfficiencyYesterday,
  fetchEngineLoad,
  fetchMonthlyFuelConsumption,
  fetchVesselFuelConsumption: fetchVesselFuelConsumption,
  fetchMapData,
  fetchVesselItemDetails,
  fetchPositionDetails,
  legs,
  voyages,
  fetchFleetTable,
  fetchBestPerformance,
  fetchCompare,
  fetchGroups,
  mapView,
  trend,
  vesselCount,
  connectionStatus,
  vesselStatus,
  healthEvents,
  vesselStatusHistory,
  calendar,
  energyBudgetOverview,
  energyBudgetResult,
  operationalProfile,
  operationalProfilePlan,
  userProfile,
  datePicker,
  admin,
  machineryReport,
  analysis,
  dashboard,
  documents,
  locations,
  excelExport,
  hullPerformance,
  fuelEmissions,
  fleetPerformance,
  fleetActivity,
  ciiScores,
  emissionsByOperationModes,
  emissionsState: emissionsPerVessel,
  [baseApi.reducerPath]: baseApi.reducer,
  auth,
  fleetStatus,
  companiesStatus,
  singleVesselStatus,
});
