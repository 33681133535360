import {
  ImoAndConsumptionPerOperation,
  OperationConsumption,
  OperationScrolling,
  Vessel,
} from "../types";
import {
  OperationId,
  operationIdSort,
} from "../../../../common/operation/operationId";
import { useMemo, useState } from "react";
import { resolveLabel } from "../../../../common/unitLabels";

export const useOperations = (
  vessels: Vessel[]
): [
  OperationId[],
  (imo: number) => OperationConsumption[],
  OperationScrolling
] => {
  const allOperationIdsOrdered = useMemo(
    () =>
      [
        ...new Set(
          vessels.flatMap((v) =>
            v.consumptionPerOperation.map((c) => c.operationId)
          )
        ),
      ].sort(operationIdSort),
    [vessels]
  );

  const vesselsWithConsumptionOrdered: ImoAndConsumptionPerOperation[] =
    useMemo(() => {
      // order consumption by operationId
      const orderConsumptionByOperation = (
        consumptionPerOperation: Vessel["consumptionPerOperation"],
        allOperationIdsOrdered: OperationId[]
      ): Vessel["consumptionPerOperation"] => {
        return [...consumptionPerOperation].sort((a, b) => {
          const indexA = allOperationIdsOrdered.indexOf(a.operationId);
          const indexB = allOperationIdsOrdered.indexOf(b.operationId);
          if (indexA === -1 || indexB === -1) {
            throw new Error(
              "OperationId not found in the list of all operations. This should not happen and means that there is a bug somewhere."
            );
          }
          return indexA - indexB;
        });
      };

      // calculate the total consumption for a given operation
      const calculateTotalConsumption = (
        consumptionArray: Vessel["consumptionPerOperation"][0]["consumption"]
      ) => {
        return consumptionArray.reduce(
          (previousValue, currentValue) =>
            previousValue + currentValue.consumption,
          0
        );
      };

      // generate hover text for a given operation
      const generateHoverText = (
        consumptionArray: Vessel["consumptionPerOperation"][0]["consumption"]
      ) => {
        return consumptionArray
          .map(
            (c) =>
              `${resolveLabel.fuelType(c.fuelType)}: ${c.consumption.toFixed(
                2
              )}`
          )
          .join("\n");
      };

      // calculate total consumption and hover text for each operation
      const calculateConsumptionPerOperation = (
        orderedConsumptionPerFuelType: Vessel["consumptionPerOperation"]
      ) => {
        return orderedConsumptionPerFuelType.map((x) => ({
          operationId: x.operationId,
          totalConsumption: calculateTotalConsumption(x.consumption),
          hoverText: generateHoverText(x.consumption),
        }));
      };

      return vessels.map((vessel) => {
        const orderedConsumptionPerFuelType = orderConsumptionByOperation(
          vessel.consumptionPerOperation,
          allOperationIdsOrdered
        );

        const consumptionPerOperation = calculateConsumptionPerOperation(
          orderedConsumptionPerFuelType
        );

        return { imo: vessel.vessel.imo, consumptionPerOperation };
      });
    }, [vessels, allOperationIdsOrdered]);

  const [visibleOperationIndexes, setVisibleOperationIndexes] = useState([
    ...Array(
      Math.min(maxVisibleOperations, allOperationIdsOrdered.length)
    ).keys(),
  ]);

  const visibleOperationIds = useMemo(
    () =>
      allOperationIdsOrdered.filter((_operationId, i) =>
        visibleOperationIndexes.includes(i)
      ),
    [allOperationIdsOrdered, visibleOperationIndexes]
  );

  const visibleVesselConsumptionPerOperation: ImoAndConsumptionPerOperation[] =
    useMemo(
      () =>
        vesselsWithConsumptionOrdered.map(
          ({ imo, consumptionPerOperation }) => ({
            imo,
            consumptionPerOperation: consumptionPerOperation.filter(
              (_value, i) => visibleOperationIndexes.includes(i)
            ),
          })
        ),
      [vesselsWithConsumptionOrdered, visibleOperationIndexes]
    );

  const canGoLeftOrRight = useMemo(
    () => allOperationIdsOrdered.length > maxVisibleOperations,
    [allOperationIdsOrdered.length]
  );

  const canGoLeft = useMemo(
    () => canGoLeftOrRight && visibleOperationIndexes[0] !== 0,
    [canGoLeftOrRight, visibleOperationIndexes]
  );

  const canGoRight = useMemo(() => {
    const lastVisibleOperationId =
      allOperationIdsOrdered[
        visibleOperationIndexes[visibleOperationIndexes.length - 1]
      ];
    const lastOperationId =
      allOperationIdsOrdered[allOperationIdsOrdered.length - 1];
    return canGoLeftOrRight && lastVisibleOperationId !== lastOperationId;
  }, [canGoLeftOrRight, visibleOperationIndexes, allOperationIdsOrdered]);

  const onClickLeft = () => {
    if (canGoLeft) {
      setVisibleOperationIndexes(visibleOperationIndexes.map((x) => x - 1));
    } else {
      console.error(
        "The go left button was clicked, but it is not possible to go further left"
      );
    }
  };

  const onClickRight = () => {
    if (canGoRight) {
      setVisibleOperationIndexes(visibleOperationIndexes.map((x) => x + 1));
    } else {
      console.error(
        "The go right button was clicked, but it is not possible to go further right"
      );
    }
  };

  const getVisibleOperationConsumption = (
    imo: number
  ): OperationConsumption[] => {
    const consumptionPerOperation = visibleVesselConsumptionPerOperation.find(
      (c) => c.imo === imo
    )?.consumptionPerOperation;
    if (consumptionPerOperation === undefined) {
      throw new Error(
        `Could not find operation consumption for vessel with imo ${imo}`
      );
    }
    return consumptionPerOperation;
  };

  return [
    visibleOperationIds,
    getVisibleOperationConsumption,
    { onClickLeft, onClickRight, canGoLeftOrRight, canGoRight, canGoLeft },
  ];
};

const maxVisibleOperations = 6;
