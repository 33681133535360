import React, { useEffect, useState } from "react";
import { EmissionsState, State, TopPerformingVesselsProps } from "./Types";
import Logo from "./Logo";
import DirectionPicker from "./DirectionPicker";
import styles from "./TopPerformingVessels.css";
import VesselList from "./VesselList";
import { useDispatch, useSelector } from "react-redux";
import { fetchEmissionsList } from "../../actions/action.fetchEmissionsList";
import Loader from "../../../common/components/Loader";
import { Direction } from "../../common/direction";
import {
  operationClassToOperations,
  operationLegend,
} from "../../../common/operation/operationId";
import { operationClassLegend } from "../../../common/operation/operationClassID";
import ComponentInfo from "../../../common/components/ComponentInfo/ComponentInfo";

const TopPerformingVessels: React.FC<TopPerformingVesselsProps> = ({
  interval,
  group,
  operationType,
  direction,
  numberOfItems,
}: TopPerformingVesselsProps) => {
  const [sortingDirection, setSortingDirection] = useState(direction);
  const dispatch = useDispatch();
  const state: EmissionsState = useSelector(
    (state: State) => state.emissionsState
  );
  const operationModes: string[] = operationClassToOperations[
    operationType
  ].map((operationId) => operationLegend[operationId]);

  useEffect(() => {
    dispatch(
      fetchEmissionsList(
        interval,
        group,
        operationClassLegend[operationType],
        operationModes,
        numberOfItems,
        sortingDirection
      )
    );
  }, [interval, group, sortingDirection]);

  function toggleDirection() {
    switch (sortingDirection) {
      case Direction.LowToHigh:
        setSortingDirection(Direction.HighToLow);
        break;
      case Direction.HighToLow:
        setSortingDirection(Direction.LowToHigh);
        break;
      default:
        throw new Error(`Unknown direction ${sortingDirection}`);
    }
  }

  const isListReady = (): boolean =>
    !(
      state?.emissionsLists?.[operationClassLegend[operationType]]?.isLoading ??
      true
    );

  const toolTipHeader = "Top performers";
  const toolTip =
    "Shows the top or bottom performing vessels in the selected operation type.";

  return (
    <ComponentInfo
      modalTargetId="popupContainer"
      title={toolTipHeader}
      text={toolTip}
    >
      <div className={styles.TopPerformingVessels}>
        <Logo operationType={operationType} />
        <DirectionPicker
          onClick={toggleDirection}
          direction={sortingDirection}
        />
        {isListReady() ? (
          <VesselList
            list={
              state.emissionsLists[operationClassLegend[operationType]].data
            }
          />
        ) : (
          <Loader />
        )}
      </div>
    </ComponentInfo>
  );
};

export default TopPerformingVessels;
