import { OperationClassID, operationClassIDSort } from "./operationClassID";
import { color } from "../colors";

interface Operation {
  legend: string;
  name: string;
}

export enum OperationId {
  None = -1,
  Other = 0,
  Port = 1,
  Maneuver = 2,
  TransitEco = 3,
  Transit = 4,
  TransitFast = 5,
  PortShoreConnection = 6,
  Standby = 7,
  PortBattery = 8,
  Dp = 100,
  DpClass2 = 101,
  DpGangway = 102,
  DpManeuver = 103,
  AnchorHandlingDp = 200,
  AnchorHandlingTensioning = 201,
  AnchorHandlingTowing = 202,
  SingleTrawl = 300,
  DoubleTrawl = 301,
  Fishing = 302,
  TripleTrawl = 303,
  Shooting = 304,
  Hoisting = 305,
  Embarking = 400,
  Jacking = 500,
  JackedUp = 501,
}

interface Operation {
  legend: string;
  name: string;
}

const operations: Record<OperationId, Operation> = {
  [OperationId.None]: { legend: "OFF", name: "Offline" },
  [OperationId.Other]: { legend: "O", name: "Other" },
  [OperationId.Port]: { legend: "P", name: "At Port" },
  [OperationId.Maneuver]: { legend: "M", name: "Maneuver" },
  [OperationId.TransitEco]: { legend: "ET", name: "Eco Transit" },
  [OperationId.Transit]: { legend: "T", name: "Transit" },
  [OperationId.TransitFast]: { legend: "FT", name: "Fast Transit" },
  [OperationId.PortShoreConnection]: {
    legend: "SC",
    name: "Port With Shore Connection",
  },
  [OperationId.Standby]: { legend: "S", name: "Standby" },
  [OperationId.PortBattery]: { legend: "PB", name: "Port With Battery" },
  [OperationId.Dp]: { legend: "DP", name: "DP" },
  [OperationId.DpClass2]: { legend: "DP2", name: "DP Class 2" },
  [OperationId.DpGangway]: { legend: "DPG", name: "DP Gangway" },
  [OperationId.DpManeuver]: { legend: "DP2M", name: "DP Maneuver" },
  [OperationId.AnchorHandlingDp]: {
    legend: "AHDP",
    name: "Anchor Handling DP",
  },
  [OperationId.AnchorHandlingTensioning]: {
    legend: "AHTe",
    name: "Anchor Handling Tensioning",
  },
  [OperationId.AnchorHandlingTowing]: { legend: "AHTw", name: "AH Towing" },
  [OperationId.SingleTrawl]: { legend: "F1", name: "Single Trawl" },
  [OperationId.DoubleTrawl]: { legend: "F2", name: "Double Trawl" },
  [OperationId.Fishing]: { legend: "F", name: "Fishing" },
  [OperationId.TripleTrawl]: { legend: "F3", name: "Triple Trawl" },
  [OperationId.Shooting]: { legend: "FS", name: "Shooting" },
  [OperationId.Hoisting]: { legend: "FH", name: "Hoisting" },
  [OperationId.Embarking]: { legend: "E", name: "Embarking" },
  [OperationId.Jacking]: { legend: "J", name: "Jacking" },
  [OperationId.JackedUp]: { legend: "JU", name: "Jacked Up" },
};

export const operationLegend: Record<OperationId, string> = Object.keys(
  operations
).reduce((prev, key) => {
  const enumKey = parseInt(key);
  if (!isNaN(enumKey)) {
    prev[enumKey as OperationId] = operations[enumKey as OperationId].legend;
  }
  return prev;
}, {} as Record<OperationId, string>);

export const operationName: Record<OperationId, string> = Object.keys(
  operations
).reduce((prev, key) => {
  const enumKey = parseInt(key);
  if (!isNaN(enumKey)) {
    prev[enumKey as OperationId] = operations[enumKey as OperationId].name;
  }
  return prev;
}, {} as Record<OperationId, string>);

export const operationToOperationClass: Record<OperationId, OperationClassID> =
  {
    [OperationId.None]: OperationClassID.Other,
    [OperationId.Other]: OperationClassID.Other,
    [OperationId.Port]: OperationClassID.General,
    [OperationId.Maneuver]: OperationClassID.Transit,
    [OperationId.TransitEco]: OperationClassID.Transit,
    [OperationId.Transit]: OperationClassID.Transit,
    [OperationId.TransitFast]: OperationClassID.Transit,
    [OperationId.PortShoreConnection]: OperationClassID.General,
    [OperationId.Standby]: OperationClassID.DP,
    [OperationId.PortBattery]: OperationClassID.General,
    [OperationId.Dp]: OperationClassID.DP,
    [OperationId.DpClass2]: OperationClassID.DP,
    [OperationId.DpGangway]: OperationClassID.DP,
    [OperationId.DpManeuver]: OperationClassID.DP,
    [OperationId.AnchorHandlingDp]: OperationClassID.AHTS,
    [OperationId.AnchorHandlingTensioning]: OperationClassID.AHTS,
    [OperationId.AnchorHandlingTowing]: OperationClassID.AHTS,
    [OperationId.SingleTrawl]: OperationClassID.Fishing,
    [OperationId.DoubleTrawl]: OperationClassID.Fishing,
    [OperationId.Fishing]: OperationClassID.Fishing,
    [OperationId.TripleTrawl]: OperationClassID.Fishing,
    [OperationId.Shooting]: OperationClassID.Fishing,
    [OperationId.Hoisting]: OperationClassID.Fishing,
    [OperationId.Jacking]: OperationClassID.Jacking,
    [OperationId.JackedUp]: OperationClassID.Jacking,
    [OperationId.Embarking]: OperationClassID.General,
  };

export const operationClassToOperations: Record<
  OperationClassID,
  OperationId[]
> = Object.entries(operationToOperationClass).reduce(
  (acc, [operationId, operationClassId]) => {
    if (!acc[operationClassId]) {
      acc[operationClassId] = [];
    }
    acc[operationClassId].push(operationId as unknown as OperationId);
    return acc;
  },
  {} as Record<OperationClassID, OperationId[]>
);

export const operationColor: Record<OperationId, string> = {
  [OperationId.None]: color("--grey-100"),
  [OperationId.Other]: color("--grey-300"),
  [OperationId.Port]: color("--grey-500"),
  [OperationId.Maneuver]: color("--blue-dark"),
  [OperationId.TransitEco]: color("--blue-darkest"),
  [OperationId.Transit]: color("--blue-base"),
  [OperationId.TransitFast]: color("--blue-bright"),
  [OperationId.PortShoreConnection]: color("--mid-grey"),
  [OperationId.Standby]: color("--orange-darkest"),
  [OperationId.PortBattery]: color("--grey-200"),
  [OperationId.Dp]: color("--orange-dark"),
  [OperationId.DpClass2]: color("--orange-base"),
  [OperationId.DpGangway]: color("--orange-brightest"),
  [OperationId.DpManeuver]: color("--orange-bright"),
  [OperationId.AnchorHandlingDp]: color("--red-dark"),
  [OperationId.AnchorHandlingTensioning]: color("--red-bright"),
  [OperationId.AnchorHandlingTowing]: color("--red-base"),
  [OperationId.SingleTrawl]: color("--yellow-dark"),
  [OperationId.DoubleTrawl]: color("--yellow-base"),
  [OperationId.Fishing]: color("--yellow-dark"),
  [OperationId.TripleTrawl]: color("--yellow-darkest"),
  [OperationId.Shooting]: color("--yellow-bright"),
  [OperationId.Hoisting]: color("--yellow-brightest"),
  [OperationId.Embarking]: color("--grey-600"),
  [OperationId.Jacking]: color("--green-base"),
  [OperationId.JackedUp]: color("--green-bright"),
};

export const operationTextColor: Record<OperationId, string | undefined> = {
  [OperationId.None]: color("--grey-500"),
  [OperationId.Other]: undefined,
  [OperationId.Port]: undefined,
  [OperationId.Maneuver]: undefined,
  [OperationId.TransitEco]: undefined,
  [OperationId.Transit]: undefined,
  [OperationId.TransitFast]: undefined,
  [OperationId.PortShoreConnection]: undefined,
  [OperationId.Standby]: undefined,
  [OperationId.PortBattery]: color("--grey-500"),
  [OperationId.Dp]: undefined,
  [OperationId.DpClass2]: undefined,
  [OperationId.DpGangway]: undefined,
  [OperationId.DpManeuver]: undefined,
  [OperationId.AnchorHandlingDp]: undefined,
  [OperationId.AnchorHandlingTensioning]: undefined,
  [OperationId.AnchorHandlingTowing]: undefined,
  [OperationId.SingleTrawl]: undefined,
  [OperationId.DoubleTrawl]: undefined,
  [OperationId.Fishing]: undefined,
  [OperationId.TripleTrawl]: undefined,
  [OperationId.Shooting]: undefined,
  [OperationId.Hoisting]: undefined,
  [OperationId.Embarking]: undefined,
  [OperationId.Jacking]: undefined,
  [OperationId.JackedUp]: undefined,
};

export const operationIdSort = (a: OperationId, b: OperationId) => {
  const aClass = operationToOperationClass[a];
  const bClass = operationToOperationClass[b];
  return aClass === bClass
    ? // Order by enum value ascending if same class
      a - b
    : // Order by class if different classes
      operationClassIDSort(aClass, bClass);
};
