import moment from "moment";
import createReducer from "./createReducer";
import { fuelAndEmissions } from "../actions/action.types";
import { isNullOrUndefined } from "../../common/objects";
import { OPERATION_GROUPS } from "../config";
import {
  EmissionsTrendSelected,
  IPoint,
  IDonutData,
  IFuelEmissionsState,
  IFuelAndTargetState,
  IFuelAndTargetItem,
} from "../components/FuelEmissions/commonTypes";
import { organizeScores } from "../views/FleetCIIScore/CIIScoreOrganizer";
import { Option } from "../../common/components/RadioButton";

interface IPayload {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload?: IFuelDataFieldValue | any;
  data:
    | IFuelConsumptionData
    | IDonutData[]
    | IEmissionsTrendData
    | IFuelAndTarget;
  error: string | undefined;
  fuel: string;
  emission: string;
}

const initialState: IFuelEmissionsState = {
  fuelConsumption: {
    fuels: [],
    consumptions: [],
    isLoading: false,
    selected: [],
  },
  donutData: {
    data: [],
    isLoading: false,
  },
  emissionsTrend: {
    options: [
      { name: EmissionsTrendSelected.Emissions, selected: true },
      { name: EmissionsTrendSelected.CiiTrend },
    ],
    selected: "Emissions",
    series: {
      cii: {
        year: 2000,
        scores: [],
      },
      emissions: [],
    },
    isLoading: false,
  },
  emissionsData: {
    data: [],
    isLoading: false,
  },
  fuelAndTarget: {
    fuels: [],
    filteredData: [],
    data: {},
    isLoading: false,
  },
  setTargetFuelDataDialog: {
    visible: false,
  },
  isLoading: false,
};

export default createReducer(initialState, {
  [fuelAndEmissions.CHANGE_FILTERS]: (
    state: IFuelEmissionsState,
    { payload }: IPayload
  ) => {
    return {
      ...state,
      isLoading: true,
      filters: payload,
    };
  },

  [fuelAndEmissions.fuelConsumptions.FETCH_FUEL_CONSUMPTIONS]: (
    state: IFuelEmissionsState
  ) => {
    return {
      ...state,
      fuelConsumption: {
        ...state.fuelConsumption,
        isLoading: true,
      },
    };
  },

  [fuelAndEmissions.fuelConsumptions.FETCH_FUEL_CONSUMPTIONS_SUCCESS]: (
    state: IFuelEmissionsState,
    { data }: IPayload
  ) => {
    const fuelConsumption = createFuelConsumptionSuccessState(
      data as IFuelConsumptionData
    );
    return {
      ...state,
      isLoading: false,
      fuelConsumption,
    };
  },

  [fuelAndEmissions.fuelConsumptions.FETCH_FUEL_CONSUMPTIONS_ERROR]: (
    state: IFuelEmissionsState,
    { error }: IPayload
  ) => {
    return {
      ...state,
      isLoading: false,
      fuelConsumption: {
        error,
        isLoading: false,
      },
    };
  },

  [fuelAndEmissions.fuelConsumptions.CHANGE_FUEL]: (
    state: IFuelEmissionsState,
    { fuel }: IPayload
  ) => {
    const consumptions = state.fuelConsumption.consumptions;

    const selected = consumptions.find((c) => c.fuel === fuel)?.consumptions;
    return {
      ...state,
      fuelConsumption: {
        ...state.fuelConsumption,
        selected: selected,
      },
    };
  },

  [fuelAndEmissions.donutData.FETCH_DONUT_DATA]: (
    state: IFuelEmissionsState
  ) => {
    return {
      ...state,
      donutData: {
        isLoading: true,
      },
    };
  },

  [fuelAndEmissions.donutData.FETCH_DONUT_DATA_SUCCESS]: (
    state: IFuelEmissionsState,
    { data }: IPayload
  ) => {
    return {
      ...state,
      isLoading: false,
      donutData: {
        isLoading: false,
        data: createDonutSuccessState(data as IDonutData[]),
      },
    };
  },

  [fuelAndEmissions.donutData.FETCH_DONUT_DATA_ERROR]: (
    state: IFuelEmissionsState,
    { error }: IPayload
  ) => {
    return {
      ...state,
      isLoading: false,
      donutData: {
        error,
        isLoading: false,
      },
    };
  },
  [fuelAndEmissions.emissionsTrend.FETCH_EMISSIONS_TREND]: (
    state: IFuelEmissionsState
  ) => {
    return {
      ...state,
      emissionsTrend: {
        ...state.emissionsTrend,
        isLoading: true,
      },
    };
  },
  [fuelAndEmissions.emissionsTrend.FETCH_EMISSIONS_TREND_SUCCESS]: (
    state: IFuelEmissionsState,
    { data }: IPayload
  ) => {
    const series = createEmissionsTrendSeries(data as IEmissionsTrendData);
    return {
      ...state,
      emissionsTrend: {
        ...state.emissionsTrend,
        series: series,
        isLoading: false,
      },
    };
  },
  [fuelAndEmissions.emissionsTrend.FETCH_EMISSIONS_TREND_ERROR]: (
    state: IFuelEmissionsState,
    { error }: IPayload
  ) => {
    return {
      ...state,
      isLoading: false,
      emissionsTrend: {
        ...state.emissionsTrend,
        error,
        isLoading: false,
      },
    };
  },
  [fuelAndEmissions.emissionsByOperationModes
    .FETCH_EMISSIONS_BY_OPERATION_MODES_DATA]: (state: IFuelEmissionsState) => {
    return {
      ...state,
      emissionsData: {
        isLoading: true,
      },
    };
  },

  [fuelAndEmissions.emissionsByOperationModes
    .FETCH_EMISSIONS_BY_OPERATION_MODES_DATA_SUCCESS]: (
    state: IFuelEmissionsState,
    { data }: IPayload
  ) => {
    return {
      ...state,
      isLoading: false,
      emissionsData: {
        isLoading: false,
        data,
      },
    };
  },

  [fuelAndEmissions.emissionsByOperationModes
    .FETCH_EMISSIONS_BY_OPERATION_MODES_DATA_ERROR]: (
    state: IFuelEmissionsState,
    { error }: IPayload
  ) => {
    return {
      ...state,
      isLoading: false,
      emissionsData: {
        error,
        isLoading: false,
      },
    };
  },
  [fuelAndEmissions.emissionsTrend.CHANGE_EMISSIONS]: (
    state: IFuelEmissionsState,
    { emission }: IPayload
  ) => {
    return {
      ...state,
      emissionsTrend: {
        ...state.emissionsTrend,
        selected: emission,
      },
    };
  },

  [fuelAndEmissions.fuelAndTarget.FETCH_FUEL_AND_TARGET_DATA]: (
    state: IFuelEmissionsState
  ) => {
    return {
      ...state,
      fuelAndTarget: {
        ...state.fuelAndTarget,
        isLoading: true,
      },
    };
  },

  [fuelAndEmissions.fuelAndTarget.FETCH_FUEL_AND_TARGET_DATA_SUCCESS]: (
    state: IFuelEmissionsState,
    { data }: IPayload
  ) => {
    const fuelAndTarget = createFuelAndTargetSuccessState(
      data as IFuelAndTarget
    );
    return {
      ...state,
      isLoading: false,
      fuelAndTarget,
    };
  },

  [fuelAndEmissions.fuelAndTarget.FETCH_FUEL_AND_TARGET_DATA_ERROR]: (
    state: IFuelEmissionsState,
    { error }: IPayload
  ) => {
    return {
      ...state,
      isLoading: false,
      fuelAndTarget: {
        error,
        isLoading: false,
      },
    };
  },

  [fuelAndEmissions.fuelAndTarget.CHANGE_FUEL]: (
    state: IFuelEmissionsState,
    { fuel }: IPayload
  ) => {
    return {
      ...state,
      fuelAndTarget: {
        ...state.fuelAndTarget,
        filteredData: getFuelAndTargetFilteredData(
          state.fuelAndTarget.data as IFuelAndTargetRow[],
          fuel
        ),
      },
    };
  },

  [fuelAndEmissions.setFuelTargetDialog.OPEN]: (
    state: IFuelEmissionsState
  ) => ({
    ...state,
    setTargetFuelDataDialog: {
      ...state.setTargetFuelDataDialog,
      visible: true,
    },
  }),

  [fuelAndEmissions.setFuelTargetDialog.CANCEL]: (
    state: IFuelEmissionsState
  ) => {
    return {
      ...state,
      setTargetFuelDataDialog: {
        visible: false,
      },
    };
  },

  [fuelAndEmissions.setFuelTargetDialog.HIDE]: (state: IFuelEmissionsState) => {
    return {
      ...state,
      setTargetFuelDataDialog: {
        ...state.setTargetFuelDataDialog,
        visible: false,
      },
    };
  },

  [fuelAndEmissions.setFuelTargetDialog.FETCH_FUEL_TARGET_DATA]: (
    state: IFuelEmissionsState
  ) => {
    return {
      ...state,
      setTargetFuelDataDialog: {
        ...state.setTargetFuelDataDialog,
        isLoading: true,
      },
    };
  },

  [fuelAndEmissions.setFuelTargetDialog.FETCH_FUEL_TARGET_DATA_SUCCESS]: (
    state: IFuelEmissionsState,
    { data }: IPayload
  ) => {
    return {
      ...state,
      isLoading: false,
      setTargetFuelDataDialog: {
        ...state.setTargetFuelDataDialog,
        isLoading: false,
        data,
      },
    };
  },

  [fuelAndEmissions.setFuelTargetDialog.FETCH_FUEL_TARGET_DATA_ERROR]: (
    state: IFuelEmissionsState,
    { error }: IPayload
  ) => {
    return {
      ...state,
      isLoading: false,
      setTargetFuelDataDialog: {
        error,
        isLoading: false,
      },
    };
  },

  [fuelAndEmissions.setFuelTargetDialog.SAVE_FUEL_TARGET_DATA]: (
    state: IFuelEmissionsState
  ) => {
    return {
      ...state,
      setTargetFuelDataDialog: {
        ...state.setTargetFuelDataDialog,
        isLoading: true,
      },
    };
  },

  [fuelAndEmissions.setFuelTargetDialog.SAVE_FUEL_TARGET_DATA_SUCCESS]: (
    state: IFuelEmissionsState
  ) => {
    return {
      ...state,
      isLoading: false,
      setTargetFuelDataDialog: {
        visible: false,
      },
    };
  },

  [fuelAndEmissions.setFuelTargetDialog.SAVE_FUEL_TARGET_DATA_ERROR]: (
    state: IFuelEmissionsState,
    { error }: IPayload
  ) => {
    return {
      ...state,
      isLoading: false,
      setTargetFuelDataDialog: {
        ...state.setTargetFuelDataDialog,
        error,
      },
    };
  },

  [fuelAndEmissions.setFuelTargetDialog.TARGET_FUEL_DATA_FIELD_VALUE_CHANGED]: (
    state: IFuelEmissionsState,
    { payload }: IPayload
  ) => {
    const value = payload as IFuelDataFieldValue;
    if (isNaN(+value.value)) return state;

    // make full copy of state data, in order to prevent mutation
    const data = (state.setTargetFuelDataDialog.data?.data ?? []).map((d) => ({
      ...d,
      targets: d.targets.map((t) => ({ ...t })),
    }));
    const row = data.find((m) => m.operationMode === value.operationMode);
    if (!row) return;
    row.hasChanges = true;
    const el = row.targets.find((t) => t.fuelType === value.field);
    if (el) {
      el.value = value.value;
    } else {
      row.targets.push({ fuelType: value.field, value: value.value });
    }

    return {
      ...state,
      setTargetFuelDataDialog: {
        ...state.setTargetFuelDataDialog,
        data: {
          ...state.setTargetFuelDataDialog.data,
          data,
        },
        hasChanges: true,
      },
    };
  },
});

//Emissions Trend

export interface IEmissionsTrendData {
  cii: ICii[];
  emissions: IEmissions[];
}
interface ICii {
  date: string;
  score: number;
}

interface IEmissions {
  date: string;
  co2: number;
  nox: number;
}

// Fuel consumption

export interface IFuelConsumptionData {
  fuels: string[];
  operationGroups: IOperationGroups[];
  fuelConsumptionPerOperationGroup: IFuelConsumptionPerOperationGroup[];
}

interface IFuelConsumptionPerOperationGroup {
  fuel: string;
  consumptions: IConsumption[];
  previousConsumptions: IConsumption[];
  series: ISerie[];
}

interface ISerie {
  operationGroup: keyof typeof OPERATION_GROUPS;
  series: IPoint[];
}

interface IConsumption {
  operationGroup: string;
  value: number;
}

interface IOperationGroups {
  operationGroup: keyof typeof OPERATION_GROUPS;
  operationProfiles: string[];
}
const createEmissionsTrendSeries = (data: IEmissionsTrendData) => {
  const ciiScores = filterCiiScores(data.cii);
  const co2Series = data.emissions.reduce<IPoint[]>((acc, curr) => {
    const newPoint: IPoint = {
      x: moment(curr.date).format("MMM"),
      y: curr.co2 / 1000,
    };
    return [...acc, newPoint];
  }, []);

  const noxSeries = data.emissions.reduce<IPoint[]>((acc, curr) => {
    const newPoint: IPoint = {
      x: moment(curr.date).format("MMM"),
      y: curr.nox / 1000,
    };
    return [...acc, newPoint];
  }, []);

  const emissionsSeries = [co2Series, noxSeries];

  return {
    cii: organizeScores(ciiScores),
    emissions: emissionsSeries,
  };
};

const filterCiiScores = (cii: ICii[]) => {
  const endOfPeriod = moment();
  const filteredDates = [];

  for (let i = 0; i < 3; i++) {
    const startOfPeriod = endOfPeriod.clone().subtract(6, "months");

    const datesForYear = cii.filter((date) => {
      const momentDate = moment(date.date);
      return (
        momentDate.isSameOrAfter(startOfPeriod) &&
        momentDate.isSameOrBefore(endOfPeriod)
      );
    });

    filteredDates.push(...datesForYear);
    endOfPeriod.subtract(1, "year");
  }
  return filteredDates;
};
const createDonutSuccessState = (data: IDonutData[]) => {
  if (!data?.length) {
    return data;
  }

  const run = "run-seconds";
  const co2 = "co2";
  const nox = "nox";
  const leafSvg = "eeoi";

  const runDonut = data.find((d) => d.type === run);
  const co2Donut = data.find((d) => d.type === co2);
  const noxDonut = data.find((d) => d.type === nox);

  return [
    runDonut,
    { ...co2Donut, type: leafSvg },
    { ...noxDonut, type: leafSvg },
  ];
};

const createFuelConsumptionSuccessState = (data: IFuelConsumptionData) => {
  const fuelOptions = data.fuels.map((f, index) => {
    return { id: f, name: f.toUpperCase(), selected: index === 0 };
  });
  const consumptionsByFuel = data.fuels.map((f) => {
    return {
      fuel: f,
      consumptions: data.operationGroups.map((o) => {
        const fuelConsumption = data.fuelConsumptionPerOperationGroup.find(
          (x) => x.fuel === f
        );
        if (isNullOrUndefined(fuelConsumption)) {
          return {
            operationsGroup: o.operationGroup,
            operationsGroupName: OPERATION_GROUPS[o.operationGroup],
            operations: o.operationProfiles,
            operationsValue: {
              value: "-",
              unit: "t/h",
              performance: 0,
            },
            trendData: [],
          };
        }
        const fuelConsumptionPerOperationGroup =
          fuelConsumption?.consumptions.find(
            (x) => x.operationGroup === o.operationGroup
          );
        if (isNullOrUndefined(fuelConsumptionPerOperationGroup)) {
          return {
            operationsGroup: o.operationGroup,
            operationsGroupName: OPERATION_GROUPS[o.operationGroup],
            operations: o.operationProfiles,
            operationsValue: {
              value: "-",
              unit: "t/h",
              performance: 0,
            },
            trendData: [],
          };
        }
        const valueString = fuelConsumptionPerOperationGroup?.value ?? 0;
        const previousConsumption =
          fuelConsumption?.previousConsumptions.find(
            (x) => x.operationGroup === o.operationGroup
          )?.value ?? 0;
        const trendData = fuelConsumption?.series
          .find((x) => x.operationGroup === o.operationGroup)
          ?.series.map((s) => {
            return {
              y: s.y,
              x: moment(s.x, "YYYY-MM-DD"),
            };
          });
        const value = Number(valueString);
        const performance =
          !isNullOrUndefined(previousConsumption) && previousConsumption !== 0
            ? Number(
                (
                  ((value - previousConsumption) / previousConsumption) *
                  100
                ).toFixed(1)
              )
            : 0;
        return {
          operationsGroup: o.operationGroup,
          operationsGroupName: OPERATION_GROUPS[o.operationGroup],
          operations: o.operationProfiles,
          operationsValue: {
            value: value.toFixed(2),
            unit: "t/h",
            performance: performance,
          },
          trendData: trendData,
        };
      }),
    };
  });
  const selected = consumptionsByFuel.find(
    (c) => c.fuel === fuelOptions.find((f) => f.selected)?.id
  );

  return {
    fuels: fuelOptions,
    consumptions: consumptionsByFuel,
    selected: selected?.consumptions,
    isLoading: false,
  };
};

//SET FUEL TARGET
export interface IFuelDataFieldValue {
  field: string;
  operationMode: string;
  value: number;
}

// Fuel and Target

export interface IFuelAndTarget {
  fuelTypes: string[];
  rows: IFuelAndTargetRow[];
}

export interface IFuelAndTargetRow {
  operationMode: string;
  runHours: number;
  consumptions: Record<string, IFuelAndTargetConsumption>;
}

export interface IFuelAndTargetConsumption {
  actual: number;
  target: number;
}

const createFuelAndTargetSuccessState = (
  fuelAndTargetData: IFuelAndTarget
): IFuelAndTargetState => {
  const fuelOptions: Option[] = fuelAndTargetData.fuelTypes.map(
    (fuel, index) => {
      return {
        id: fuel,
        name: fuel.toUpperCase(),
        selected: index === 0,
      };
    }
  );

  const selected = fuelOptions.filter((f) => f.selected)[0];

  return {
    fuels: fuelOptions ?? [],
    filteredData: getFuelAndTargetFilteredData(
      fuelAndTargetData.rows,
      selected.id as string
    ),
    data: fuelAndTargetData.rows,
    isLoading: false,
  };
};

const getFuelAndTargetFilteredData = (
  rows: IFuelAndTargetRow[],
  fuel: string
) => {
  const getFormattedValue = (val: number) => (val ? val.toFixed(2) : val);

  const data: IFuelAndTargetItem[] = rows.map((fuelAndTarget) => {
    return {
      operationMode: fuelAndTarget.operationMode,
      runTime: getFormattedValue(fuelAndTarget.runHours),
      actualFuelUse: getFormattedValue(
        fuelAndTarget.consumptions[fuel]?.actual
      ),
      targetFuelUse: getFormattedValue(
        fuelAndTarget.consumptions[fuel]?.target
      ),
    };
  });

  return data;
};
