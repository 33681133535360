import { IPoint } from "../commonTypes";
import React from "react";
import { EmissionsGraph } from "./EmissionsGraph";

type EmissionsProps = {
  data: IPoint[][];
  width: number;
  height: number;
};

// This is a hack since the sparkline component overflows.
// The height and the width in the current case is from the parent element.
// Since it overflows it
const padding = 50;
const adjust = (dim: number): number => {
  if (dim === 0) {
    return dim;
  }
  return dim - padding;
};

export const Emissions: React.FC<EmissionsProps> = ({
  data,
  width,
  height,
}: EmissionsProps) => {
  return (
    <EmissionsGraph width={adjust(width)} height={adjust(height)} data={data} />
  );
};
