import React from "react";
import * as d3 from "d3";
import PropTypes, { bool, number } from "prop-types";
import { getUniqueId } from "../../id";
import { parseCurve } from "./SparklineHelpers";
import { color } from "../../colors";
import { isFinite, has } from "lodash";
import { SparklineContext } from "./Sparkline";
import memoize from "memoize-one";
import {
  addHours,
  getDifferenceInMinutes,
} from "../../../onshore/common/dates";
import { isNullOrUndefined } from "@microsoft/applicationinsights-core-js";

const isPointFinite = ({ x, y } = {}) => isFinite(x) && isFinite(y);

class SparklineLine extends React.PureComponent {
  constructor(props) {
    super(props);
    this.gradientId = getUniqueId();
  }

  getPoints = memoize((data, dataSetIndex, scaleX, scaleY) => {
    return data[dataSetIndex].map((d, i) => ({
      x: scaleX(has(d, "x") ? d.x : i),
      y: scaleY(has(d, "y") ? d.y : d),
      xValue: has(d, "x") ? d.x : i,
      yValue: has(d, "y") ? d.y : d,
    }));
  });

  getLineData = memoize((points, curve, xOffset) => {
    const curveFunction = parseCurve(curve);

    const lineFunction = d3
      .line()
      .defined((p) => p && isFinite(p.x) && isFinite(p.y))
      .x((p) => p.x + xOffset)
      .y((p) => p.y)
      .curve(curveFunction || d3.curveLinear);
    return lineFunction(points);
  });

  getPointsWithDiscontinuousLines = (points, minutes) => {
    let result = [];
    points.forEach((currentPoint, i) => {
      const previousPoint = points[i - 1];
      if (
        previousPoint &&
        getDifferenceInMinutes(previousPoint.xValue, currentPoint.xValue) >
          minutes &&
        !isNullOrUndefined(previousPoint.y)
      ) {
        result.push({ xValue: addHours(1, new Date(previousPoint.xValue)) });
      }
      result.push(currentPoint);
    });
    return result;
  };

  render() {
    const {
      data,
      curve,
      scaleX,
      scaleY,
      dataSetIndex,
      color: sparklineLineColor,
      gradient,
      strokeOpacity,
      strokeDasharray,
      strokeWidth,
      lastValueIndicator,
      yDomainIndex = 0,
      xOffset,
      deselectedSeries,
      lineBreakOptions,
    } = this.props;

    if (deselectedSeries.has(dataSetIndex)) {
      return null;
    }

    const currentScaleY = scaleY[yDomainIndex];
    let points = this.getPoints(data, dataSetIndex, scaleX, currentScaleY);

    const lineStyle = {
      fill: "none",
      strokeWidth,
      strokeLinecap: "round",
      strokeLinejoin: "round",
      stroke:
        gradient === true ? `url(#${this.gradientId})` : sparklineLineColor,
      strokeOpacity,
      strokeDasharray,
    };

    const circleStyle = {
      stroke: "none",
      strokeWidth: "0",
      fill: sparklineLineColor,
      pointerEvents: "auto",
    };

    const circlePoint = points[points.length - 1];

    if (
      lineBreakOptions?.continuous === false &&
      lineBreakOptions?.minimumBreakMinutes
    ) {
      points = this.getPointsWithDiscontinuousLines(
        points.filter((p) => p && isPointFinite(p)),
        lineBreakOptions?.minimumBreakMinutes
      );
    } else {
      points = points.filter((p) => p && isPointFinite(p));
    }

    return (
      <svg>
        <g>
          {gradient === true && (
            <defs>
              <linearGradient
                gradientUnits={"userSpaceOnUse"}
                id={this.gradientId}
                x1={"0%"}
                y1={"0%"}
                x2={"100%"}
                y2={"0%"}
              >
                <stop
                  offset={"0%"}
                  stopColor={sparklineLineColor}
                  stopOpacity={"0.1"}
                />
                <stop
                  offset={"100%"}
                  stopColor={sparklineLineColor}
                  stopOpacity={"1"}
                />
              </linearGradient>
            </defs>
          )}
          <path
            style={lineStyle}
            d={this.getLineData(points, curve, xOffset)}
          />
          {lastValueIndicator && isPointFinite(circlePoint) && (
            <circle
              cx={circlePoint.x}
              cy={circlePoint.y}
              r={4}
              style={circleStyle}
            />
          )}
        </g>
      </svg>
    );
  }
}

SparklineLine.defaultProps = {
  dataSetIndex: 0,
  color: color("--blue-base"),
  gradient: false,
  strokeWidth: 2,
  strokeOpacity: 1,
  strokeDasharray: 0,
};

SparklineLine.propTypes = {
  dataSetIndex: PropTypes.number,
  color: PropTypes.string,
  gradient: PropTypes.bool,
  strokeWidth: PropTypes.number,
  strokeOpacity: PropTypes.number,
  strokeDasharray: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  lastValueIndicator: PropTypes.bool,
  curve: PropTypes.string,
  showHoverPoint: PropTypes.bool,
  lineBreakOptions: PropTypes.shape({
    continuous: bool,
    minimumBreakMinutes: number,
  }),
};

export default React.forwardRef((props, ref) => (
  <SparklineContext.Consumer>
    {(context) => <SparklineLine {...context} {...props} ref={ref} />}
  </SparklineContext.Consumer>
));
