import React, { useEffect, useState } from "react";
import styles from "./FleetStatusPopUp.css";
import Popup from "../../../components/Popup/Popup";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "./SingleVesselStatusData";
import { fetchSingleVesselStatus } from "../../../actions";
import Loader from "../../../../common/components/Loader";
import RenderDot from "../../../components/RenderDot/RenderDot";

interface StateData {
  vesselName: string;
  overallState: number;
  states: Record<
    string,
    Array<{
      name: string;
      comment: string;
      status: number;
      timestamp: string | null;
    }>
  >;
}

const FleetStatusPopUp = ({
  onClose,
  imo,
  vesselName,
}: {
  onClose: () => void;
  imo: number;
  vesselName: string;
}) => {
  const dispatch = useDispatch();
  const singleVesselStatusData =
    useSelector((state: AppState) => state.singleVesselStatus.data) ?? {};

  useEffect(() => {
    dispatch(fetchSingleVesselStatus(imo));
  }, [dispatch]);

  const [editedComments, setEditedComments] = useState<{
    [key: string]: string;
  }>({});

  const handleCommentChange = (
    commentKey: string,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const updatedComments = { ...editedComments, [commentKey]: e.target.value };
    setEditedComments(updatedComments);
  };

  const handleCommentSave = () => {
    console.log("Updated comments:", editedComments);
    setEditedComments({});
    //TODO: Dispatch an action to update the comments in the state and database
  };

  const renderOverallStatus = (overallState: number): JSX.Element => (
    <div className={styles.overallStatus}>
      <table className={styles.singleVesselStatusTable}>
        <thead>
          <tr>
            <th>Overall Status</th>
            <th>
              <RenderDot status={overallState} />
            </th>
          </tr>
        </thead>
      </table>
    </div>
  );

  const renderStateItem = (
    item: {
      name: string;
      comment: string;
      status: number;
      timestamp: string | null;
    },
    stateCategory: string
  ) => {
    const key = item.name.trim();
    const commentKey = `${stateCategory}_${key}`;
    const editedComment = editedComments[commentKey] || item.comment;

    return (
      <tr key={key}>
        <td>{key}</td>
        <td className="statusDotContainer">
          <RenderDot status={parseInt(Object.values(item)[1] as string, 10)} />
        </td>
        <td>
          {item.timestamp ? new Date(item.timestamp).toLocaleString() : "N/A"}
        </td>
        <td>
          <input
            type="text"
            value={editedComment}
            onChange={(e) => handleCommentChange(commentKey, e)}
            className={styles.commentInput}
          />
          <button className={styles.commentButton} onClick={handleCommentSave}>
            submit
          </button>
        </td>
      </tr>
    );
  };

  const renderStateCategory = (
    stateCategory: string,
    stateItems: Array<{
      name: string;
      comment: string;
      status: number;
      timestamp: string | null;
    }>
  ): JSX.Element => (
    <div key={stateCategory} className={styles.square}>
      <h1>{stateCategory}</h1>
      <table className={styles.singleVesselStatusTable}>
        <thead>
          <tr>
            <th>Name</th>
            <th>Status</th>
            <th>Timestamp</th>
            <th>Comment</th>
          </tr>
        </thead>
        <tbody>
          {stateItems.map((item) => renderStateItem(item, stateCategory))}
        </tbody>
      </table>
    </div>
  );

  const generateStateData = (stateData: StateData): JSX.Element => {
    const { overallState, states } = stateData;

    const stateElements = Object.keys(states).map((stateCategory) =>
      renderStateCategory(stateCategory, states[stateCategory])
    );

    return (
      <div>
        {renderOverallStatus(overallState)}
        {stateElements.length > 0 ? (
          <div className={styles.gridContainer}>{stateElements}</div>
        ) : (
          <p>There is no data to show for the selected vessel.</p>
        )}
      </div>
    );
  };

  let state: JSX.Element | null = null;

  if (Object.keys(singleVesselStatusData).length > 0) {
    state = generateStateData(singleVesselStatusData as StateData);
    console.log("SingleVesselStatusData contents: ", singleVesselStatusData);
  } else {
    console.error(
      "SingleVesselStatusData is empty: ",
      Object.keys(singleVesselStatusData)
    );
  }

  return (
    <Popup
      visible={true}
      target="popupContainer"
      title={vesselName}
      onHide={onClose}
    >
      <div key="fleet-status-popup" className={styles.main}>
        {Object.keys(singleVesselStatusData).length === 0 ? (
          <Loader text="Loading vessel status..." />
        ) : (
          state
        )}
      </div>
    </Popup>
  );
};

export default FleetStatusPopUp;
