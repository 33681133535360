import React from "react";
import styles from "./DataGridView.css";
import { OperationCircle } from "../../../../common/components/Operation/OperationCircle";
import {
  OperationId,
  operationLegend,
  operationName,
} from "../../../../common/operation/operationId";

type OperationModeProps = {
  profile: string;
};

export const OperationMode: React.FC<OperationModeProps> = ({
  profile,
}: OperationModeProps) => {
  // This is a hack to get the operationId from the legend.
  // For some reason the legend is received from the backend as a unique identifier,
  // even though the id should be used for that purpose.
  // Therefore, this component will break if the legends change.
  const operationId = Number(
    Object.entries(operationLegend)
      .find(([, value]) => value === profile)
      ?.at(0)
  ) as OperationId;

  return (
    <div className={styles.operationMode}>
      <OperationCircle operationId={operationId} size={"2rem"} />
      {operationName[operationId]}
    </div>
  );
};
