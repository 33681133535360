import { useDispatch } from "react-redux";
import React from "react";
import { setTargetFuelDataOpen } from "../../../actions/action.fuelEmissions";
import editIcon from "../../../../style/img/edit.svg";
import styles from "./EditTargetButton.module.css";

export const EditTargetButton = () => {
  const dispatch = useDispatch();
  const setTargetFuelDetailsClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    dispatch(setTargetFuelDataOpen());
  };
  return (
    <button
      onClick={setTargetFuelDetailsClick}
      className={styles.editTargetButton}
      title={title}
    >
      <img src={editIcon} height={12} alt={title} title={title} />
    </button>
  );
};

const title = "Edit fuel targets";
