import { color } from "../colors";

export enum OperationClassID {
  DP = 0,
  Transit = 1,
  AHTS = 2,
  Fishing = 3,
  Jacking = 6,
  General = 4,
  Other = 5,
  Port = 7,
}

export const operationClassTitle: Record<OperationClassID, string> = {
  [OperationClassID.DP]: "DP",
  [OperationClassID.Transit]: "Transit",
  [OperationClassID.AHTS]: "Anchor Handling Tug Supply",
  [OperationClassID.Fishing]: "Fishing",
  [OperationClassID.General]: "General",
  [OperationClassID.Jacking]: "Jacking",
  [OperationClassID.Other]: "Other",
  [OperationClassID.Port]: "Port",
};

export const operationClassLegend: Record<OperationClassID, string> = {
  [OperationClassID.DP]: "DP",
  [OperationClassID.Transit]: "T",
  [OperationClassID.AHTS]: "AHTS",
  [OperationClassID.Fishing]: "F",
  [OperationClassID.General]: "G",
  [OperationClassID.Jacking]: "J",
  [OperationClassID.Other]: "O",
  [OperationClassID.Port]: "P",
};

export const operationClassColor: Record<OperationClassID, string> = {
  [OperationClassID.DP]: "#ff9a53",
  [OperationClassID.Transit]: "#2ee4f4",
  [OperationClassID.AHTS]: "#e76670",
  [OperationClassID.Fishing]: "#efce65",
  [OperationClassID.Jacking]: "#018361",
  [OperationClassID.General]: "#757e7e",
  [OperationClassID.Other]: "light grey",
  [OperationClassID.Port]: color("--grey-500"),
};

export const operationClassOrder: Record<OperationClassID, number> = {
  [OperationClassID.DP]: 1,
  [OperationClassID.Transit]: 0,
  [OperationClassID.AHTS]: 3,
  [OperationClassID.Jacking]: 4,
  [OperationClassID.Fishing]: 5,
  [OperationClassID.General]: 6,
  [OperationClassID.Other]: 7,
  [OperationClassID.Port]: 2,
};

export const operationClassIDSort = (
  a: OperationClassID,
  b: OperationClassID
) => operationClassOrder[a] - operationClassOrder[b];
