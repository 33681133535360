import { ADMIN_COMPANIES, FLEET_STATUS } from "../../api";
import { FETCH_ADMIN_COMPANIES, FETCH_FLEET_STATUS } from "../action.types";
import fetch from "../action.fetch";
import { AnyAction } from "redux";

export function fetchFleetStatus(): AnyAction {
  return fetch({
    url: FLEET_STATUS.get(),
    actionType: FETCH_FLEET_STATUS,
  }) as AnyAction;
}

export function fetchCompanies(): AnyAction {
  return fetch({
    url: ADMIN_COMPANIES.get(),
    actionType: FETCH_ADMIN_COMPANIES,
  }) as AnyAction;
}
