import {
  all,
  call,
  fork,
  put,
  take,
  takeLatest,
  takeEvery,
  getContext,
  select,
} from "redux-saga/effects";
import { vesselConfig } from "../../actions/action.types";
import { vesselConfigApi } from "../../api";
import {
  initialState,
  BASELINE,
  ONBOARD_FRONTEND_CONFIG,
  ONBOARD_PROCESSOR_CONFIG,
  ONSHORE_FRONTEND_CONFIG,
  VESSEL_DETAILS,
  VESSEL_ITEMS,
  VESSEL_OPERATIONS,
  VESSEL_PERFORMANCE_INDICATORS,
  HULL_PERFORMANCE_CONFIG,
  ENERGY_FLOW,
} from "../../reducers/admin/vesselConfig/reducer.vesselConfig";
import { initBaseline } from "./baseline.saga";
import { initOnboardFrontendConfig } from "./onboardFrontendConfig.saga";
import { initOnboardProcessorConfig } from "./onboardProcessorConfig.saga";
import { initOnshoreFrontendConfig } from "./onshoreFrontendConfig.saga";
import { initVesselConfigDetails } from "./vesselConfigDetails.saga";
import { initVesselItems } from "./vesselItems.saga";
import { initVesselOperations } from "./vesselOperations.saga";
import { initVesselPerformanceIndicators } from "./vesselPerformanceIndicators.saga";
import { initHullPerformanceConfig } from "./hullPerformanceConfig.saga";
import { initEnergyFlow } from "./energyFlowConfig.saga";

export function* vesselConfigSagas() {
  yield fork(initWatcher);
  yield fork(closeWatcher);
}

function* initWatcher() {
  yield takeLatest(vesselConfig.INIT, init);
}

function* init({ isNew, vesselId }) {
  const configVersion = yield select(
    (state) => state.admin.vesselConfig.configVersion
  );
  try {
    const vessel = isNew ? null : yield call(fetchVessel, vesselId);
    yield put({ type: vesselConfig.INIT_SUCCESS, isNew, vessel });
    yield fork(initPanels, isNew, vessel, configVersion);
  } catch (err) {
    yield put({ type: vesselConfig.INIT_ERROR, error: err });
  }
}

function* fetchVessel(vesselId) {
  const http = yield getContext("http");
  const res = yield call(http.get, vesselConfigApi.get(vesselId));
  return res.data;
}

function* initPanels(isNew, vessel, configVersion) {
  const visiblePanels = initialState.panels
    .filter((p) => p.isVisible)
    .map((p) => p.name);

  yield all(
    visiblePanels.map((p) =>
      fork(initializePanel, p, isNew, vessel, configVersion)
    )
  );
  yield fork(togglePanelWatcher, visiblePanels, isNew, vessel, configVersion);
}

function* togglePanelWatcher(initializedPanels, isNew, vessel, configVersion) {
  let initializedPanelsSet = new Set(initializedPanels);
  while (true) {
    const { name, isVisible } = yield take(vesselConfig.TOGGLE_PANEL);
    if (isVisible && !initializedPanelsSet.has(name)) {
      yield fork(initializePanel, name, isNew, vessel, configVersion);
      initializedPanelsSet.add(name);
    }
  }
}

function* initializePanel(name, isNew, vessel, configVersion) {
  const params = { isNew, vessel, vesselId: isNew ? null : vessel.id };
  switch (name) {
    case VESSEL_DETAILS:
      return yield fork(initVesselConfigDetails, params);
    case ONBOARD_FRONTEND_CONFIG:
      return yield fork(initOnboardFrontendConfig, {
        ...params,
        configVersion,
      });
    case ONBOARD_PROCESSOR_CONFIG:
      return yield fork(initOnboardProcessorConfig, params);
    case ONSHORE_FRONTEND_CONFIG:
      return yield fork(initOnshoreFrontendConfig, params);
    case BASELINE:
      return yield fork(initBaseline, params);
    case VESSEL_OPERATIONS:
      return yield fork(initVesselOperations, params);
    case VESSEL_ITEMS:
      return yield fork(initVesselItems, params);
    case VESSEL_PERFORMANCE_INDICATORS:
      return yield fork(initVesselPerformanceIndicators, params);
    case HULL_PERFORMANCE_CONFIG:
      return yield fork(initHullPerformanceConfig, params);
    case ENERGY_FLOW:
      return yield fork(initEnergyFlow, params);
    default:
      throw new Error(`Unrecognised panel: ${name}`);
  }
}

function* closeWatcher() {
  yield takeEvery(vesselConfig.CLOSE, close);
}

function* close() {
  const history = yield getContext("history");
  yield call(history.push, "/admin/vessels");
}
