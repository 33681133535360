import React from "react";
import { EmissionsTrendSelected } from "../commonTypes";
import FleetCIIScoreSparkline from "../../../../onshore/views/FleetCIIScore/FleetCIIScoreSparkline";
import styles from "./EmissionsTrend.css";
import fuelEmissionsStyles from "../FuelEmissions.css";
import { Emissions } from "./Emissions";
import Loader from "../../../../common/components/Loader";
import { useAppSelector } from "../../../store/rootState";
import { useRefSize } from "../../../../hooks/useRefSize";

const EmissionsTrend = () => {
  const { series, selected, isLoading } = useAppSelector(
    (state) => state.fuelEmissions.emissionsTrend
  );
  const isEmissions = selected === EmissionsTrendSelected.Emissions;
  if (isLoading)
    return (
      <div className={fuelEmissionsStyles.girdItemLoader}>
        <Loader />
      </div>
    );

  return <Content series={series} isEmissions={isEmissions} />;
};
interface ContentProps {
  series: any;
  isEmissions: boolean;
}

const Content = ({ series, isEmissions }: ContentProps) => {
  const [ref, width, height] = useRefSize<HTMLDivElement>();
  return (
    <div className={styles.container} ref={ref}>
      {isEmissions ? (
        <Emissions data={series.emissions} width={width} height={height} />
      ) : (
        <FleetCIIScoreSparkline
          data={series.cii}
          padding={40}
          width={width}
          height={height}
        />
      )}
    </div>
  );
};

export default EmissionsTrend;
