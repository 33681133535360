import React, { useState, useEffect, useMemo } from "react";
import styles from "./RadioButton.css";

interface Props {
  options: Option[];
  onChange?(o: Option): unknown;
}

export interface Option {
  id?: number | string;
  name: string;
  selected?: boolean;
}

const getId = (o: Option) => `${o.id ?? o.name}`;

export function RadioButton({ options, onChange }: Readonly<Props>) {
  const [optionsList, setOptionsList] = useState([] as Option[]);
  const componentId = useMemo(
    () => `radio_${Math.random().toString().slice(2)}_`,
    []
  );

  useEffect(() => {
    setOptionsList(options.map((o) => ({ ...o })));
  }, [options]);

  const handleOptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    const newOptions = optionsList.map((o) => ({
      ...o,
      selected: e.target.value === getId(o),
    }));
    const selectedEl = newOptions.find((o) => o.selected);
    setOptionsList(newOptions);
    selectedEl && onChange?.(selectedEl);
  };

  return (
    <ul className={styles.radioGroup}>
      {optionsList.map((o, i) => (
        <li key={`${componentId}${getId(o)}`}>
          <input
            type="radio"
            id={`${componentId}${i}`}
            value={getId(o)}
            name={componentId}
            checked={!!o.selected}
            onChange={handleOptionChange}
          />
          <label htmlFor={`${componentId}${i}`}>{o.name}</label>
        </li>
      ))}
    </ul>
  );
}

export default RadioButton;
