import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Header } from "../../../components/Header";
import FleetStatusPopUp from "./FleetStatusPopUp";
import { fetchCompanies, fetchFleetStatus } from "../../../actions";
import styles from "./FleetStatus.css";
import { AppState } from "./FleetStatusData";
import Loader from "../../../../common/components/Loader";
import SelectBox from "../../../components/SelectBox";
import adminStyles from "../Admin.css";
import DataGrid from "../../../components/DataGrid/DataGrid";
import RenderDot from "../../../components/RenderDot/RenderDot";

interface StatusRow {
  imo: number;
  vesselName: string;
  companyID: string;
  companyName: string;
  overallState: number;
  connectivityState: number;
  hullState: number;
  propulsionState: number;
  engineState: number;
  boilerState: number;
  batteriesState: number;
  shoreConnectionState: number;
  consumerState: number;
  [key: string]: any;
}

interface Company {
  id: string;
  name: string;
  imageUrl: string;
  tenantId: string;
  companyModules: string[];
}

interface SortField {
  sortBy: string;
  sortDirection: "asc" | "desc";
}

const FleetStatus: React.FC = () => {
  const [isPopupVisible, setIsPopupVisible] = useState<boolean>(false);
  const [selectedImo, setSelectedImo] = useState<number>(0);
  const [selectedVesselName, setSelectedVesselName] = useState<string>("");
  const [selectedCompany, setSelectedCompany] = useState<Company | null>(null);

  const [sortBy, setSortBy] = useState<string>("vesselName");
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");

  const dispatch = useDispatch();
  const { data: fleetStatusData, companiesStatus: companiesData } = useSelector(
    (state: AppState) => ({
      data: state.fleetStatus?.data ?? [],
      companiesStatus: state.companiesStatus?.data ?? [],
    })
  );

  useEffect(() => {
    dispatch(fetchFleetStatus());
    dispatch(fetchCompanies());
  }, [dispatch]);

  const allCompaniesOption: Company = {
    id: "all",
    name: "All Companies",
    imageUrl: "",
    tenantId: "",
    companyModules: [],
  };

  const companyOptions = [
    allCompaniesOption,
    ...(Array.isArray(companiesData) ? companiesData : []),
  ];

  const handleRowClick = (imo: number, vesselName: string): void => {
    setSelectedImo(imo);
    setSelectedVesselName(vesselName);
    setIsPopupVisible(true);
  };

  const gridConfig = {
    columns: [
      {
        header: "Vessel",
        field: "vesselName",
        flexGrow: 4,
      },
      {
        header: "Imo",
        field: "imo",
        flexGrow: 1,
      },
      {
        header: "Overall Status",
        field: "overallStatus",
        template: (rowData: StatusRow) => (
          <RenderDot status={rowData.overallState} />
        ),
      },
      {
        header: "Connectivity",
        field: "connectivity",
        template: (rowData: StatusRow) => (
          <RenderDot status={rowData.connectivityState} />
        ),
      },
      {
        header: "Hull",
        field: "hull",
        template: (rowData: StatusRow) => (
          <RenderDot status={rowData.hullState} />
        ),
      },
      {
        header: "Propulsion",
        field: "propulsion",
        template: (rowData: StatusRow) => (
          <RenderDot status={rowData.propulsionState} />
        ),
      },
      {
        header: "Engine",
        field: "engine",
        template: (rowData: StatusRow) => (
          <RenderDot status={rowData.engineState} />
        ),
      },
      {
        header: "Boiler",
        field: "boiler",
        template: (rowData: StatusRow) => (
          <RenderDot status={rowData.boilerState} />
        ),
      },
      {
        header: "Batteries",
        field: "batteries",
        template: (rowData: StatusRow) => (
          <RenderDot status={rowData.batteriesState} />
        ),
      },
      {
        header: "Shore Connection",
        field: "shoreConnection",
        template: (rowData: StatusRow) => (
          <RenderDot status={rowData.shoreConnectionState} />
        ),
      },
      {
        header: "Consumer",
        field: "consumer",
        template: (rowData: StatusRow) => (
          <RenderDot status={rowData.consumerState} />
        ),
      },
    ],
  };

  const filteredFleetStatusData = (fleetStatusData as StatusRow[]).filter(
    (vessel) => {
      if (!selectedCompany) {
        return true;
      }
      // Should be on ID, but that does not work due to current global solution of companies.
      return vessel.companyName === selectedCompany.name;
    }
  );

  const sortData = (field: keyof StatusRow, direction: "asc" | "desc") => {
    const sortedData = [...filteredFleetStatusData].sort((a, b) => {
      const aValue =
        field === "imo" ? a[field] : String(a[field]).toLowerCase();
      const bValue =
        field === "imo" ? b[field] : String(b[field]).toLowerCase();

      if (typeof aValue === "string" && typeof bValue === "string") {
        // Alphabetical sorting
        if (direction === "asc") {
          return aValue.localeCompare(bValue);
        } else {
          return bValue.localeCompare(aValue);
        }
      } else {
        // Numeric sorting
        const aValueNumeric = Number(aValue);
        const bValueNumeric = Number(bValue);

        if (!isNaN(aValueNumeric) && !isNaN(bValueNumeric)) {
          // Both values are numeric
          if (direction === "asc") {
            return aValueNumeric - bValueNumeric;
          } else {
            return bValueNumeric - aValueNumeric;
          }
        } else {
          return 0; // Maintain current order
        }
      }
    });

    return sortedData;
  };

  const onClose = useCallback(() => {
    setIsPopupVisible(false);
  }, []);

  return (
    <div>
      <Header title="Fleet Status" contentDistribution="space-between">
        <div className={adminStyles.headerContainer}>
          <SelectBox
            options={companyOptions}
            optionValKey="id"
            optionLabelKey="name"
            onSelect={(selectedCompany: Company) => {
              selectedCompany.id === "all"
                ? setSelectedCompany(null)
                : setSelectedCompany(selectedCompany);
            }}
          />
        </div>
      </Header>
      {Array.isArray(filteredFleetStatusData) &&
      filteredFleetStatusData.length > 0 ? (
        <div className={styles.dataGridContainer}>
          <DataGrid
            data={sortData(sortBy, sortDirection)}
            onRowClicked={(
              index: any,
              rowData: { imo: number; vesselName: string }
            ) => {
              const { imo, vesselName } = rowData;
              handleRowClick(imo, vesselName);
            }}
            sortBy={sortBy} // Ensure sortBy is a string
            sortDirection={sortDirection}
            onSort={(field: SortField) => {
              const sortByFieldName = field.sortBy;
              const direction = field.sortDirection;

              setSortBy(sortByFieldName.toString());
              setSortDirection(direction);
            }}
            {...gridConfig}
          />
        </div>
      ) : !selectedCompany ? (
        <Loader text="Loading fleet status..." expand={true} />
      ) : (
        <div>
          <p>There are no vessels to display for the selected company.</p>
        </div>
      )}
      {isPopupVisible && (
        <FleetStatusPopUp
          onClose={onClose}
          imo={selectedImo}
          vesselName={selectedVesselName}
        />
      )}
    </div>
  );
};

export default FleetStatus;
