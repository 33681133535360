import React from "react";
import * as d3 from "d3";
import { isFinite } from "lodash";
import PropTypes from "prop-types";
import { getUniqueXValues, getUniqueYValues } from "./SparklineHelpers";
import { getScale } from "../../components/Helpers/YScale";
import memoize from "memoize-one";

export default class Sparkline extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      addMarginProvider: this.addMarginProvider,
      removeMarginProvider: this.removeMarginProvider,
      marginProviders: [],
      deselectedSeries: new Set(),
    };
  }

  onLegendClicked = (dataSetIndex) => {
    if (this.state.deselectedSeries.has(dataSetIndex)) {
      this.state.deselectedSeries.delete(dataSetIndex);
    } else {
      this.state.deselectedSeries.add(dataSetIndex);
    }

    this.setState({ deselectedSeries: new Set(this.state.deselectedSeries) });
  };

  uniqueXValues = memoize((data) =>
    getUniqueXValues(data, this.props.dontSortXAxis)
  );

  uniqueYValues = memoize((data) => getUniqueYValues(data));

  addMarginProvider = (target) => {
    this.setState((prevState) => ({
      marginProviders: [...prevState.marginProviders, target],
    }));
  };

  removeMarginProvider = (target) => {
    const index = this.state.marginProviders.indexOf(target);
    this.setState((prevState) => ({
      marginProviders: prevState.marginProviders.splice(index, 1),
    }));
  };

  getMargins = (state) => {
    const { margin } = this.props;

    const margins = {
      top: 10,
      left: 0,
      bottom: 0,
      right: 0,
    };

    if (margin) {
      if (isFinite(margin)) {
        margins.top += margin;
        margins.left += margin;
        margins.bottom += margin;
        margins.right += margin;
      } else {
        margins.top += margin.top || 0;
        margins.left += margin.left || 0;
        margins.bottom += margin.bottom || 0;
        margins.right += margin.right || 0;
      }
    }

    return state.marginProviders.reduce((prev, it) => {
      const providerMargins = it.getMargins();
      prev.left += providerMargins.left || 0;
      prev.right += providerMargins.right || 0;
      prev.top += providerMargins.top || 0;
      prev.bottom += providerMargins.bottom || 0;
      return prev;
    }, margins);
  };

  prepareData = memoize((data, dataConverter) => {
    if (dataConverter) {
      data = dataConverter(data);
    }

    if (!data || data.length === 0) {
      return [[]];
    } else if (!Array.isArray(data[0])) {
      return [data];
    }
    return data;
  });

  getMemoizedContext = memoize(
    (
      state,
      data,
      dataConverter,
      xDomain,
      yDomain,
      width,
      height,
      limit,
      scaleX,
      xScaleType,
      xScalePadding,
      xScalePaddingOuter,
      yScaleType,
      yScalePadding,
      yScalePaddingOuter,
      yScaleProperties,
      onYDomainCreated,
      useUniqueValuesForBand,
      showEqualAspectRatio
    ) => {
      const preparedData = this.limitPreparedData(
        limit,
        this.prepareData(data, dataConverter)
      );

      if (yDomain && yDomain.length > 0 && !Array.isArray(yDomain[0])) {
        yDomain = [[...yDomain]];
      }

      const { minX, maxX, minY, maxY } = this.getXAndYMinMax(
        xDomain,
        yDomain,
        preparedData
      );

      if (!xDomain) {
        xDomain = [minX, maxX];
      }
      if (!yDomain) {
        yDomain = [[...minY, ...maxY]];
      }
      if (onYDomainCreated) {
        yDomain = onYDomainCreated(yDomain);
      }

      const margins = this.getMargins(state);
      const { innerWidth, innerHeight } = this.getInnerSize(
        showEqualAspectRatio,
        height,
        width,
        margins
      );
      const { dontSortXAxis } = this.props;

      let scaleY;
      let uniqueXValues, uniqueYValues;
      let xOffset = 0,
        yOffset;
      if (!scaleX) {
        switch (xScaleType) {
          case "band":
            uniqueXValues = this.uniqueXValues(preparedData, dontSortXAxis);
            scaleX = this.getScaleForBandScaleType(
              [0, innerWidth],
              xScalePadding,
              xScalePaddingOuter,
              useUniqueValuesForBand,
              uniqueXValues,
              xDomain
            );
            scaleX.invert = (() => {
              const domain = scaleX.domain();
              const range = scaleX.range();
              const scale = d3.scaleQuantize().domain(range).range(domain);
              return (x) => {
                return scale(x);
              };
            })();
            xOffset = scaleX.bandwidth() / 2;
            break;
          case "utc":
            scaleX = d3.scaleUtc().domain(xDomain).rangeRound([0, innerWidth]);
            break;
          default:
            scaleX = d3
              .scaleLinear()
              .domain(xDomain)
              .range(minX < 0 ? [-innerWidth, 0] : [0, innerWidth]);
        }
      }

      if (yScaleType === "band") {
        uniqueYValues = this.uniqueYValues(preparedData);
        scaleY = yDomain.map((d) =>
          this.getScaleForBandScaleType(
            [innerHeight, 0],
            yScalePadding,
            yScalePaddingOuter,
            useUniqueValuesForBand,
            uniqueYValues,
            d
          )
        );
        yOffset = scaleY.map((s) => s.bandwidth() / 2);
      } else {
        scaleY = yDomain.map((d) =>
          getScale(yScaleType, yScaleProperties)
            .domain(d)
            .range([innerHeight, 0])
        );
        yOffset = yDomain.map(() => 0);
      }

      return {
        data: preparedData,
        width: innerWidth,
        height: innerHeight,
        limit,
        scaleX,
        margins,
        scaleY,
        minX,
        maxX,
        minY: minY,
        maxY: maxY,
        xDomain,
        yDomain,
        xScaleType,
        xScalePadding,
        yScaleType,
        yScaleProperties,
        uniqueXValues,
        uniqueYValues,
        xOffset,
        yOffset,
        addMarginProvider: state.addMarginProvider,
        removeMarginProvider: state.removeMarginProvider,
        useUniqueValuesForBand,
        onLegendClicked: this.onLegendClicked,
        deselectedSeries: this.state.deselectedSeries,
      };
    }
  );

  getXAndYMinMax(xDomain, yDomain, preparedData) {
    const maxX = xDomain
      ? d3.max(xDomain)
      : d3.max(preparedData, (dataSet) => d3.max(dataSet, (d) => d.x));
    const maxY =
      yDomain && yDomain.length > 0
        ? yDomain.map((d) => d[1])
        : [d3.max(preparedData, (dataSet) => d3.max(dataSet, (d) => d.y))];
    const minX = xDomain
      ? d3.min(xDomain)
      : d3.min(preparedData, (dataSet) => d3.min(dataSet, (d) => d.x));
    const minY =
      yDomain && yDomain.length > 0
        ? yDomain.map((d) => d[0])
        : [d3.min(preparedData, (dataSet) => d3.min(dataSet, (d) => d.y))];
    return { minX, maxX, minY, maxY };
  }

  limitPreparedData(limit, preparedData) {
    if (limit <= 0) return preparedData;

    for (let i = 0; i < preparedData.length; i++) {
      const dataSet = preparedData[i];
      if (limit < dataSet.length) {
        preparedData[i] = dataSet.slice(dataSet.length - limit);
      }
    }

    return preparedData;
  }

  getInnerSize(showEqualAspectRatio, height, width, margins) {
    const innerWidth =
      (showEqualAspectRatio !== null && showEqualAspectRatio ? height : width) -
      margins.left -
      margins.right;
    const innerHeight = height - margins.top - margins.bottom;
    return { innerWidth, innerHeight };
  }

  getScaleForBandScaleType(
    innerRange,
    scalePadding,
    scalePaddingOuter,
    useUniqueValuesForBand,
    uniqueValues,
    domain
  ) {
    return d3
      .scaleBand()
      .rangeRound(innerRange)
      .paddingInner(scalePadding)
      .paddingOuter(scalePaddingOuter)
      .domain(
        useUniqueValuesForBand && uniqueValues.length > 0
          ? uniqueValues
          : domain
      );
  }

  render() {
    const {
      data,
      dataConverter,
      xDomain,
      yDomain,
      width,
      height,
      limit,
      scaleX,
      xScaleType,
      xScalePadding,
      xScalePaddingOuter,
      yScaleType,
      yScalePadding,
      yScalePaddingOuter,
      yScaleProperties,
      onYDomainCreated,
      useUniqueValuesForBand,
      showEqualAspectRatio,
    } = this.props;

    let context = this.getMemoizedContext(
      this.state,
      data,
      dataConverter,
      xDomain,
      yDomain,
      width,
      height,
      limit,
      scaleX,
      xScaleType,
      xScalePadding,
      xScalePaddingOuter,
      yScaleType,
      yScalePadding,
      yScalePaddingOuter,
      yScaleProperties,
      onYDomainCreated,
      useUniqueValuesForBand,
      showEqualAspectRatio
    );

    const svgOpts = {
      width:
        showEqualAspectRatio !== null && showEqualAspectRatio ? height : width,
      height: height,
    };

    return (
      <SparklineContext.Provider value={context}>
        <svg {...svgOpts}>
          <g
            transform={`translate(${context.margins.left} ${context.margins.top})`}
          >
            {this.props.children}
          </g>
        </svg>
      </SparklineContext.Provider>
    );
  }
}

Sparkline.defaultProps = {
  xScaleType: "linear",
  yScaleType: "linear",
  xScalePadding: 0,
  xScalePaddingOuter: 0,
  yScalePadding: 0,
  yScalePaddingOuter: 0,
  yScaleProperties: {},
  useUniqueValuesForBand: true,
  dontSortXAxis: false,
};

const mobxArrayPropType = PropTypes.shape({
  $mobx: PropTypes.object.isRequired,
  slice: PropTypes.func.isRequired,
});

Sparkline.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  margin: PropTypes.any,
  limit: PropTypes.number,
  xDomain: PropTypes.arrayOf(PropTypes.any, PropTypes.any),
  yDomain: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.any, PropTypes.any),
    PropTypes.arrayOf(
      PropTypes.arrayOf(PropTypes.any, PropTypes.any),
      PropTypes.arrayOf(PropTypes.any, PropTypes.any)
    ),
  ]),
  scaleX: PropTypes.func,
  data: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.any),
    PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.any)),
    mobxArrayPropType,
    PropTypes.arrayOf(
      PropTypes.shape({
        x: PropTypes.any,
        y: PropTypes.any,
      })
    ),
    PropTypes.arrayOf(
      PropTypes.arrayOf(
        PropTypes.shape({
          x: PropTypes.any,
          y: PropTypes.any,
        })
      )
    ),
  ]),
  xScaleType: PropTypes.oneOf(["linear", "band", "utc"]),
  xScalePadding: PropTypes.number,
  xScalePaddingOuter: PropTypes.number,
  yScalePadding: PropTypes.number,
  yScalePaddingOuter: PropTypes.number,
  yScaleType: PropTypes.oneOf([
    "linear",
    "band",
    "utc",
    "logarithmic",
    "exponential",
  ]),
  yScaleProperties: PropTypes.shape({
    exponent: PropTypes.number,
  }),
  onYDomainCreated: PropTypes.func,
  dataConverter: PropTypes.func,
  useUniqueValuesForBand: PropTypes.bool,
  dontSortXAxis: PropTypes.bool,
};

export const SparklineContext = React.createContext({});
