import { SINGLE_VESSEL_STATUS } from "../../api";
import { FETCH_SINGLE_VESSEL_STATUS } from "../action.types";
import fetch from "../action.fetch";
import { AnyAction } from "redux";

export function fetchSingleVesselStatus(imo: number): AnyAction {
  return fetch({
    url: SINGLE_VESSEL_STATUS.get(imo),
    actionType: FETCH_SINGLE_VESSEL_STATUS,
  }) as AnyAction;
}
