import React from "react";
import PropTypes from "prop-types";
import styles from "./DataDetailsListItem.css";
import DataDetails from "./DataDetails";
import * as PropertyValues from "../PropertyValues/PropertyValues";
import Loader from "../../../common/components/Loader/Loader";

class DataDetailsListItem extends React.PureComponent {
  state = {
    open: false,
    loading: false,
    loaded: !!DataDetailsListItem.getData(this.props),
  };

  constructor(props) {
    super(props);

    this.toggleOpen = this.toggleOpen.bind(this);
    this.changeResolutionStatus = this.changeResolutionStatus.bind(this);
  }

  toggleOpen() {
    const loading =
      this.state.loading ||
      (this.state.open === false && this.state.loaded === false);
    if (loading && this.state.loading === false && this.props.onLoad) {
      this.props.onLoad(this.props);
    }
    this.setState((prevState) => ({
      loading,
      open: !prevState.open,
    }));
  }

  static getData(props) {
    const { data: propData, dataProvider } = props;
    return propData || (dataProvider && dataProvider(props)) || null;
  }

  static getDerivedStateFromProps(props, state) {
    if (DataDetailsListItem.getData(props) && state.loading === true) {
      return {
        loading: false,
        loaded: true,
      };
    }
    return null;
  }

  renderItemProperties() {
    const { properties } = this.props;

    if (!properties) return;
    return properties
      .filter((prop) => prop.hidden !== true)
      .map((prop) => {
        if (prop.propertyType === "Ehm.Resolution") {
          prop = { ...prop, onSelect: this.changeResolutionStatus };
        }
        if (prop.propertyType === "Ehm.Tooltip") {
          return (
            <div className={styles.tooltip} key={`prop_${prop.name}`}>
              {PropertyValues.createPropertyValueComponent(prop)}
            </div>
          );
        }
        return (
          <div className={styles.propertyContainer} key={`prop_${prop.name}`}>
            {PropertyValues.createPropertyValueComponent(prop)}
          </div>
        );
      });
  }
  changeResolutionStatus(selected) {
    switch (selected.name) {
      case "Acknowledge":
        this.props.acknowledgeEvent(this.props);
        break;
      case "Resolved":
        this.props.resolveEvent(this.props);
        break;
      case "Pending Action":
        this.props.revertEvent(this.props);
        break;
    }
  }

  render() {
    const data = DataDetailsListItem.getData(this.props);
    const { open, loaded, loading } = this.state;

    return (
      <div
        className={styles.outerContainer}
        data-open={open ? "open" : "closed"}
      >
        <div onClick={this.toggleOpen} className={styles.itemContainer}>
          {this.renderItemProperties()}
        </div>
        {open && loading && (
          <div className={styles.detailsContainer}>
            <Loader />
          </div>
        )}
        {open && loaded && (
          <div className={styles.detailsContainer}>
            <DataDetails dataDetails={data} />
          </div>
        )}
      </div>
    );
  }
}

DataDetailsListItem.propTypes = {
  onLoad: PropTypes.func,
  dataProvider: PropTypes.func,
};

export default DataDetailsListItem;
