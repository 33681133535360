// noinspection SpellCheckingInspection

import { createUrl } from "../common/urls";

export const VESSELS = {
  get: () => "/api/vessels",
  getWithId: (id) => `/api/vessels/${id}`,
  getExport: (id, { from, to }, startTimeOffset, endTimeOffset, filters) =>
    createUrl(`/api/v2/vessels/${id}/export`, {
      from,
      to,
      startTimeOffset,
      endTimeOffset,
      filters,
    }),
  getOperationExport: (
    id,
    { from, to },
    startTimeOffset,
    endTimeOffset,
    filters
  ) =>
    createUrl(`/api/v2/vessels/${id}/operationExport`, {
      from,
      to,
      startTimeOffset,
      endTimeOffset,
      filters,
    }),
  getPositions: (id, level) =>
    createUrl(`/api/v2/vessels/${id}/positions`, { level }),
  getPositionsForPeriod: (id, { from, to }, level, timeOffset) =>
    createUrl(`/api/v2/vessels/${id}/positions`, {
      from,
      to,
      level,
      timeOffset,
    }),
  getMachineryStatus: (id, { from, to }, timeOffset) =>
    createUrl(`/api/v2/vessels/${id}/machinery/status`, {
      from,
      to,
      timeOffset,
    }),
};

export const MACHINERYREPORTS = {
  getExport: (id, { from, to }, startTimeOffset, endTimeOffset) =>
    createUrl(`/api/v2/vessels/${id}/machinery/export`, {
      from,
      to,
      startTimeOffset,
      endTimeOffset,
    }),
};

export const POSITION_TRACK = {
  get: (vesselId, { from, to }, timeOffset) =>
    createUrl(`/api/v2/data/map/positiontrack/vessel/${vesselId}`, {
      from,
      to,
      timeOffset,
    }),
};

export const GROUPS = {
  get: () => "/api/groups",
};

export const FLEET_TABLE = {
  get: (groupId, { from, to }, timeOffset) =>
    createUrl(`/api/v2/data/fleettable/${groupId}`, {
      from,
      to,
      timeOffset,
    }),
};

export const ITEM_TYPE_TABLE = {
  get: (vesselId, { from, to }, timeOffset, filters) =>
    createUrl(`/api/v2/data/itemtypetable/vessel/${vesselId}/`, {
      from,
      to,
      timeOffset,
      filters,
    }),
};

export const VESSEL_ITEM_DETAILS = {
  get: (vesselItemId, { from, to }, timeOffset, filters) =>
    createUrl(`/api/v2/data/vesselitemdetails/${vesselItemId}`, {
      from,
      to,
      timeOffset,
      filters,
    }),
};

export const HEALTH_MANAGEMENT_EVENT_DETAILS = {
  get: (eventId) => `/api/data/healthmanagementeventdetails/${eventId}`,
};

export const MAP_DATA = {
  get: () => "/api/v2/data/map",
  getVessel: (vesselId, { from, to }, timeOffset, filters) =>
    createUrl(`/api/v2/data/map/vessel/${vesselId}`, {
      from,
      to,
      timeOffset,
      filters,
    }),
};

export const POSITION_DETAILS = {
  get: (vesselId, date) =>
    `/api/v2/data/map/positiondetails/vessel/${vesselId}?queryDate=${date}`,
};

export const DONUT = {
  getVessel: (itemTypeMetricId, vesselId, { from, to }, timeOffset, filters) =>
    createUrl(
      `/api/v2/data/donut/vessel/${vesselId}/itemtypemetric/${itemTypeMetricId}`,
      { from, to, timeOffset, filters }
    ),
  getGroup: (itemTypeMetricId, groupId, { from, to }, timeOffset) =>
    createUrl(
      `/api/v2/data/donut/vesselgroup/${groupId}/itemtypemetric/${itemTypeMetricId}/`,
      { from, to, timeOffset }
    ),
};

export const GAUGE = {
  getVessel: (
    performanceIndicatorId,
    vesselId,
    { from, to },
    timeOffset,
    filters
  ) =>
    createUrl(
      `/api/v2/data/gauge/vessel/${vesselId}/performanceindicator/${performanceIndicatorId}`,
      { from, to, timeOffset, filters }
    ),
  getGroup: (performanceIndicatorId, groupId, { from, to }, timeOffset) =>
    createUrl(
      `/api/v2/data/gauge/vesselgroup/${groupId}/performanceindicator/${performanceIndicatorId}`,
      { from, to, timeOffset }
    ),
  getFuelEfficiencyToday: (performanceIndicatorId) =>
    `/api/v2/data/gauge/fuelEfficiency/today/${performanceIndicatorId}`,
  getFuelEfficiencyYesterday: (performanceIndicatorId) =>
    `/api/v2/data/gauge/fuelEfficiency/yesterday/${performanceIndicatorId}`,
};

export const FLEET_OVERVIEW = {
  get: () => "/api/v2/data/donut/fleetoverview",
};

export const CONNECTION_STATUS = {
  get: () => "/api/v2/data/vesselcount/connectionstatus",
};

export const BEST_PERFORMANCE = {
  get: ({ from, to }, count = 5) =>
    createUrl("/api/data/bestperformance", { count, from, to }),
};

export const MONTHLY_FUEL_USAGE = {
  get: (itemTypeMetricId) =>
    `/api/v2/data/monthlyFuelUsage/itemTypeMetric/${itemTypeMetricId}`,
};
export const VESSEL_FUEL_CONSUMPTION = {
  get: (vesselId, from, to, timeOffset, filters) =>
    createUrl(`/api/v2/data/vesselFuelConsumption/vessel/${vesselId}`, {
      from,
      to,
      timeOffset,
      filters,
    }),
};

export const COMPARE = {
  get: (vesselId, { from, to }, operationIds, timeOffset = []) =>
    createUrl(`/api/v2/compare/${vesselId}`, {
      from,
      to,
      operationIds,
      timeOffset,
    }),
};

export const USER = {
  get: () => "/api/me",
  putSettings: () => "/api/me/settings",
};

export const TENANTUSER = {
  get: (email) => `/api/tenantUser?user=${email}`,
};

export const API_ALIVE = {
  get: () => "/api/status",
};

export const TREND = {
  getOperationTrend: (vesselId, { from, to }, timeOffset, filters) =>
    createUrl(`/api/v2/trend/vessel/${vesselId}/operation`, {
      from,
      to,
      timeOffset,
      filters,
    }),
  getTrendForMetrics: (
    vesselId,
    metricIds,
    vesselItemIds,
    { from, to },
    timeOffset,
    filters
  ) =>
    createUrl(`/api/v2/trend/vessel/${vesselId}/metrics`, {
      from,
      to,
      timeOffset,
      metricIds,
      vesselItemIds,
      filters,
    }),
  getTrendForPerformanceIndicator: (
    vesselId,
    performanceIndicatorIds,
    { from, to },
    timeOffset,
    filters
  ) =>
    createUrl(`/api/v2/trend/vessel/${vesselId}/performance`, {
      from,
      to,
      timeOffset,
      performanceIndicatorId: performanceIndicatorIds,
      filters,
    }),
};

export const CALENDAR = {
  getOperationalData: (vesselId, { from, to }, timeOffset) =>
    createUrl(`/api/calendar/${vesselId}/operationaldata`, {
      from,
      to,
      timeOffset,
    }),
  fetchCalendarHealthEvents: (vesselId, { from, to }, timeOffset) =>
    createUrl(`/api/calendar/${vesselId}/healthevents`, {
      from,
      to,
      timeOffset,
    }),
};

export const HEALTH_EVENTS = {
  getGroup: (groupId, { from, to }) =>
    createUrl(`/api/healthevents/group/${groupId}`, { from, to }),
  getVessel: (vesselId, { from, to }) =>
    createUrl(`/api/healthevents/vessel/${vesselId}`, { from, to }),
};
export const MANUAL_FUEL_DATA = {
  getDataForVessel: (vesselId, { from, to }, timeOffset) =>
    createUrl(`/api/v2/manualfueldata/${vesselId}`, {
      from,
      to,
      timeOffset,
    }),
  getConfigForVessel: (vesselId) =>
    createUrl(`/api/v2/manualfueldata/${vesselId}/config`),
  addManualFuelData: (vesselId) =>
    createUrl(`/api/v2/manualfueldata/${vesselId}`),
  deleteManualFuelData: (vesselId, date) =>
    createUrl(`/api/v2/manualfueldata/${vesselId}`, {
      date,
    }),
};
export const EVENTS = {
  getEventsForVessel: (vesselId, { from, to }, timeOffset) =>
    createUrl(`/api/v2/data/event/vessel/${vesselId}`, {
      from,
      to,
      timeOffset,
    }),
  createUpdateEvent: (vesselId) => `/api/v2/data/event/vessel/${vesselId}`,
  deleteEvent: (eventId) => `/api/v2/data/event/${eventId}`,
  getEventsForVesselByEventType: (vesselId, eventTypeId) =>
    `/api/v2/data/event/vessel/${vesselId}/${eventTypeId}`,
};

export const VESSEL_STATUS = {
  get: () => "/api/v2/admin/service/vesselStatus",
};

export const FLEET_STATUS = {
  get: () => "/api/admin/fleetstatus",
};

export const SINGLE_VESSEL_STATUS = {
  get: (imo) => createUrl(`/api/admin/singleVesselStatus/${imo}`),
};

export const VESSEL_STATUS_HISTORY = {
  get: (vesselId, { from, to }, timeOffset) =>
    createUrl(`/api/v2/admin/service/history/vessel/${vesselId}`, {
      from,
      to,
      timeOffset,
    }),
};

export const ENERGY_BUDGET_DATA = {
  getOverview: ({ metricIds, groupBy, historicalCount, futureCount }) =>
    createUrl(`/api/v2/data/energybudget/`, {
      metricIds,
      groupBy,
      historicalCount,
      futureCount,
    }),
  getResultForVessel: (
    vesselId,
    metricIds,
    { from, to },
    timeOffset,
    filters
  ) =>
    createUrl(`/api/v2/data/energybudget/vessel/${vesselId}/result`, {
      from,
      to,
      timeOffset,
      metricIds,
      filters,
    }),
  getResultForGroup: (groupId, metricIds, { from, to }, timeOffset) =>
    createUrl(`/api/v2/data/energybudget/group/${groupId}/result`, {
      from,
      to,
      timeOffset,
      metricIds,
    }),
};

export const ENERGY_BUDGET_ADMIN = {
  getVessels: () => "/api/data/energybudget/admin/vessels",
  getForVessel: (vesselId, metricId) =>
    `/api/data/energybudget/admin/vessel/${vesselId}/${metricId}`,
  postForVessel: (vesselId, metricId) =>
    `/api/data/energybudget/admin/vessel/${vesselId}/${metricId}`,
};

export const ADMIN_USERS = {
  getById: (id) => `/api/admin/users/${id}`,
  get: (companyId) => createUrl("/api/admin/users", { companyId }),
  post: () => `/api/admin/users`,
  import: () => "/api/admin/users/import",
  put: (id) => `/api/admin/users/${id}`,
  passwordReset: (id) => `/api/admin/users/${id}/sendPasswordReset`,
  getUserGroups: (userId) => `/api/admin/users/${userId}/groups`,
  putUserGroupsInCompany: (userId, companyId) =>
    `/api/admin/users/${userId}/groups/company/${companyId}`,
  deactivate: (userId) => `/api/admin/users/${userId}/deactivate`,
  reactivate: (userId) => `/api/admin/users/${userId}/reactivate`,
  delete: (userId) => `/api/admin/users/${userId}/delete`,
  generateApiSecret: (userId) =>
    `/api/admin/users/${userId}/generateApiClientSecret`,
  clearApiSecret: (userId) => `/api/admin/users/${userId}/clearApiClientSecret`,
};

export const ADMIN_COMPANIES = {
  getById: (id) => `/api/admin/companies/${id}`,
  get: () => `/api/admin/companies`,
  post: () => `/api/admin/companies`,
  put: (id) => `/api/admin/companies/${id}`,
};

export const ADMIN_OPERATIONS = {
  get: () => `/api/admin/operations`,
};

export const ADMIN_PERFORMANCE_INDICATORS = {
  get: () => `/api/admin/performanceIndicators`,
};

export const ADMIN_PAGE_CONFIG = {
  getById: (id) => `/api/admin/pageconfigs/${id}`,
  get: (companyId) => createUrl("/api/admin/pageconfigs", { companyId }),
  post: () => `/api/admin/pageconfigs`,
  put: (id) => `/api/admin/pageconfigs/${id}`,
  delete: (id) => `/api/admin/pageconfigs/${id}`,
};

export const ADMIN_VESSELS = {
  get: (companyId) => createUrl("/api/admin/vessels", { companyId }),
  postSensorUpdateRequest: (id) =>
    `/api/admin/vessels/${id}/sensorUpdateRequest`,
  generateApiToken: (id) => `/api/admin/vessels/${id}/generateApiToken`,
};

export const ADMIN_ITEMTYPES = {
  get: () => "/api/admin/itemtypes",
};

export const ADMIN_FUELTYPES = {
  get: () => "/api/admin/fueltypes",
};

export const ADMIN_ITEM_CONFIG = {
  getContext: () => "/api/admin/itemconfigs/context",
  configJson: ({ itemTypeId, vesselId, vesselItemId }) =>
    createUrl(`/api/admin/itemconfigs/itemType/${itemTypeId}/configJson`, {
      vesselId,
      vesselItemId,
    }),
};

export const ADMIN_VESSEL_REMOTEACCESS = {
  put: (id) => `/api/admin/vessels/${id}/remoteAccessControl`,
};

export const ADMIN_VESSEL_GROUPS = {
  getById: (id) => `/api/admin/vesselgroups/${id}`,
  get: (companyId) => createUrl("/api/admin/vesselgroups", { companyId }),
  post: () => "/api/admin/vesselgroups",
  put: (id) => `/api/admin/vesselgroups/${id}`,
  delete: (id) => `/api/admin/vesselgroups/${id}`,
};

export const ADMIN_HEALTH_MANAGEMENT_EVENT = {
  get: (approvalStatus) =>
    createUrl("/api/admin/healthmanagementevent", { approvalStatus }),
  getEvent: (eventId) => `/api/admin/healthmanagementevent/${eventId}`,
  getEventDetails: (eventId) =>
    `/api/admin/healthmanagementevent/${eventId}/details`,
  getStandardTexts: (eventId) =>
    `/api/admin/healthmanagementevent/${eventId}/standardTexts`,
  approveEvent: (eventId) =>
    `/api/admin/healthmanagementevent/${eventId}/approve`,
};

export const HEALTH_EVENTS_RESOLUTION = {
  acknowledgeEvent: (eventId) => `/api/v2/hm/resolution/${eventId}/acknowledge`,
  resolveEvent: (eventId) => `/api/v2/hm/resolution/${eventId}/resolve`,
  revertEvent: (eventId) => `/api/v2/hm/resolution/${eventId}/revertResolution`,
};

export const ADMIN_TELEMETRY = {
  get: ({ from, to }) =>
    createUrl(`/api/admin/telemetry/aggregated`, { from, to }),
};

export const OPERATIONAL_PROFILE_PLAN_DATA = {
  getVessel: (vesselId, itemTypeMetricId, { from, to }) =>
    createUrl(
      `/api/data/donut/vessel/${vesselId}/itemtypemetric/${itemTypeMetricId}`,
      { from, to }
    ),
  getPlannedVessel: (vesselId, itemTypeMetricId, { from, to }) =>
    createUrl(
      `/api/data/donut/planned/vessel/${vesselId}/itemtypemetric/${itemTypeMetricId}`,
      { from, to }
    ),
  getGroup: (groupId, itemTypeMetricId, { from, to }) =>
    createUrl(
      `/api/data/donut/vesselgroup/${groupId}/itemtypemetric/${itemTypeMetricId}/`,
      { from, to }
    ),
  getPlannedGroup: (groupId, itemTypeMetricId, { from, to }) =>
    createUrl(
      `/api/data/donut/planned/vesselgroup/${groupId}/itemtypemetric/${itemTypeMetricId}/`,
      { from, to }
    ),
};

export const ANALYSIS = {
  fetchOperations: (vesselId, { from, to }, timeOffset) =>
    createUrl(`/api/v2/analysis/operations/${vesselId}`, {
      from,
      to,
      timeOffset,
    }),
  fetchEngines: (vesselId, { from, to }, timeOffset) =>
    createUrl(`/api/v2/analysis/engines/${vesselId}`, { from, to, timeOffset }),
  fetchPropulsors: (vesselId, { from, to }, timeOffset) =>
    createUrl(`/api/v2/analysis/propulsors/${vesselId}`, {
      from,
      to,
      timeOffset,
    }),
  fetchAxisTypes: (vesselId, { from, to }, timeOffset) =>
    createUrl(`/api/v2/analysis/axisTypes/${vesselId}`, {
      from,
      to,
      timeOffset,
    }),
  fetchFilterValues: () => `/api/v2/analysis/filterValues`,
  fetchAnalysisPlot: () => "/api/v2/analysis/plotData",
};

export const DOCUMENTS = {
  fetchFileTypes: (companyId) =>
    createUrl("/api/documents/fileTypes", { company: companyId }),
  fetchDateRange: (companyId) =>
    createUrl("/api/documents/dateRange", { company: companyId }),
  fetchCalendarDocuments: (vesselId, { from, to }, timeOffset) =>
    createUrl(`/api/documents/calendar/vessel/${vesselId}`, {
      from,
      to,
      timeOffset,
    }),
  getFolders: (companyId) =>
    createUrl("/api/documents/folders", { company: companyId }),
  addFile: () => `/api/documents/file`,
  updateFile: (id) => `/api/documents/file/${id}`,
  deleteFile: (id) => `/api/documents/file/${id}/delete`,
  getDownloadUrl: (id) => `/api/documents/file/${id}/download`,
  addFolder: () => `/api/documents/folder`,
  updateFolder: (id) => `/api/documents/folder/${id}`,
  getFiles: ({
    search = "",
    fileTypes = "",
    companyId = "",
    from = "",
    to = "",
    vesselId = "",
    vesselGroupId = "",
    timeOffset = 0,
  } = {}) =>
    createUrl("/api/documents/files", {
      search,
      fileTypes,
      company: companyId,
      from,
      to,
      vesselId,
      vesselGroup: vesselGroupId,
      timeOffset,
    }),
};

export const EQUINOR = {
  fetchVesselReport: (vesselId, { from, to }) =>
    createUrl(`/api/v2/equinor/vessel/${vesselId}`, { from, to }),
};

export const LOCATIONS = {
  getLocationsInArea: (nw, se) =>
    createUrl("/api/v2/locations/in-area", {
      nwLat: nw.lat,
      nwLng: nw.lng,
      seLat: se.lat,
      seLng: se.lng,
    }),
};

export const api = {
  me: {
    onPageLoad: () => "/api/me/onPageLoad",
  },
  vessel: {
    getDataQuality: (vesselId, { from, to }, timeOffset, filters) =>
      createUrl(`/api/v2/vessels/${vesselId}/dataQuality`, {
        from,
        to,
        timeOffset,
        filters,
      }),
  },
};

export const vesselConfigApi = {
  get: (vesselId) => `/api/admin/vessels/${vesselId}`,
  vesselDetails: {
    get: (vesselId) => `/api/admin/vessels/${vesselId}`,
    put: (vesselId) => `/api/admin/vessels/${vesselId}`,
    post: () => "/api/admin/vessels",
    windConfig: (vesselId) => `/api/admin/vessels/${vesselId}/windcoefficients`,
  },
  vesselOperations: {
    getForVessel: (vesselId) => `/api/admin/vessels/${vesselId}/operations`,
    post: (vesselId) => `/api/admin/vessels/${vesselId}/operations`,
    put: (vesselId, vesselOperationId) =>
      `/api/admin/vessels/${vesselId}/operations/${vesselOperationId}`,
    delete: (vesselId, vesselOperationId) =>
      `/api/admin/vessels/${vesselId}/operations/${vesselOperationId}`,
  },
  vesselPerformanceIndicators: {
    get: (vesselId) => `/api/admin/vessels/${vesselId}/performanceindicators`,
    post: (vesselId) => `/api/admin/vessels/${vesselId}/performanceindicators`,
    put: (vesselId, id) =>
      `/api/admin/vessels/${vesselId}/performanceindicators/${id}`,
    delete: (vesselId, id) =>
      `/api/admin/vessels/${vesselId}/performanceindicators/${id}`,
  },
  onboardFrontendConfig: {
    get: (vesselId) => `/api/admin/onboardFrontendConfig/${vesselId}`,
    post: (vesselId) => `/api/admin/onboardFrontendConfig/${vesselId}`,
    download: (vesselId) =>
      `/api/admin/onboardFrontendConfig/${vesselId}/download`,
    deploy: (vesselId) => `/api/admin/onboardFrontendConfig/${vesselId}/deploy`,
  },
  onboardProcessorConfig: {
    getConfigs: (vesselId) => `/api/admin/onboardProcessorConfig/${vesselId}`,
    deploy: (vesselId, configId) =>
      `/api/admin/onboardProcessorConfig/${vesselId}/${configId}/deploy`,
    download: (vesselId, configId) =>
      `/api/admin/onboardProcessorConfig/${vesselId}/${configId}/download`,
    generate: (vesselId) =>
      `/api/admin/onboardProcessorConfig/${vesselId}/generate`,
    getMinimumProcessorBuild: () =>
      `/api/admin/onboardProcessorConfig/minimumprocessorbuild`,
    getProcessorBuild: (vesselId) =>
      `/api/admin/onboardProcessorConfig/${vesselId}/processorbuild`,
  },
  onshoreFrontendConfig: {
    get: (vesselId) => `/api/admin/onshoreFrontendConfig/${vesselId}`,
    post: (vesselId) => `/api/admin/onshoreFrontendConfig/${vesselId}`,
  },
  vesselItems: {
    getById: (id) => `/api/admin/vesselitems/${id}`,
    get: (vesselId) => createUrl("/api/admin/vesselitems", { vesselId }),
    post: () => "/api/admin/vesselitems",
    put: (id) => `/api/admin/vesselitems/${id}`,
    getMetrics: (id) => `/api/admin/vesselitems/metrics/${id}`,
    putMetrics: (id) => `/api/admin/vesselitems/metrics/${id}`,
    delete: (id) => `/api/admin/vesselitems/${id}`,
  },
  baseline: {
    getBaselines: (vesselId) => `/api/admin/vessels/${vesselId}/baselines`,
    postBaseline: (vesselId) => `/api/admin/vessels/${vesselId}/baselines`,
  },
  hullPerformanceConfig: {
    get: (vesselId) => `/api/admin/vessels/${vesselId}/hullperformanceconfig`,
    save: (vesselId) => `/api/admin/vessels/${vesselId}/hullperformanceconfig`,
    generate: (vesselId, from, to) =>
      `/api/admin/vessels/${vesselId}/hullperformanceconfig/generate?from=${from}&to=${to}`,
    validate: (vesselId) =>
      `/api/admin/vessels/${vesselId}/hullperformanceconfig/validate`,
    requestCalculation: (vesselId) =>
      `/api/admin/vessels/${vesselId}/hullperformanceconfig/requestcalculation`,
    calculationResult: (vesselId) =>
      `/api/admin/vessels/${vesselId}/hullperformanceconfig/calculationresult`,
    recalculate: (vesselId) =>
      `/api/admin/vessels/${vesselId}/hullperformanceconfig/recalculate`,
  },
  energyFlow: {
    getLayout: (vesselId) => `/api/admin/vessels/${vesselId}/energyflow/layout`,
    getRelations: (vesselId) =>
      `/api/admin/vessels/${vesselId}/energyflow/relations`,
    postLayout: (vesselId) =>
      `/api/admin/vessels/${vesselId}/energyflow/layout`,
    postRelations: (vesselId) =>
      `/api/admin/vessels/${vesselId}/energyflow/relations`,
  },
};

export const hullPerformanceApi = {
  getPerformanceValues: (vesselId, from, to) =>
    createUrl(`/api/v2/hullPerformance/${vesselId}`, { from, to }),
};

export const cargoConfigApi = {
  getEEOI: (vesselId, from, to) => {
    return createUrl(`/api/admin/cargoconfig/${vesselId}/eeoi`, { from, to });
  },
  saveCargo: (vesselId) => `/api/admin/cargoconfig/${vesselId}/cargo`,
};

export const dataMaintenanceApi = {
  getDataCount: (vesselId, from, to) =>
    createUrl(`/api/admin/datamaintenance/${vesselId}/count`, { from, to }),
  deleteData: (vesselId, from, to) =>
    createUrl(`/api/admin/datamaintenance/${vesselId}/delete`, { from, to }),
};

export const adminApi = {
  devOps: {
    get: (companyId) =>
      "/api/v2/admin/devOps" +
      (companyId !== null ? `?companyId=${companyId}` : ""),
    application: {
      version: {
        get: () => "/api/v2/admin/devOps/application/version",
      },
    },
  },
  dataStatus: {
    get: (allVessels, dataPage) =>
      `/api/v2/admin/service/dataStatus?includeNotConnectedVessels=${allVessels}&dataPage=${dataPage}`,
  },
  vessels: {
    remoteControl: {
      acknowledgeActionFailure: (vesselId, actionId) =>
        `/api/admin/vessels/remote/acknowledgeFailure/${vesselId}/${actionId}`,
      updateSystem: (vesselId) =>
        `/api/admin/vessels/remote/updatesystem/${vesselId}`,
      updateTargets: () => "/api/admin/vessels/remote/updateTargets",
    },
  },
  modules: {
    get: () => "/api/admin/modules",
  },
};

export const FUEL_EMISSIONS = {
  getFuelConsumption: (vesselId, { from, to }, timeOffset) =>
    createUrl(`/api/v2/data/emissions/fuel-consumptions/${vesselId}`, {
      from,
      to,
      timeOffset,
    }),
  getEmissionsTrend: (vesselId) =>
    createUrl(`/api/v2/data/emissions/emissions-trend/${vesselId}`),
  getDonutData: (vesselId, { from, to }, timeOffset) =>
    createUrl(
      `/api/v2/data/emissions/run-and-emissions-donut-data/${vesselId}`,
      {
        from,
        to,
        timeOffset,
      }
    ),
  getEmissionsByOperationModes: (vesselId, { from, to }, timeOffset) =>
    createUrl(
      `/api/v2/data/emissions/emissions-by-operation-modes/${vesselId}`,
      {
        from,
        to,
        timeOffset,
      }
    ),
  getFuelAndTarget: (vesselId, { from, to }, timeOffset) =>
    createUrl(`/api/v2/data/emissions/fuel-and-target/${vesselId}`, {
      from,
      to,
      timeOffset,
    }),
  getFuelTargets: (vesselId) =>
    createUrl(`/api/v2/data/emissions/fuel-targets/${vesselId}`),

  saveFuelTargets: (vesselId) =>
    createUrl(`/api/v2/data/emissions/fuel-targets/${vesselId}`),
};
