import React from "react";
import { connect } from "react-redux";
import { get, matchesProperty } from "lodash";
import {
  fetchUser,
  fetchCompanies,
  closeUserPopup,
  saveUser,
  setUserValue,
} from "../../../actions/admin";
import {
  deactivateUser,
  reactivateUser,
  deletePopupOpen,
  generateApiSecret,
  requestRegenerateApiSecret,
  requestClearApiSecret,
} from "../../../actions/admin/action.users";
import {
  FormTextBox,
  FormSelectBox,
  FormPopup,
  FormCheckBox,
} from "../../../components/Form";
import {
  getRoleOptions,
  healthManagementEventTypeOptions,
} from "../../../reducers/admin/reducer.users";
import { isSystemAdmin } from "../../../reducers/reducer.user";
import { getDifferenceInMinutes, now } from "../../../common/dates";
import RadioButton from "../../../../common/components/RadioButton";
import { isEmail, isGuid, isRequired } from "../../../common/forms.validators";

class UserPopup extends React.PureComponent {
  constructor(props) {
    super(props);
    this.onPopupFormHide = this.onPopupFormHide.bind(this);
    this.onPopupFormSave = this.onPopupFormSave.bind(this);
    this.onPopupFormCancel = this.onPopupFormCancel.bind(this);
    this.onPopupFormInputChange = this.onPopupFormInputChange.bind(this);
    this.onPopupFormSelectChange = this.onPopupFormSelectChange.bind(this);
  }

  onPopupFormHide() {
    this.props.closeUserPopup();
  }

  onPopupFormSave() {
    this.props.saveUser();
  }

  onPopupFormCancel() {
    this.props.closeUserPopup();
  }

  onPopupFormInputChange(name, value, valid, message) {
    this.props.setUserValue(name, value, valid, message);
  }

  onPopupFormSelectChange(name, selected, valid, message) {
    this.props.setUserValue(name, selected.id, valid, message);
  }

  componentDidMount() {
    const { companiesFetchedAt } = this.props.editor.form;

    if (
      !companiesFetchedAt ||
      getDifferenceInMinutes(companiesFetchedAt, now()) > 5
    ) {
      this.props.fetchCompanies();
    }
  }

  userType = "I";

  clientId(form) {
    return get(form, "fields.clientId.value");
  }

  isUser(form) {
    const clientId = this.clientId(form);
    return (
      (form.isUpdate && !clientId) || (!form.isUpdate && this.userType === "I")
    );
  }

  isClient(form) {
    const clientId = this.clientId(form);
    return (
      (form.isUpdate && clientId) || (!form.isUpdate && this.userType === "NI")
    );
  }

  renderForm() {
    const { companies, editor, user } = this.props;
    const { form, isVisible } = editor;
    if (!isVisible) {
      return <div />;
    }

    const selectedCompany = companies.find(
      matchesProperty("id", get(form, "fields.companyId.value"))
    );

    const userTypeOptions = {
      options: [
        {
          id: "I",
          name: "User",
          selected: this.userType === "I",
        },
        {
          id: "NI",
          name: "Client",
          selected: this.userType === "NI",
        },
      ],
      onChange: (o) => {
        this.userType = o.id;
        this.onPopupFormInputChange();
      },
    };

    return (
      <div>
        {!form.isUpdate && (
          <div style={{ paddingBottom: "0.75rem", display: "flex" }}>
            <div style={{ flex: "1" }} />
            <RadioButton
              options={userTypeOptions.options}
              onChange={userTypeOptions.onChange}
            />
          </div>
        )}

        {this.isUser(form) && (
          <div>
            <FormTextBox
              name="firstName"
              label="First name"
              value={get(form, "fields.firstName.value")}
              onChange={this.onPopupFormInputChange}
              required
            />
            <FormTextBox
              name="lastName"
              label="Last name"
              value={get(form, "fields.lastName.value")}
              onChange={this.onPopupFormInputChange}
              required
            />
            <FormTextBox
              type="email"
              name="email"
              label="Email"
              value={get(form, "fields.email.value")}
              onChange={this.onPopupFormInputChange}
              readOnly={form.isUpdate}
              required
            />
          </div>
        )}
        {this.isClient(form) && (
          <div>
            <FormTextBox
              name="firstName"
              label="Name"
              value={get(form, "fields.firstName.value")}
              onChange={this.onPopupFormInputChange}
              required
            />
            <FormTextBox
              name="clientId"
              label="Client Id"
              value={get(form, "fields.clientId.value")}
              onChange={this.onPopupFormInputChange}
              readOnly={form.isUpdate}
              required
            />
          </div>
        )}
        {isSystemAdmin(user) && (
          <>
            <FormSelectBox
              name="companyId"
              label="Company"
              selected={selectedCompany}
              options={companies}
              optionValKey="id"
              optionLabelKey="name"
              onChange={this.onPopupFormSelectChange}
              required
            />
            <FormSelectBox
              name="roles"
              label="Roles"
              multi
              selected={get(form, ["fields", "roles", "value"])}
              options={getRoleOptions(form)}
              onChange={this.props.setUserValue}
            />
            <FormSelectBox
              clearable
              name="healthManagementAccessEventType"
              label="Health management event access"
              multi
              selected={get(form, [
                "fields",
                "healthManagementAccessEventType",
                "value",
              ])}
              options={healthManagementEventTypeOptions}
              onChange={this.props.setUserValue}
            />
            <FormCheckBox
              label={"API Client User only"}
              name={"isApiClientUserOnly"}
              value={get(form, "fields.isApiClientUserOnly.value")}
              onChange={this.props.setUserValue}
            />
          </>
        )}
      </div>
    );
  }

  getAdditionalButtons(user) {
    if (!user) {
      return [];
    }
    return [
      {
        label: "Delete",
        visible: true,
        action: () => this.props.deletePopupOpen(user),
      },
      user.deactivated
        ? {
            label: "Re-activate",
            visible: true,
            action: () => this.props.reactivateUser(user),
          }
        : {
            label: "Deactivate",
            visible: true,
            action: () => this.props.deactivateUser(user),
          },
    ];
  }

  isValid = (form) => {
    let isValid = true;
    if (this.isUser(form)) {
      const firstName = get(form, "fields.firstName.value");
      const lastName = get(form, "fields.lastName.value");
      const email = get(form, "fields.email.value");

      const firstNameIsValid = isRequired(firstName).isValid;
      const lastNameIsValid = isRequired(lastName).isValid;
      const emailIsValid =
        form.isUpdate || (isRequired(email).isValid && isEmail(email).isValid);

      isValid = firstNameIsValid && lastNameIsValid && emailIsValid;
    } else if (this.isClient(form)) {
      const clientId = get(form, "fields.clientId.value");
      const name = get(form, "fields.firstName.value");

      const clientIdIsValid =
        form.isUpdate ||
        (isRequired(clientId).isValid && isGuid(clientId).isValid);
      const nameIsValid = isRequired(name).isValid;

      isValid = clientIdIsValid && nameIsValid;
    }
    const companyId = get(form, "fields.companyId.value");
    isValid = isValid && isRequired(companyId).isValid && isGuid(companyId);
    return isValid;
  };

  render() {
    const { form, isVisible, user } = this.props.editor;
    const { isSaving } = form;
    const enableSave =
      (!form.isUpdate || form.hasChanges) && this.isValid(form) && !isSaving;
    return (
      <FormPopup
        title={form.isUpdate ? "Edit User" : "Create new User"}
        visible={isVisible}
        isSaving={isSaving}
        enableSave={enableSave}
        onHide={this.onPopupFormHide}
        onSave={this.onPopupFormSave}
        onCancel={this.onPopupFormCancel}
        additionalButtons={this.getAdditionalButtons(user)}
      >
        {this.renderForm()}
      </FormPopup>
    );
  }
}

const mapStateToProps = (state) => ({
  companies: state.admin.companies.data || [],
  editor: state.admin.users.editor,
  user: state.user,
});

const mapDispatchToProps = {
  fetchUser,
  fetchCompanies,
  closeUserPopup,
  saveUser,
  setUserValue,
  deactivateUser,
  reactivateUser,
  deletePopupOpen,
  generateApiSecret,
  requestRegenerateApiSecret,
  requestClearApiSecret,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserPopup);
