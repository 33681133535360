import React from "react";
import styles from "./FuelTarget.css";
import fuelEmissionsStyles from "../FuelEmissions.css";
import DataGridView from "../DataGridView/DataGridView";
import { IFuelAndTargetItem, IFuelAndTargetState } from "../commonTypes";
import Loader from "../../../../common/components/Loader";
import { sortByOperationLegend } from "../sortByOperationLegend";

const colors = {
  red: "#fc406a",
  green: "#39bf65",
};

interface IProps {
  data: IFuelAndTargetState;
}

function FuelTarget({ data }: IProps): JSX.Element {
  const sortedData = data.filteredData
    ? sortByOperationLegend(data.filteredData, (item) => item.operationMode)
    : [];

  return data.isLoading ? (
    <div className={fuelEmissionsStyles.girdItemLoader}>
      <Loader text="Loading Fuel & Target" />
    </div>
  ) : (
    <div className={styles.fuelTargetContainer}>
      <DataGridView
        data={sortedData}
        columns={columns}
        idSelector={(row) => row.operationMode}
      />
    </div>
  );
}

const columns = [
  {
    field: "operationMode",
    unit: "",
  },
  {
    name: "Run time",
    field: "runTime",
    unit: "h",
  },
  {
    name: "Actual Fuel\nUse",
    field: "actualFuelUse",
    unit: "t/h",
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    customStyleFn: (item: IFuelAndTargetItem) =>
      getColor(item.actualFuelUse, item.targetFuelUse),
  },
  {
    name: "Target Fuel\nUse",
    field: "targetFuelUse",
    unit: "t/h",
  },
];

const getColor = (
  actual: number | string,
  target: number | string
): React.CSSProperties => {
  if (!+target) {
    return {};
  }

  const diff = +actual - +target;
  if (diff > 0) {
    return { color: colors.red };
  } else if (diff < 0) {
    return { color: colors.green };
  } else {
    return {};
  }
};

export default FuelTarget;
