import React from "react";
import styles from "./FleetPerformanceTable.css";
import { VesselColumn } from "./Columns/Vessel/VesselColumn";
import { VesselCheckbox } from "./Columns/Checkbox/VesselCheckbox";
import { Consumption } from "./Columns/Consumption/Consumption";
import { Trend } from "./Columns/Trend/Trend";
import { OperationHeader } from "./Columns/Operations/OperationsHeader";
import { OperationsColumn } from "./Columns/Operations/OperationsColumn";
import { Vessel } from "../types";
import { useOperations } from "./useOperations";
import { HeaderCell } from "./HeaderCell";
import ComponentInfo from "../../../../common/components/ComponentInfo/ComponentInfo";

interface Props {
  vessels: Vessel[];
}

export const FleetPerformanceTable = ({ vessels }: Props) => {
  const [
    visibleOperationIds,
    getVisibleOperationConsumption,
    operationScrolling,
  ] = useOperations(vessels);

  return (
    <table
      className={styles.table}
      // maxHeight overrides height, so by setting height to 100% the table's height is responsive,
      // but we use maxHeight to set the max row height which is not possible directly on <tr> elements
      style={{ maxHeight: `${vessels.length * 7}rem`, height: "100%" }}
    >
      <thead>
        <tr>
          <th className={styles.headerCell}></th>
          <th className={styles.headerCell}>
            <HeaderCell headerText="Vessel" />
          </th>
          <th className={styles.headerCell}>
            <ComponentInfo
              modalTargetId={"popupContainer"}
              buttonStyle={{
                top: "0rem",
                right: "1rem",
                position: "relative",
                flex: "none",
              }}
              title="Consumption"
              text="Total sum of fuel consumption within the chosen time interval."
            >
              <HeaderCell headerText="Consumption" />
            </ComponentInfo>
          </th>
          <th className={styles.headerCell}>
            <ComponentInfo
              modalTargetId={"popupContainer"}
              buttonStyle={{
                top: "0rem",
                right: "1rem",
                position: "relative",
                flex: "none",
              }}
              title="Trend"
              text={
                "The average consumption in the selected time interval compared to the previous interval of the same length. Eg. if you select a month, the number is the average consumption in the the selected month compared to the average consumption in the previous month.\n The graph shows the average consumption in the previous and selected time interval."
              }
            >
              <HeaderCell headerText="Trend" />
            </ComponentInfo>
          </th>
          <th className={styles.headerCell}>
            <ComponentInfo
              modalTargetId={"popupContainer"}
              buttonStyle={{
                right: "-1rem",
                top: "1.2rem",
                position: "relative",
                flex: "none",
              }}
              title="Operations"
              text={
                "The average fuel consumption per hour in the given operation."
              }
            >
              <OperationHeader
                operations={visibleOperationIds}
                operationScrolling={operationScrolling}
              />
            </ComponentInfo>
          </th>
        </tr>
      </thead>
      <tbody>
        {vessels.map((v) => (
          <tr className={styles.row} key={v.vessel.imo}>
            <td className={styles.cell} style={{ textAlign: "center" }}>
              <VesselCheckbox id={v.vessel.id} name={v.vessel.name} />
            </td>
            <td className={styles.cell}>
              <VesselColumn name={v.vessel.name} imo={v.vessel.imo} />
            </td>
            <td className={styles.cell}>
              <Consumption consumption={v.consumption} />
            </td>
            <td className={styles.cell}>
              <Trend
                graph={v.trend.graph}
                percentageValue={v.trend.percentageValue}
              />
            </td>
            <td className={styles.cell}>
              <OperationsColumn
                consumptionPerOperation={getVisibleOperationConsumption(
                  v.vessel.imo
                )}
                canGoLeftOrRight={operationScrolling.canGoLeftOrRight}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
