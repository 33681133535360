/*
 * Action types
 */

export const FETCH = "FETCH";

export const TOGGLE_NAV = "TOGGLE_NAV";
export const FETCH_VESSEL = "FETCH_VESSEL";
export const FETCH_FLEET_OVERVIEW = "FETCH_FLEET_OVERVIEW";
export const FETCH_FLEET_ACTIVITY = "FETCH_FLEET_ACTIVITY";
export const FETCH_FLEET_STATUS = "FETCH_FLEET_STATUS";
export const FETCH_SINGLE_VESSEL_STATUS = "FETCH_SINGLE_VESSEL_STATUS";
export const FETCH_CII_SCORES = "FETCH_CII_SCORES";
export const FETCH_EMISSIONS_BY_OPERATION_MODES =
  "FETCH_EMISSIONS_BY_OPERATION_MODES";
export const FETCH_EMISSIONS_BY_OPERATION_MODES_SUCCESS =
  "FETCH_EMISSIONS_BY_OPERATION_MODES_SUCCESS";
export const FETCH_EMISSIONS_BY_OPERATION_MODES_ERROR =
  "FETCH_EMISSIONS_BY_OPERATION_MODES_ERROR";
export const FETCH_EMISSIONS_LIST = "FETCH_EMISSIONS_LIST";
export const FETCH_EMISSIONS_LIST_SUCCESS = "FETCH_EMISSIONS_LIST_SUCCESS";
export const FETCH_EMISSIONS_LIST_ERROR = "FETCH_EMISSIONS_LIST_ERROR";
export const FETCH_CONNECTION_STATUS = "FETCH_CONNECTION_STATUS";
export const FETCH_HEALTH_EVENTS = "FETCH_HEALTH_EVENTS";
export const FETCH_GAUGE = "FETCH_GAUGE";
export const FETCH_FUEL_EFFICIENCY_TODAY = "FETCH_FUEL_EFFICIENCY_TODAY";
export const FETCH_FUEL_EFFICIENCY_YESTERDAY =
  "FETCH_FUEL_EFFICIENCY_YESTERDAY";
export const FETCH_MONTHLY_FUEL_CONSUMPTION = "FETCH_MONTHLY_FUEL_CONSUMPTION";
export const FETCH_MONTHLY_FUEL_CONSUMPTION_SUCCESS =
  "FETCH_MONTHLY_FUEL_CONSUMPTION_SUCCESS";
export const FETCH_MONTHLY_FUEL_CONSUMPTION_ERROR =
  "FETCH_MONTHLY_FUEL_CONSUMPTION_ERROR";
export const FETCH_VESSEL_FUEL_CONSUMPTION = "FETCH_VESSEL_FUEL_CONSUMPTION";
export const FETCH_VESSEL_FUEL_CONSUMPTION_SUCCESS =
  "FETCH_VESSEL_FUEL_CONSUMPTION_SUCCESS";
export const FETCH_VESSEL_FUEL_CONSUMPTION_ERROR =
  "FETCH_VESSEL_FUEL_CONSUMPTION_ERROR";

export const FETCH_ADMIN_USER = "FETCH_ADMIN_USER";
export const FETCH_ADMIN_USER_SUCCESS = "FETCH_ADMIN_USER_SUCCESS";
export const FETCH_ADMIN_USER_ERROR = "FETCH_ADMIN_USER_ERROR";
export const FETCH_ADMIN_USERS = "FETCH_ADMIN_USERS";
export const FETCH_ADMIN_USERS_SUCCESS = "FETCH_ADMIN_USERS_SUCCESS";
export const FETCH_ADMIN_USERS_ERROR = "FETCH_ADMIN_USERS_ERROR";
export const SAVE_ADMIN_USER = "SAVE_ADMIN_USER";
export const SAVE_ADMIN_USER_SUCCESS = "SAVE_ADMIN_USER_SUCCESS";
export const SAVE_ADMIN_USER_ERROR = "SAVE_ADMIN_USER_ERROR";
export const CREATE_ADMIN_USER = "CREATE_ADMIN_USER";
export const CLOSE_ADMIN_USER_POPUP = "CLOSE_ADMIN_USER_POPUP";
export const SET_ADMIN_USER_VALUE = "SET_ADMIN_USER_VALUE";
export const EDIT_USER_ACCESS = "EDIT_USER_ACCESS";
export const CLOSE_EDIT_USER_ACCESS_POPUP = "CLOSE_EDIT_USER_ACCESS_POPUP";
export const FETCH_USER_GROUPS = "FETCH_USER_GROUPS";
export const FETCH_USER_GROUPS_SUCCESS = "FETCH_USER_GROUPS_SUCCESS";
export const SAVE_USER_GROUPS_IN_COMPANY = "SAVE_USER_GROUPS_IN_COMPANY";
export const SAVE_USER_GROUPS_IN_COMPANY_SUCCESS =
  "SAVE_USER_GROUPS_IN_COMPANY_SUCCESS";
export const SAVE_USER_GROUPS_IN_COMPANY_ERROR =
  "SAVE_USER_GROUPS_IN_COMPANY_ERROR";
export const SET_USER_ACCESS_SELECTED_COMPANY =
  "SET_USER_ACCESS_SELECTED_COMPANY";
export const SET_USER_ACCESS_SELECTED_GROUPS =
  "SET_USER_ACCESS_SELECTED_GROUPS";
export const REQUEST_USER_PASSWORD_RESET = "REQUEST_USER_PASSWORD_RESET";
export const CONFIRM_USER_PASSWORD_RESET = "CONFIRM_USER_PASSWORD_RESET";
export const CANCEL_USER_PASSWORD_RESET = "CANCEL_USER_PASSWORD_RESET";
export const SEND_USER_PASSWORD_RESET = "SEND_USER_PASSWORD_RESET";
export const SEND_USER_PASSWORD_RESET_SUCCESS =
  "SEND_USER_PASSWORD_RESET_SUCCESS";
export const SEND_USER_PASSWORD_RESET_ERROR = "SEND_USER_PASSWORD_RESET_ERROR";
export const SORT_ADMIN_USERS = "SORT_ADMIN_USERS";

export const FETCH_ADMIN_COMPANY = "FETCH_ADMIN_COMPANY";
export const FETCH_ADMIN_COMPANY_SUCCESS = "FETCH_ADMIN_COMPANY_SUCCESS";
export const FETCH_ADMIN_COMPANY_ERROR = "FETCH_ADMIN_COMPANY_ERROR";
export const FETCH_ADMIN_COMPANIES = "FETCH_ADMIN_COMPANIES";
export const FETCH_ADMIN_COMPANIES_SUCCESS = "FETCH_ADMIN_COMPANIES_SUCCESS";
export const FETCH_ADMIN_COMPANIES_ERROR = "FETCH_ADMIN_COMPANIES_ERROR";
export const SAVE_ADMIN_COMPANY = "SAVE_ADMIN_COMPANY";
export const SAVE_ADMIN_COMPANY_SUCCESS = "SAVE_ADMIN_COMPANY_SUCCESS";
export const SAVE_ADMIN_COMPANY_ERROR = "SAVE_ADMIN_COMPANY_ERROR";
export const CREATE_ADMIN_COMPANY = "CREATE_ADMIN_COMPANY";
export const CLOSE_ADMIN_COMPANY_POPUP = "CLOSE_ADMIN_COMPANY_POPUP";
export const SET_ADMIN_COMPANY_VALUE = "SET_ADMIN_COMPANY_VALUE";

export const FETCH_ADMIN_PAGE_CONFIG = "FETCH_ADMIN_PAGE_CONFIG";
export const FETCH_ADMIN_PAGE_CONFIG_SUCCESS =
  "FETCH_ADMIN_PAGE_CONFIG_SUCCESS";
export const FETCH_ADMIN_PAGE_CONFIG_ERROR = "FETCH_ADMIN_PAGE_CONFIG_ERROR";
export const FETCH_ADMIN_PAGE_CONFIGS = "FETCH_ADMIN_PAGE_CONFIGS";
export const FETCH_ADMIN_PAGE_CONFIGS_SUCCESS =
  "FETCH_ADMIN_PAGE_CONFIGS_SUCCESS";
export const FETCH_ADMIN_PAGE_CONFIGS_ERROR = "FETCH_ADMIN_PAGE_CONFIGS_ERROR";
export const SAVE_ADMIN_PAGE_CONFIG = "SAVE_ADMIN_PAGE_CONFIG";
export const SAVE_ADMIN_PAGE_CONFIG_SUCCESS = "SAVE_ADMIN_PAGE_CONFIG_SUCCESS";
export const SAVE_ADMIN_PAGE_CONFIG_ERROR = "SAVE_ADMIN_PAGE_CONFIG_ERROR";
export const CREATE_ADMIN_PAGE_CONFIG = "CREATE_ADMIN_PAGE_CONFIG";
export const CLOSE_ADMIN_PAGE_CONFIG_POPUP = "CLOSE_ADMIN_PAGE_CONFIG_POPUP";
export const SET_ADMIN_PAGE_CONFIG_VALUE = "SET_ADMIN_PAGE_CONFIG_VALUE";
export const OPEN_ADMIN_PAGE_CONFIG_DELETE_POPUP =
  "OPEN_ADMIN_PAGE_CONFIG_DELETE_POPUP";
export const CLOSE_ADMIN_PAGE_CONFIG_DELETE_POPUP =
  "CLOSE_ADMIN_PAGE_CONFIG_DELETE_POPUP";
export const DELETE_ADMIN_PAGE_CONFIG = "DELETE_ADMIN_PAGE_CONFIG";
export const DELETE_ADMIN_PAGE_CONFIG_ERROR = "DELETE_ADMIN_PAGE_CONFIG_ERROR";
export const DELETE_ADMIN_PAGE_CONFIG_SUCCESS =
  "DELETE_ADMIN_PAGE_CONFIG_SUCCESS";
export const SET_ADMIN_PAGE_JSON_CONFIG = "SET_ADMIN_PAGE_JSON_CONFIG";
export const CLEAR_ADMIN_PAGE_JSON_CONFIG = "CLEAR_ADMIN_PAGE_JSON_CONFIG";
export const CLEAR_ADMIN_PAGE_CONFIG_ROWS = "CLEAR_ADMIN_PAGE_CONFIG_ROWS";
export const CLEAR_ADMIN_PAGE_CONFIG_VESSEL_ID =
  "CLEAR_ADMIN_PAGE_CONFIG_VESSEL_ID";
export const CLEAR_ADMIN_PAGE_CONFIG_VESSEL_GROUP_ID =
  "CLEAR_ADMIN_PAGE_CONFIG_VESSEL_GROUP_ID";

export const FETCH_ADMIN_VESSELS = "FETCH_ADMIN_VESSELS";
export const FETCH_ADMIN_VESSELS_SUCCESS = "FETCH_ADMIN_VESSELS_SUCCESS";
export const FETCH_ADMIN_VESSELS_ERROR = "FETCH_ADMIN_VESSELS_ERROR";
export const ADMIN_VESSEL_REMOTECONTROL_CONFIGURE_CLOSE =
  "ADMIN_VESSEL_REMOTECONTROL_CONFIGURE_CLOSE";
export const ADMIN_VESSEL_REMOTECONTROL_CONFIGURE =
  "ADMIN_VESSEL_REMOTECONTROL_CONFIGURE";

export const ADMIN_VESSEL_GROUP_INITIALIZED = "ADMIN_VESSEL_GROUP_INITIALIZED";
export const ADMIN_VESSEL_GROUP_DETACHED = "ADMIN_VESSEL_GROUP_DETACHED";
export const FETCH_ADMIN_VESSEL_GROUP = "FETCH_ADMIN_VESSEL_GROUP";
export const FETCH_ADMIN_VESSEL_GROUP_SUCCESS =
  "FETCH_ADMIN_VESSEL_GROUP_SUCCESS";
export const FETCH_ADMIN_VESSEL_GROUP_ERROR = "FETCH_ADMIN_VESSEL_GROUP_ERROR";
export const FETCH_ADMIN_VESSEL_GROUPS = "FETCH_ADMIN_VESSEL_GROUPS";
export const FETCH_ADMIN_VESSEL_GROUPS_SUCCESS =
  "FETCH_ADMIN_VESSEL_GROUPS_SUCCESS";
export const FETCH_ADMIN_VESSEL_GROUPS_ERROR =
  "FETCH_ADMIN_VESSEL_GROUPS_ERROR";
export const SAVE_ADMIN_VESSEL_GROUP = "SAVE_ADMIN_VESSEL_GROUP";
export const SAVE_ADMIN_VESSEL_GROUP_SUCCESS =
  "SAVE_ADMIN_VESSEL_GROUP_SUCCESS";
export const SAVE_ADMIN_VESSEL_GROUP_ERROR = "SAVE_ADMIN_VESSEL_GROUP_ERROR";
export const CREATE_ADMIN_VESSEL_GROUP = "CREATE_ADMIN_VESSEL_GROUP";
export const CLOSE_ADMIN_VESSEL_GROUP_POPUP = "CLOSE_ADMIN_VESSEL_GROUP_POPUP";
export const SET_ADMIN_VESSEL_GROUP_VALUE = "SET_ADMIN_VESSEL_GROUP_VALUE";
export const DELETE_ADMIN_VESSEL_GROUP_POPUP_OPEN =
  "DELETE_ADMIN_VESSEL_GROUP_POPUP_OPEN";
export const DELETE_ADMIN_VESSEL_GROUP_POPUP_CLOSE =
  "DELETE_ADMIN_VESSEL_GROUP_POPUP_CLOSE";
export const DELETE_ADMIN_VESSEL_GROUP = "DELETE_ADMIN_VESSEL_GROUP";
export const DELETE_ADMIN_VESSEL_GROUP_SUCCESS =
  "DELETE_ADMIN_VESSEL_GROUP_SUCCESS";
export const DELETE_ADMIN_VESSEL_GROUP_ERROR =
  "DELETE_ADMIN_VESSEL_GROUP_ERROR";

export const SET_SELECTED_COMPANY = "SET_SELECTED_COMPANY";

export const FETCH_VESSELS = "FETCH_VESSELS";
export const FETCH_VESSELS_SUCCESS = "FETCH_VESSELS_SUCCESS";
export const FETCH_VESSELS_ERROR = "FETCH_VESSELS_ERROR";
export const FETCH_ENGINE_LOAD = "FETCH_ENGINE_LOAD";
export const FETCH_ENGINE_LOAD_SUCCESS = "FETCH_ENGINE_LOAD_SUCCESS";
export const FETCH_ENGINE_LOAD_ERROR = "FETCH_ENGINE_LOAD_ERROR";
export const FETCH_MAP_DATA = "FETCH_MAP_DATA";
export const FETCH_ITEM_TYPE_DATA = "FETCH_ITEM_TYPE_DATA";
export const FETCH_VESSEL_ITEM_DETAILS = "FETCH_VESSEL_ITEM_DETAILS";
export const FETCH_VESSEL_ITEM_DETAILS_SUCCESS =
  "FETCH_VESSEL_ITEM_DETAILS_SUCCESS";
export const FETCH_VESSEL_ITEM_DETAILS_ERROR =
  "FETCH_VESSEL_ITEM_DETAILS_ERROR";
export const FETCH_HEALTH_MANAGEMENT_EVENT_DETAILS =
  "FETCH_HEALTH_MANAGEMENT_EVENT_DETAILS";
export const FETCH_HEALTH_MANAGEMENT_EVENT_DETAILS_SUCCESS =
  "FETCH_HEALTH_MANAGEMENT_EVENT_DETAILS_SUCCESS";
export const FETCH_DONUT = "FETCH_DONUT";
export const FETCH_POSITION_TRACK = "FETCH_POSITION_TRACK";
export const FETCH_POSITION_TRACK_SUCCESS = "FETCH_POSITION_TRACK_SUCCESS";
export const FETCH_POSITION_TRACK_ERROR = "FETCH_POSITION_TRACK_ERROR";
export const FETCH_POSITION_DETAILS = "FETCH_POSITION_DETAILS";
export const FETCH_POSITION_DETAILS_SUCCESS = "FETCH_POSITION_DETAILS_SUCCESS";
export const FETCH_POSITION_DETAILS_ERROR = "FETCH_POSITION_DETAILS_ERROR";
export const FETCH_LEGS = "FETCH_LEGS";
export const FETCH_VOYAGES = "FETCH_VOYAGES";
export const FETCH_FLEET_TABLE = "FETCH_FLEET_TABLE";
export const FETCH_FLEET_TABLE_SUCCESS = "FETCH_FLEET_TABLE_SUCCESS";
export const FETCH_FLEET_TABLE_ERROR = "FETCH_FLEET_TABLE_ERROR";
export const FETCH_BEST_PERFORMANCE = "FETCH_BEST_PERFORMANCE";
export const FETCH_BEST_PERFORMANCE_SUCCESS = "FETCH_BEST_PERFORMANCE_SUCCESS";
export const FETCH_BEST_PERFORMANCE_ERROR = "FETCH_BEST_PERFORMANCE_ERROR";
export const FETCH_COMPARE = "FETCH_COMPARE";
export const FETCH_COMPARE_SUCCESS = "FETCH_COMPARE_SUCCESS";
export const FETCH_COMPARE_ERROR = "FETCH_COMPARE_ERROR";
export const FETCH_GROUPS = "FETCH_GROUPS";
export const FETCH_GROUPS_ERROR = "FETCH_GROUPS_ERROR";
export const FETCH_GROUPS_SUCCESS = "FETCH_GROUPS_SUCCESS";
export const REMOVE_COMPARE_VESSEL = "REMOVE_COMPARE_VESSEL";
export const REMOVE_ALL_COMPARE_VESSEL = "REMOVE_ALL_COMPARE_VESSEL";
export const SET_MAP_VIEW_DATA = "SET_MAP_VIEW_DATA";
export const TOGGLE_MAP_POI = "TOGGLE_MAP_POI";
export const FIT_MAP_TO_VESSELS = "FIT_MAP_TO_VESSELS";
export const START_ZOOM = "START_ZOOM";
export const END_ZOOM = "END_ZOOM";

export const TOGGLE_VESSEL_ITEM_DETAILS = "TOGGLE_VESSEL_ITEM_DETAILS";
export const CLOSE_POSITION_DETAILS = "CLOSE_POSITION_DETAILS";
export const FETCH_USER_DATA_SUCCESS = "FETCH_USER_DATA_SUCCESS";
export const FETCH_USER_DATA_ERROR = "FETCH_USER_DATA_ERROR";
export const FETCH_TREND_FOR_VESSEL_ITEM = "FETCH_TREND_FOR_VESSEL_ITEM";
export const FETCH_TREND_FOR_VESSEL_ITEM_SUCCESS =
  "FETCH_TREND_FOR_VESSEL_ITEM_SUCCESS";
export const FETCH_TREND_FOR_PERFORMANCE_INDICATOR =
  "FETCH_TREND_FOR_PERFORMANCE_INDICATOR";
export const FETCH_TREND_FOR_PERFORMANCE_INDICATOR_SUCCESS =
  "FETCH_TREND_FOR_PERFORMANCE_INDICATOR_SUCCESS";
export const FETCH_TREND_FOR_PERFORMANCE_INDICATOR_ERROR =
  "FETCH_TREND_FOR_PERFORMANCE_INDICATOR_ERROR";
export const FETCH_TREND_DATA = "FETCH_TREND_DATA";
export const FETCH_OPERATION_TREND = "FETCH_OPERATION_TREND";
export const FETCH_OPERATION_TREND_SUCCESS = "FETCH_OPERATION_TREND_SUCCESS";
export const FETCH_OPERATION_TREND_ERROR = "FETCH_OPERATION_TREND_ERROR";
export const FETCH_VESSEL_STATUS = "FETCH_VESSEL_STATUS";
export const FETCH_VESSEL_STATUS_HISTORY = "FETCH_VESSEL_STATUS_HISTORY";
export const FETCH_VESSEL_STATUS_HISTORY_SUCCESS =
  "FETCH_VESSEL_STATUS_HISTORY_SUCCESS";
export const FETCH_VESSEL_STATUS_HISTORY_ERROR =
  "FETCH_VESSEL_STATUS_HISTORY_ERROR";
export const SET_VESSEL_STATUS_HISTORY_YEAR = "SET_VESSEL_STATUS_HISTORY_YEAR";
export const SET_VESSEL_STATUS_HISTORY_SELECTED_DAY =
  "SET_VESSEL_STATUS_HISTORY_SELECTED_DAY";
export const SET_CALENDAR_DATE = "SET_CALENDAR_DATE";
export const FETCH_CALENDAR_OPERATIONALDATA = "FETCH_CALENDAR_OPERATIONALDATA";
export const FETCH_CALENDAR_OPERATIONALDATA_SUCCESS =
  "FETCH_CALENDAR_OPERATIONALDATA_SUCCESS";
export const FETCH_CALENDAR_OPERATIONALDATA_ERROR =
  "FETCH_CALENDAR_OPERATIONALDATA_ERROR";
export const TOGGLE_PERFORMANCE_OPERATOR = "TOGGLE_PERFORMANCE_OPERATOR";
export const FETCH_CALENDAR_DOCUMENTS = "FETCH_CALENDAR_DOCUMENTS";
export const FETCH_CALENDAR_DOCUMENTS_SUCCESS =
  "FETCH_CALENDAR_DOCUMENTS_SUCCESS";
export const FETCH_CALENDAR_DOCUMENTS_ERROR = "FETCH_CALENDAR_DOCUMENTS_ERROR";
export const TOGGLE_CALENDAR_DOCUMENTS = "TOGGLE_CALENDAR_DOCUMENTS";
export const FETCH_CALENDAR_HEALTH_EVENTS = "FETCH_CALENDAR_HEALTH_EVENTS";
export const FETCH_CALENDAR_HEALTH_EVENTS_SUCCESS =
  "FETCH_CALENDAR_HEALTH_EVENTS_SUCCESS";
export const FETCH_CALENDAR_HEALTH_EVENTS_ERROR =
  "FETCH_CALENDAR_HEALTH_EVENTS_ERROR";
export const TOGGLE_CALENDAR_HEALTH_EVENTS = "TOGGLE_CALENDAR_HEALTH_EVENTS";

export const SUBHEADER_VISIBLE = "SUBHEADER_VISIBLE";
export const SUBHEADER_HIDDEN = "SUBHEADER_HIDDEN";
export const SET_USER_PROFILE_USER_SETTING = "SET_USER_PROFILE_USER_SETTING";
export const SAVE_USER_PROFILE = "SAVE_USER_PROFILE";
export const SAVE_USER_PROFILE_SUCCESS = "SAVE_USER_PROFILE_SUCCESS";
export const TOGGLE_POSITION_TRACK = "TOGGLE_POSITION_TRACK";
export const SET_VESSEL_TRACK_SCROLL_BOUNDARY =
  "SET_VESSEL_TRACK_SCROLL_BOUNDARY";
export const SET_VESSEL_TRACK_DATE = "SET_VESSEL_TRACK_DATE";
export const HOVER_VESSEL_TRACK_ITEM = "HOVER_VESSEL_TRACK_ITEM";
export const SET_ACTIVE_VESSEL_TRACK_ITEM = "SET_ACTIVE_VESSEL_TRACK_ITEM";
export const HOVER_VESSEL_TRACK_GROUP = "HOVER_VESSEL_TRACK_GROUP";
export const CENTER_POINTS_IN_MAP = "CENTER_POINTS_IN_MAP";
export const DATEPICKER_UPDATE = "DATEPICKER_UPDATE";
export const DATEPICKER_SHOW = "DATEPICKER_SHOW";
export const DATEPICKER_HIDE = "DATEPICKER_HIDE";
export const DATEPICKER_SELECT = "DATEPICKER_SELECT";
export const DATEPICKER_CHANGE_TYPE = "DATEPICKER_CHANGE_TYPE";
export const DATEPICKER_NEXT_OPTIONS = "DATEPICKER_NEXT_OPTIONS";
export const DATEPICKER_PREV_OPTIONS = "DATEPICKER_PREV_OPTIONS";
export const DATEPICKER_CHANGE_DATE = "DATEPICKER_CHANGE_DATE";

export const OPERATIONAL_PROFILE_PLAN_ACTIONS = {
  FETCH_OPERATIONAL_PROFILE_DATA: "FETCH_OPERATIONAL_PROFILE_DATA",
  FETCH_OPERATIONAL_PROFILE_DATA_SUCCESS:
    "FETCH_OPERATIONAL_PROFILE_DATA_SUCCESS",
  FETCH_OPERATIONAL_PROFILE_DATA_ERROR: "FETCH_OPERATIONAL_PROFILE_DATA_ERROR",
  FETCH_OPERATIONAL_PROFILE_PLAN_DATA: "FETCH_OPERATIONAL_PROFILE_PLAN_DATA",
  FETCH_OPERATIONAL_PROFILE_PLAN_DATA_SUCCESS:
    "FETCH_OPERATIONAL_PROFILE_PLAN_DATA_SUCCESS",
  FETCH_OPERATIONAL_PROFILE_PLAN_DATA_ERROR:
    "FETCH_OPERATIONAL_PROFILE_PLAN_DATA_ERROR",
  SET_WORKING_PERIOD: "SET_WORKING_PERIOD",
};
export const SET_ANALYSIS_DATE = "SET_ANALYSIS_DATE";
export const SET_ANALYSIS_VESSEL = "SET_ANALYSIS_VESSEL";
export const FETCH_ANALYSIS_OPERATIONS = "FETCH_ANALYSIS_OPERATIONS";
export const FETCH_ANALYSIS_OPERATIONS_SUCCESS =
  "FETCH_ANALYSIS_OPERATIONS_SUCCESS";
export const FETCH_ANALYSIS_OPERATIONS_ERROR =
  "FETCH_ANALYSIS_OPERATIONS_ERROR";
export const FETCH_ANALYSIS_ENGINES = "FETCH_ANALYSIS_ENGINES";
export const FETCH_ANALYSIS_ENGINES_SUCCESS = "FETCH_ANALYSIS_ENGINES_SUCCESS";
export const FETCH_ANALYSIS_ENGINES_ERROR = "FETCH_ANALYSIS_ENGINES_ERROR";
export const FETCH_ANALYSIS_PROPULSORS = "FETCH_ANALYSIS_PROPULSORS";
export const FETCH_ANALYSIS_PROPULSORS_SUCCESS =
  "FETCH_ANALYSIS_PROPULSORS_SUCCESS";
export const FETCH_ANALYSIS_PROPULSORS_ERROR =
  "FETCH_ANALYSIS_PROPULSORS_ERROR";
export const FETCH_ANALYSIS_AXIS_TYPES = "FETCH_ANALYSIS_AXIS_TYPES";
export const FETCH_ANALYSIS_AXIS_TYPES_SUCCESS =
  "FETCH_ANALYSIS_AXIS_TYPES_SUCCESS";
export const FETCH_ANALYSIS_AXIS_TYPES_ERROR =
  "FETCH_ANALYSIS_AXIS_TYPES_ERROR";
export const TOGGLE_ANALYSIS_OPERATION = "TOGGLE_ANALYSIS_OPERATION";
export const TOGGLE_ANALYSIS_ENGINE = "TOGGLE_ANALYSIS_ENGINE";
export const TOGGLE_ANALYSIS_PROPULSOR = "TOGGLE_ANALYSIS_PROPULSOR";
export const FETCH_ANALYSIS_PLOT = "FETCH_ANALYSIS_PLOT";
export const FETCH_ANALYSIS_PLOT_SUCCESS = "FETCH_ANALYSIS_PLOT_SUCCESS";
export const FETCH_ANALYSIS_FILTER = "FETCH_ANALYSIS_FILTER";
export const FETCH_ANALYSIS_FILTER_SUCCESS = "FETCH_ANALYSIS_FILTER_SUCCESS";
export const FETCH_ANALYSIS_FILTER_ERROR = "FETCH_ANALYSIS_FILTER_ERROR";
export const SET_ANALYSIS_FILTER_VALUE = "SET_ANALYSIS_FILTER_VALUE";
export const SET_ANALYSIS_AXIS_VALUE_TYPE = "SET_ANALYSIS_AXIS_VALUE_TYPE";
export const SET_ANALYSIS_Z_DOMAIN = "SET_ANALYSIS_Z_DOMAIN";

export const ANALYSIS_FETCHING_METADATA = "ANALYSIS_FETCHING_METADATA";
export const ANALYSIS_FETCHING_METADATA_FAILED =
  "ANALYSIS_FETCHING_METADATA_FAILED";
export const ANALYSIS_FETCHING_METADATA_SUCCESS =
  "ANALYSIS_FETCHING_METADATA_SUCCESS";

export const FETCH_API_ALIVE_SUCCESS = "FETCH_API_ALIVE_SUCCESS";
export const FETCH_API_ALIVE_ERROR = "FETCH_API_ALIVE_ERROR";
export const SET_SELECTED_DASHBOARD = "SET_SELECTED_DASHBOARD";

export const FETCH_VESSEL_TOTAL_POSITIONS = "FETCH_VESSEL_TOTAL_POSITIONS";
export const FETCH_VESSEL_TOTAL_POSITIONS_SUCCESS =
  "FETCH_VESSEL_TOTAL_POSITIONS_SUCCESS";
export const FETCH_VESSEL_PERIOD_POSITIONS = "FETCH_VESSEL_PERIOD_POSITIONS";
export const FETCH_VESSEL_PERIOD_POSITIONS_SUCCESS =
  "FETCH_VESSEL_PERIOD_POSITIONS_SUCCESS";
export const FETCH_MACHINERY_STATUS = "FETCH_MACHINERY_STATUS";
export const FETCH_MACHINERY_STATUS_SUCCESS = "FETCH_MACHINERY_STATUS_SUCCESS";
export const FETCH_MACHINERY_STATUS_ERROR = "FETCH_MACHINERY_STATUS_ERROR";
export const SET_MACHINERY_STATUS_MAP_PROPERTIES =
  "SET_MACHINERY_STATUS_MAP_PROPERTIES";

export const SET_DOCUMENTS_SEARCH_STRING = "SET_DOCUMENTS_SEARCH_STRING";
export const CLEAR_DOCUMENTS_FILTERS = "CLEAR_DOCUMENTS_FILTERS";
export const TOGGLE_DOCUMENT_FOLDER_OPEN = "TOGGLE_DOCUMENT_FOLDER_OPEN";
export const SET_DOCUMENTS_VESSEL_GROUP_FILTER =
  "SET_DOCUMENTS_VESSEL_GROUP_FILTER";
export const SET_DOCUMENTS_VESSEL_FILTER = "SET_DOCUMENTS_VESSEL_FILTER";
export const SET_DOCUMENTS_MODIFIED_FILTER = "SET_DOCUMENTS_MODIFIED_FILTER";
export const SET_DOCUMENTS_FILE_TYPE_FILTER = "SET_DOCUMENTS_FILE_TYPE_FILTER";
export const BEGIN_DOCUMENTS_EDIT_FILE = "BEGIN_DOCUMENTS_EDIT_FILE";
export const CANCEL_DOCUMENTS_FILE = "CANCEL_DOCUMENTS_EDIT_FILE";
export const SET_DOCUMENTS_EDIT_FILENAME = "SET_DOCUMENTS_EDIT_FILENAME";
export const SET_DOCUMENTS_EDIT_VESSEL_ID = "SET_DOCUMENTS_EDIT_VESSEL_ID";
export const SET_DOCUMENTS_EDIT_CHECKED = "SET_DOCUMENTS_EDIT_CHECKED";
export const SET_DOCUMENTS_EDIT_FILE = "SET_DOCUMENTS_EDIT_FILE";
export const SET_DOCUMENTS_EDIT_FILE_FOLDER_ID =
  "SET_DOCUMENTS_EDIT_FILE_FOLDER_ID";
export const BEGIN_DOCUMENTS_ADD_FILE = "BEGIN_DOCUMENTS_ADD_FILE";
export const SAVE_DOCUMENTS_FILE = "SAVE_DOCUMENTS_FILE";
export const SAVE_DOCUMENTS_FILE_SUCCESS = "SAVE_DOCUMENTS_FILE_SUCCESS";
export const SAVE_DOCUMENTS_FILE_ERROR = "SAVE_DOCUMENTS_FILE_ERROR";
export const SAVE_DOCUMENTS_FILE_PROGRESS = "SAVE_DOCUMENTS_FILE_PROGRESS";
export const BEGIN_DOCUMENTS_ADD_FOLDER = "BEGIN_DOCUMENTS_ADD_FOLDER";
export const BEGIN_DOCUMENTS_EDIT_FOLDER = "BEGIN_DOCUMENTS_EDIT_FOLDER";
export const CANCEL_DOCUMENTS_EDIT_FOLDER = "CANCEL_DOCUMENTS_EDIT_FOLDER";
export const BEGIN_DOCUMENTS_DELETE_FILE = "BEGIN_DOCUMENTS_DELETE_FILE";
export const CANCEL_DOCUMENTS_DELETE_FILE = "CANCEL_DOCUMENTS_DELETE_FILE";
export const DELETE_DOCUMENTS_FILE = "DELETE_DOCUMENTS_FILE";
export const DELETE_DOCUMENTS_FILE_SUCCESS = "DELETE_DOCUMENTS_FILE_SUCCESS";
export const DELETE_DOCUMENTS_FILE_ERROR = "DELETE_DOCUMENTS_FILE_ERROR";
export const SAVE_DOCUMENTS_EDIT_FOLDER = "SAVE_DOCUMENTS_EDIT_FOLDER";
export const SAVE_DOCUMENTS_EDIT_FOLDER_SUCCESS =
  "SAVE_DOCUMENTS_EDIT_FOLDER_SUCCESS";
export const SAVE_DOCUMENTS_EDIT_FOLDER_ERROR =
  "SAVE_DOCUMENTS_EDIT_FOLDER_ERROR";
export const SET_DOCUMENTS_EDIT_FOLDER_NAME = "SET_DOCUMENTS_EDIT_FOLDER_NAME";
export const SET_DOCUMENTS_EDIT_FOLDER_DESC = "SET_DOCUMENTS_EDIT_FOLDER_DESC";
export const FETCH_DOCUMENTS_FILE_TYPES = "FETCH_DOCUMENTS_FILE_TYPES";
export const FETCH_DOCUMENTS_FILE_TYPES_SUCCESS =
  "FETCH_DOCUMENTS_FILE_TYPES_SUCCESS";
export const FETCH_DOCUMENTS_FILE_TYPES_ERROR =
  "FETCH_DOCUMENTS_FILE_TYPES_ERROR";
export const FETCH_DOCUMENTS_DATE_RANGE = "FETCH_DOCUMENTS_DATE_RANGE";
export const FETCH_DOCUMENTS_DATE_RANGE_SUCCESS =
  "FETCH_DOCUMENTS_DATE_RANGE_SUCCESS";
export const FETCH_DOCUMENTS_DATE_RANGE_ERROR =
  "FETCH_DOCUMENTS_DATE_RANGE_ERROR";
export const SET_DOCUMENTS_COMPANY_ID = "SET_DOCUMENTS_COMPANY_ID";
export const FETCH_DOCUMENTS_FILES = "FETCH_DOCUMENTS_FILES";
export const FETCH_DOCUMENTS_FILES_SUCCESS = "FETCH_DOCUMENTS_FILES_SUCCESS";
export const FETCH_DOCUMENTS_FILES_ERROR = "FETCH_DOCUMENTS_FILES_ERROR";
export const FETCH_DOCUMENTS_FOLDERS = "FETCH_DOCUMENTS_FOLDERS";
export const FETCH_DOCUMENTS_FOLDERS_SUCCESS =
  "FETCH_DOCUMENTS_FOLDERS_SUCCESS";
export const FETCH_DOCUMENTS_FOLDERS_ERROR = "FETCH_DOCUMENTS_FOLDERS_ERROR";
export const FETCH_DOCUMENTS_FILE_DOWNLOAD_URL =
  "FETCH_DOCUMENTS_FILE_DOWNLOAD_URL";
export const FETCH_DOCUMENTS_FILE_DOWNLOAD_URL_SUCCESS =
  "FETCH_DOCUMENTS_FILE_DOWNLOAD_URL_SUCCESS";
export const FETCH_DOCUMENTS_FILE_DOWNLOAD_URL_ERROR =
  "FETCH_DOCUMENTS_FILE_DOWNLOAD_URL_ERROR";
export const LOAD_DOCUMENTS_FILTERS = "LOAD_DOCUMENTS_FILTERS";
export const LOAD_DOCUMENTS_FILTERS_SUCCESS = "LOAD_DOCUMENTS_FILTERS_SUCCESS";
export const LOAD_DOCUMENTS_FILTERS_ERROR = "LOAD_DOCUMENTS_FILTERS_ERROR";
export const CLEAR_DOCUMENTS_ERRORS = "CLEAR_DOCUMENTS_ERRORS";

export const FETCH_ENERGYBUDGET_RESULT = "FETCH_ENERGYBUDGET_RESULT";
export const FETCH_ENERGYBUDGET_OVERVIEW = "FETCH_ENERGYBUDGET_OVERVIEW";

export const FETCH_LOCATIONS_IN_AREA = "FETCH_LOCATIONS_IN_AREA";
export const FETCH_LOCATIONS_IN_AREA_SUCCESS =
  "FETCH_LOCATIONS_IN_AREA_SUCCESS";
export const FETCH_LOCATIONS_IN_AREA_ERROR = "FETCH_LOCATIONS_IN_AREA_ERROR";

export const ACKNOWLEDGE_EVENT = "ACKNOWLEDGE_EVENT";
export const ACKNOWLEDGE_EVENT_SUCCESS = "ACKNOWLEDGE_EVENT_SUCCESS";

export const RESOLVE_EVENT = "RESOLVE_EVENT";
export const RESOLVE_EVENT_SUCCESS = "RESOLVE_EVENT_SUCCESS";

export const PENDING_ACTION_EVENT = "PENDING_ACTION_EVENT";
export const PENDING_ACTION_EVENT_SUCCESS = "PENDING_ACTION_EVENT_SUCCESS";

export const excelExportActions = {
  EXPORT: "export.EXPORT",
  EXPORT_SUCCESS: "export.EXPORT_SUCCESS",
  EXPORT_ERROR: "export.EXPORT_ERROR",
  SHOW_EXPORT: "export.SHOW_EXPORT",
  HIDE_EXPORT: "export.HIDE_EXPORT",
  TOGGLE_TIME_OFFSET_OPTION: "export.TOGGLE_TIME_OFFSET_OPTION",
  SET_START_TIME_OFFSET: "export.SET_START_TIME_OFFSET",
  SET_END_TIME_OFFSET: "export.SET_END_TIME_OFFSET",
};

export const calendarEvents = {
  FETCH_CALENDAR_EVENTS: "FETCH_CALENDAR_EVENTS",
  FETCH_CALENDAR_EVENTS_SUCCESS: "FETCH_CALENDAR_EVENTS_SUCCESS",
  FETCH_CALENDAR_EVENTS_ERROR: "FETCH_CALENDAR_EVENTS_ERROR",
  TOGGLE_CALENDAR_EVENTS: "calendarEvents.TOGGLE_CALENDAR_EVENTS",
  ADD_EVENT: "calendarEvents.ADD_EVENT",
  CLOSE_EVENT_DIALOG: "calendarEvents.CLOSE_EVENT_DIALOG",
  EVENT_FIELD_VALUE_CHANGED: "calendarEvents.EVENT_FIELD_VALUE_CHANGED",
  SAVE_EVENT: "calendarEvents.SAVE_EVENT",
  SAVE_EVENT_SUCCESS: "calendarEvents.SAVE_EVENT_SUCCESS",
  SAVE_EVENT_ERROR: "calendarEvents.SAVE_EVENT_ERROR",
  EDIT_EVENT: "calendarEvents.EDIT_EVENT",
  DELETE_EVENT: "calendarEvents.DELETE_EVENT",
  CONFIRM_DELETE_EVENT: "calendarEvents.CONFIRM_DELETE_EVENT",
  CONFIRM_DELETE_EVENT_SUCCESS: "calendarEvents.CONFIRM_DELETE_EVENT_SUCCESS",
  CONFIRM_DELETE_EVENT_ERROR: "calendarEvents.CONFIRM_DELETE_EVENT_ERROR",
};
export const calendarManualFuelData = {
  FETCH_CALENDAR_MANUAL_FUEL_DATA: "FETCH_CALENDAR_MANUAL_FUEL_DATA",
  FETCH_CALENDAR_MANUAL_FUEL_DATA_SUCCESS:
    "FETCH_CALENDAR_MANUAL_FUEL_DATA_SUCCESS",
  FETCH_MANUAL_FUEL_DATA_CONFIG: "FETCH_MANUAL_FUEL_DATA_CONFIG",
  FETCH_MANUAL_FUEL_DATA_CONFIG_SUCCESS:
    "FETCH_MANUAL_FUEL_DATA_CONFIG_SUCCESS",
  TOGGLE_CALENDAR_MANUAL_FUEL_DATA:
    "calendarManualFuelData.TOGGLE_CALENDAR_EVENTS",
  ADD_MANUAL_FUEL_DATA: "calendarManualFuelData.ADD_MANUAL_FUEL_DATA",
  CLOSE_MANUAL_FUEL_DATA_DIALOG:
    "calendarManualFuelData.CLOSE_MANUAL_FUEL_DATA_DIALOG",
  MANUAL_FUEL_DATA_FIELD_VALUE_CHANGED:
    "calendarEvents.MANUAL_FUEL_DATA_FIELD_VALUE_CHANGED",
  SAVE_MANUAL_FUEL_DATA: "calendarManualFuelData.SAVE_MANUAL_FUEL_DATA",
  SAVE_MANUAL_FUEL_DATA_SUCCESS:
    "calendarManualFuelData.SAVE_MANUAL_FUEL_DATA_SUCCESS",
  SAVE_MANUAL_FUEL_DATA_ERROR:
    "calendarManualFuelData.SAVE_MANUAL_FUEL_DATA_ERROR",
  ADD_ROW_MANUAL_FUEL_DATA_DIALOG:
    "calendarManualFuelData.ADD_ROW_MANUAL_FUEL_DATA_DIALOG",
  REMOVE_ROW_MANUAL_FUEL_DATA_DIALOG:
    "calendarManualFuelData.REMOVE_ROW_MANUAL_FUEL_DATA_DIALOG",
};

export const fuelAndEmissions = {
  CHANGE_FILTERS: "fuelAndEmissions.CHANGE_FILTERS",
  fuelConsumptions: {
    FETCH_FUEL_CONSUMPTIONS:
      "fuelAndEmissions.fuelConsumptions.FETCH_FUEL_CONSUMPTIONS",
    FETCH_FUEL_CONSUMPTIONS_SUCCESS:
      "fuelAndEmissions.fuelConsumptions.FETCH_FUEL_CONSUMPTIONS_SUCCESS",
    FETCH_FUEL_CONSUMPTIONS_ERROR:
      "fuelAndEmissions.fuelConsumptions.FETCH_FUEL_CONSUMPTIONS_ERROR",
    CHANGE_FUEL: "fuelAndEmissions.fuelConsumptions.CHANGE_FUEL",
  },
  donutData: {
    FETCH_DONUT_DATA: "fuelAndEmissions.donutData.FETCH_DONUT_DATA",
    FETCH_DONUT_DATA_SUCCESS:
      "fuelAndEmissions.donutData.FETCH_DONUT_DATA_SUCCESS",
    FETCH_DONUT_DATA_ERROR: "fuelAndEmissions.donutData.FETCH_DONUT_DATA_ERROR",
  },
  emissionsTrend: {
    FETCH_EMISSIONS_TREND:
      "fuelAndEmissions.emissionsTrend.FETCH_EMISSIONS_TREND",
    FETCH_EMISSIONS_TREND_SUCCESS:
      "fuelAndEmissions.emissionsTrend.FETCH_EMISSIONS_TREND_SUCCESS",
    FETCH_EMISSIONS_TREND_ERROR:
      "fuelAndEmissions.emissionsTrend.FETCH_EMISSIONS_TREND_ERROR",
    CHANGE_EMISSIONS: "fuelAndEmissions.emissionsTrend.CHANGE_EMISSIONS",
  },
  emissionsByOperationModes: {
    FETCH_EMISSIONS_BY_OPERATION_MODES_DATA:
      "fuelAndEmissions.emissions.FETCH_EMISSIONS_BY_OPERATION_MODES_DATA",
    FETCH_EMISSIONS_BY_OPERATION_MODES_DATA_SUCCESS:
      "fuelAndEmissions.emissions.FETCH_EMISSIONS_BY_OPERATION_MODES_DATA_SUCCESS",
    FETCH_EMISSIONS_BY_OPERATION_MODES_DATA_ERROR:
      "fuelAndEmissions.emissions.FETCH_EMISSIONS_BY_OPERATION_MODES_DATA_ERROR",
  },
  fuelAndTarget: {
    FETCH_FUEL_AND_TARGET_DATA:
      "fuelAndEmissions.fuelTarget.FETCH_FUEL_AND_TARGET_DATA",
    FETCH_FUEL_AND_TARGET_DATA_SUCCESS:
      "fuelAndEmissions.fuelTarget.FETCH_FUEL_AND_TARGET_DATA_SUCCESS",
    FETCH_FUEL_AND_TARGET_DATA_ERROR:
      "fuelAndEmissions.fuelTarget.FETCH_FUEL_AND_TARGET_DATA_ERROR",
    CHANGE_FUEL: "fuelAndEmissions.fuelTarget.CHANGE_FUEL",
  },
  setFuelTargetDialog: {
    OPEN: "fuelAndEmissions.setFuelTargetDialog.OPEN",
    CANCEL: "fuelAndEmissions.setFuelTargetDialog.CANCEL",
    HIDE: "fuelAndEmissions.setFuelTargetDialog.HIDE",
    SAVE: "fuelAndEmissions.setFuelTargetDialog.SAVE",
    FETCH_FUEL_TARGET_DATA:
      "fuelAndEmissions.setFuelTargetDialog.FETCH_FUEL_TARGET_DATA",
    FETCH_FUEL_TARGET_DATA_SUCCESS:
      "fuelAndEmissions.setFuelTargetDialog.FETCH_FUEL_TARGET_DATA_SUCCESS",
    FETCH_FUEL_TARGET_DATA_ERROR:
      "fuelAndEmissions.setFuelTargetDialog.FETCH_FUEL_TARGET_DATA_ERROR",
    TARGET_FUEL_DATA_FIELD_VALUE_CHANGED:
      "fuelAndEmissions.setFuelTargetDialog.TARGET_FUEL_DATA_FIELD_VALUE_CHANGED",
    SAVE_FUEL_TARGET_DATA:
      "fuelAndEmissions.setFuelTargetDialog.SAVE_FUEL_TARGET_DATA",
    SAVE_FUEL_TARGET_DATA_SUCCESS:
      "fuelAndEmissions.setFuelTargetDialog.SAVE_FUEL_TARGET_DATA_SUCCESS",
    SAVE_FUEL_TARGET_DATA_ERROR:
      "fuelAndEmissions.setFuelTargetDialog.SAVE_FUEL_TARGET_DATA_ERROR",
  },
};

export const components = {
  equinor: {
    FETCH_VESSEL_REPORT: "components.equinor.FETCH_VESSEL_REPORT",
    FETCH_VESSEL_REPORT_SUCCESS:
      "components.equinor.FETCH_VESSEL_REPORT_SUCCESS",
    FETCH_VESSEL_REPORT_ERROR: "components.equinor.FETCH_VESSEL_REPORT_ERROR",
  },
  trend: {
    FETCH_VESSEL: "components.trend.FETCH_VESSEL",
    FETCH_VESSEL_ERROR: "components.trend.FETCH_VESSEL_ERROR",
    FETCH_VESSEL_SUCCESS: "components.trend.FETCH_VESSEL_SUCCESS",
  },
  vessel: {
    dataQuality: {
      FETCH: "components.vessel.dataQuality.FETCH",
      FETCH_ERROR: "components.vessel.dataQuality.FETCH_ERROR",
      FETCH_SUCCESS: "components.vessel.dataQuality.FETCH_SUCCESS",
      HIDE: "components.vessel.dataQuality.HIDE",
      SHOW: "components.vessel.dataQuality.SHOW",
    },
  },
};

export const vesselConfig = {
  INIT: "vesselConfig.INIT",
  INIT_SUCCESS: "vesselConfig.INIT_SUCCESS",
  INIT_ERROR: "vesselConfig.INIT_ERROR",
  TOGGLE_PANEL: "vesselConfig.TOGGLE_PANEL",
  CLOSE: "vesselConfig.CLOSE",
  vesselDetails: {
    INITIALIZE: "vesselConfig.vesselDetails.INITIALIZE",
    FETCH_NEW_VESSEL_DATA: "vesselConfig.vesselDetails.FETCH_NEW_VESSEL_DATA",
    FETCH_NEW_VESSEL_DATA_SUCCESS:
      "vesselConfig.vesselDetails.FETCH_NEW_VESSEL_DATA_SUCCESS",
    FETCH_NEW_VESSEL_DATA_ERROR:
      "vesselConfig.vesselDetails.FETCH_NEW_VESSEL_DATA_ERROR",
    FETCH_DETAILS: "vesselConfig.vesselDetails.FETCH_DETAILS",
    FETCH_DETAILS_SUCCESS: "vesselConfig.vesselDetails.FETCH_DETAILS_SUCCESS",
    FETCH_DETAILS_ERROR: "vesselConfig.vesselDetails.FETCH_DETAILS_ERROR",
    INPUT_CHANGED: "vesselConfig.vesselDetails.INPUT_CHANGED",
    SAVE_VESSEL: "vesselConfig.vesselDetails.SAVE_VESSEL",
    SAVE_VESSEL_SUCCESS: "vesselConfig.vesselDetails.SAVE_VESSEL_SUCCESS",
    SAVE_VESSEL_ERROR: "vesselConfig.vesselDetails.SAVE_VESSEL_ERROR",
    SET_PROGRESS_MESSAGE: "vesselConfig.vesselDetails.SET_PROGRESS_MESSAGE",
    COMPANY_CHANGED: "vesselConfig.vesselDetails.COMPANY_CHANGED",
    VESSEL_GROUP_CHANGED: "vesselConfig.vesselDetails.VESSEL_GROUP_CHANGED",
    VESSEL_IMAGE_CHANGED: "vesselConfig.vesselDetails.VESSEL_IMAGE_CHANGED",
    SHOW_GENERATE_API_TOKEN_DIALOG:
      "vesselConfig.vesselDetails.SHOW_GENERATE_TOKEN_DIALOG",
    CLOSE_GENERATE_API_TOKEN_DIALOG:
      "vesselConfig.vesselDetails.CLOSE_GENERATE_TOKEN_DIALOG",
    GENERATE_API_TOKEN: "vesselConfig.vesselDetails.GENERATE_API_TOKEN",
    GENERATE_API_TOKEN_SUCCESS:
      "vesselConfig.vesselDetails.GENERATE_API_TOKEN_SUCCESS",
    GENERATE_API_TOKEN_ERROR:
      "vesselConfig.vesselDetails.GENERATE_API_TOKEN_ERROR",
    SHOW_WINDCONFIG_POPUP: "vesselConfig.vesselDetails.SHOW_WINDCONFIG_POPUP",
    HIDE_WINDCONFIG_POPUP: "vesselConfig.vesselDetails.HIDE_WINDCONFIG_POPUP",
    DOWNLOAD_WINDCONFIG: "vesselConfig.vesselDetails.DOWNLOAD_WINDCONFIG",
    DOWNLOAD_WINDCONFIG_ERROR:
      "vesselConfig.vesselDetails.DOWNLOAD_WINDCONFIG_ERROR",
    SAVE_WINDCONFIG: "vesselConfig.vesselDetails.SAVE_WINDCONFIG",
    SAVE_WINDCONFIG_SUCCESS:
      "vesselConfig.vesselDetails.SAVE_WINDCONFIG_SUCCESS",
    SAVE_WINDCONFIG_ERROR: "vesselConfig.vesselDetails.SAVE_WINDCONFIG_ERROR",
    WIND_CONFIG_CHANGED: "vesselConfig.vesselDetails.WIND_CONFIG_CHANGED",
    COPY_FROM_VESSEL_CHANGED:
      "vesselConfig.vesselDetails.COPY_FROM_VESSEL_CHANGED",
    SHOW_SAVE_DIALOG: "vesselConfig.vesselDetails.SHOW_SAVE_DIALOG",
    HIDE_SAVE_DIALOG: "vesselConfig.vesselDetails.HIDE_SAVE_DIALOG",
  },
  onboardFrontendConfig: {
    FETCH: "vesselConfig.onboardFrontendConfig.FETCH",
    FETCH_SUCCESS: "vesselConfig.onboardFrontendConfig.FETCH_SUCCESS",
    FETCH_ERROR: "vesselConfig.onboardFrontendConfig.FETCH_ERROR",
    INPUT_CHANGED: "vesselConfig.onboardFrontendConfig.INPUT_CHANGED",
    DASHBOARD_INPUT_CHANGED:
      "vesselConfig.onboardFrontendConfig.DASHBOARD_INPUT_CHANGED",
    SAVE: "vesselConfig.onboardFrontendConfig.SAVE",
    SAVE_SUCCESS: "vesselConfig.onboardFrontendConfig.SAVE_SUCCESS",
    SAVE_ERROR: "vesselConfig.onboardFrontendConfig.SAVE_ERROR",
    DOWNLOAD: "vesselConfig.onboardFrontendConfig.DOWNLOAD",
    DOWNLOAD_SUCCESS: "vesselConfig.onboardFrontendConfig.DOWNLOAD_SUCCESS",
    DOWNLOAD_ERROR: "vesselConfig.onboardFrontendConfig.DOWNLOAD_ERROR",
    DEPLOY: "vesselConfig.onboardFrontendConfig.DEPLOY",
    DEPLOY_SUCCESS: "vesselConfig.onboardFrontendConfig.DEPLOY_SUCCESS",
    DEPLOY_ERROR: "vesselConfig.onboardFrontendConfig.DEPLOY_ERROR",
    VESSEL_IMAGE_CHANGED:
      "vesselConfig.onboardFrontendConfig.VESSEL_IMAGE_CHANGED",
    VESSEL_IMAGE_CHANGED_ERROR:
      "vesselConfig.onboardFrontendConfig.VESSEL_IMAGE_CHANGED_ERROR",
    SHOW_VESSEL_IMAGE_PREVIEW:
      "vesselConfig.onboardFrontendConfig.SHOW_VESSEL_IMAGE_PREVIEW",
    HIDE_VESSEL_IMAGE_PREVIEW:
      "vesselConfig.onboardFrontendConfig.HIDE_VESSEL_IMAGE_PREVIEW",
    CHANGE_CONFIG_VERSION:
      "vesselConfig.onboardFrontendConfig.CHANGE_CONFIG_VERSION",
  },
  onboardProcessorConfig: {
    FETCH_DATA: "vesselConfig.onboardProcessorConfig.FETCH_DATA",
    FETCH_DATA_SUCCESS:
      "vesselConfig.onboardProcessorConfig.FETCH_DATA_SUCCESS",
    FETCH_DATA_ERROR: "vesselConfig.onboardProcessorConfig.FETCH_DATA_ERROR",
    DEPLOY_REQUESTED: "vesselConfig.onboardProcessorConfig.DEPLOY_REQUESTED",
    DEPLOY: "vesselConfig.onboardProcessorConfig.DEPLOY",
    DEPLOY_SUCCESS: "vesselConfig.onboardProcessorConfig.DEPLOY_SUCCESS",
    DEPLOY_ERROR: "vesselConfig.onboardProcessorConfig.DEPLOY_ERROR",
    DOWNLOAD_REQUESTED:
      "vesselConfig.onboardProcessorConfig.DOWNLOAD_REQUESTED",
    DOWNLOAD: "vesselConfig.onboardProcessorConfig.DOWNLOAD",
    DOWNLOAD_SUCCESS: "vesselConfig.onboardProcessorConfig.DOWNLOAD_SUCCESS",
    DOWNLOAD_ERROR: "vesselConfig.onboardProcessorConfig.DOWNLOAD_ERROR",
    GENERATE_REQUESTED:
      "vesselConfig.onboardProcessorConfig.GENERATE_REQUESTED",
    GENERATE: "vesselConfig.onboardProcessorConfig.GENERATE",
    GENERATE_SUCCESS: "vesselConfig.onboardProcessorConfig.GENERATE_SUCCESS",
    GENERATE_ERROR: "vesselConfig.onboardProcessorConfig.GENERATE_ERROR",
    SHOW_GENERATE_PANEL:
      "vesselConfig.onboardProcessorConfig.SHOW_GENERATE_PANEL",
    HIDE_GENERATE_PANEL:
      "vesselConfig.onboardProcessorConfig.HIDE_GENERATE_PANEL",
    SELECT_BASELINE: "vesselConfig.onboardProcessorConfig.SELECT_BASELINE",
  },
  onshoreFrontendConfig: {
    FETCH: "vesselConfig.onshoreFrontendConfig.FETCH",
    FETCH_SUCCESS: "vesselConfig.onshoreFrontendConfig.FETCH_SUCCESS",
    FETCH_ERROR: "vesselConfig.onshoreFrontendConfig.FETCH_ERROR",
    INPUT_CHANGED: "vesselConfig.onshoreFrontendConfig.INPUT_CHANGED",
    SAVE: "vesselConfig.onshoreFrontendConfig.SAVE",
    SAVE_SUCCESS: "vesselConfig.onshoreFrontendConfig.SAVE_SUCCESS",
    SAVE_ERROR: "vesselConfig.onshoreFrontendConfig.SAVE_ERROR",
  },
  vesselOperations: {
    FETCH: "vesselConfig.vesselOperations.FETCH",
    FETCH_SUCCESS: "vesselConfig.vesselOperations.FETCH_SUCCESS",
    FETCH_ERROR: "vesselConfig.vesselOperations.FETCH_ERROR",
    EDIT: "vesselConfig.vesselOperations.EDIT",
    CANCEL_EDIT: "vesselConfig.vesselOperations.CANCEL_EDIT",
    INPUT_CHANGED: "vesselConfig.vesselOperations.INPUT_CHANGED",
    SAVE: "vesselConfig.vesselOperations.SAVE",
    SAVE_NEW_SUCCESS: "vesselConfig.vesselOperations.SAVE_NEW_SUCCESS",
    SAVE_NEW_ERROR: "vesselConfig.vesselOperations.SAVE_NEW_ERROR",
    UPDATE_EXISTING_SUCCESS:
      "vesselConfig.vesselOperations.UPDATE_EXISTING_SUCCESS",
    UPDATE_EXISTING_ERROR:
      "vesselConfig.vesselOperations.UPDATE_EXISTING_ERROR",
    NEW: "vesselConfig.vesselOperations.NEW",
    DELETE: "vesselConfig.vesselOperations.DELETE",
    CANCEL_DELETE: "vesselConfig.vesselOperations.CANCEL_DELETE",
    CONFIRM_DELETE: "vesselConfig.vesselOperations.CONFIRM_DELETE",
    CONFIRM_DELETE_SUCCESS:
      "vesselConfig.vesselOperations.CONFIRM_DELETE_SUCCESS",
    CONFIRM_DELETE_ERROR: "vesselConfig.vesselOperations.CONFIRM_DELETE_ERROR",
  },
  vesselItems: {
    FETCH: "vesselConfig.vesselItems.FETCH",
    FETCH_SUCCESS: "vesselConfig.vesselItems.FETCH_SUCCESS",
    FETCH_ERROR: "vesselConfig.vesselItems.FETCH_ERROR",
    BEGIN_EDIT: "vesselConfig.vesselItems.BEGIN_EDIT",
    BEGIN_EDIT_SUCCESS: "vesselConfig.vesselItems.BEGIN_EDIT_SUCCESS",
    BEGIN_EDIT_ERROR: "vesselConfig.vesselItems.BEGIN_EDIT_ERROR",
    CANCEL_EDIT: "vesselConfig.vesselItems.CANCEL_EDIT",
    INPUT_CHANGED: "vesselConfig.vesselItems.INPUT_CHANGED",
    FUEL_TYPES_CHANGED: "vesselConfig.vesselItems.FUEL_TYPES_CHANGED",
    METRIC_CHANGED: "vesselConfig.vesselItem.METRIC_CHANGED",
    SAVE: "vesselConfig.vesselItems.SAVE",
    SAVE_SUCCESS: "vesselConfig.vesselItems.SAVE_SUCCESS",
    SAVE_ERROR: "vesselConfig.vesselItems.SAVE_ERROR",
    CREATE: "vesselConfig.vesselItems.CREATE",
    DELETE: "vesselConfig.vesselItems.DELETE",
    CANCEL_DELETE: "vesselConfig.vesselItems.CANCEL_DELETE",
    CONFIRM_DELETE: "vesselConfig.vesselItems.CONFIRM_DELETE",
    CONFIRM_DELETE_SUCCESS: "vesselConfig.vesselItems.CONFIRM_DELETE_SUCCESS",
    CONFIRM_DELETE_ERROR: "vesselConfig.vesselItems.CONFIRM_DELETE_ERROR",
  },
  baseline: {
    INITIALIZE: "vesselConfig.baseline.INITIALIZE",
    FETCH: "vesselConfig.baseline.FETCH",
    FETCH_SUCCESS: "vesselConfig.baseline.FETCH_SUCCESS",
    FETCH_ERROR: "vesselConfig.baseline.FETCH_ERROR",
    DOWNLOAD: "vesselConfig.baseline.DOWNLOAD",
    SHOW_UPLOAD_FORM: "vesselConfig.baseline.SHOW_UPLOAD_FORM",
    SET_BASELINE_TXT: "vesselConfig.baseline.SET_BASELINE_TXT",
    UPLOAD_REQUESTED: "vesselConfig.baseline.UPLOAD_REQUESTED",
    UPLOAD: "vesselConfig.baseline.UPLOAD",
    UPLOAD_SUCCESS: "vesselConfig.baseline.UPLOAD_SUCCESS",
    UPLOAD_ERROR: "vesselConfig.baseline.UPLOAD_ERROR",
  },
  vesselPerformanceIndicators: {
    FETCH: "vesselConfig.vesselPerformanceIndicators.FETCH",
    FETCH_SUCCESS: "vesselConfig.vesselPerformanceIndicators.FETCH_SUCCESS",
    FETCH_ERROR: "vesselConfig.vesselPerformanceIndicators.FETCH_ERROR",
    CANCEL_EDIT: "vesselConfig.vesselPerformanceIndicators.CANCEL_EDIT",
    INPUT_CHANGED: "vesselConfig.vesselPerformanceIndicators.INPUT_CHANGED",
    NEW: "vesselConfig.vesselPerformanceIndicators.NEW",
    EDIT: "vesselConfig.vesselPerformanceIndicators.EDIT",
    SAVE: "vesselConfig.vesselPerformanceIndicators.SAVE",
    SAVE_SUCCESS: "vesselConfig.vesselPerformanceIndicators.SAVE_SUCCESS",
    SAVE_ERROR: "vesselConfig.vesselPerformanceIndicators.SAVE_ERROR",
    DELETE: "vesselConfig.vesselPerformanceIndicators.DELETE",
    CANCEL_DELETE: "vesselConfig.vesselPerformanceIndicators.CANCEL_DELETE",
    CONFIRM_DELETE: "vesselConfig.vesselPerformanceIndicators.CONFIRM_DELETE",
    CONFIRM_DELETE_SUCCESS:
      "vesselConfig.vesselPerformanceIndicators.CONFIRM_DELETE_SUCCESS",
    CONFIRM_DELETE_ERROR:
      "vesselConfig.vesselPerformanceIndicators.CONFIRM_DELETE_ERROR",
  },
  hullPerformance: {
    FETCH: "vesselConfig.hullPerformance.FETCH",
    FETCH_SUCCESS: "vesselConfig.hullPerformance.FETCH_SUCCESS",
    FETCH_ERROR: "vesselConfig.hullPerformance.FETCH_ERROR",
    INPUT_CHANGED: "vesselConfig.hullPerformance.INPUT_CHANGED",
    SAVE: "vesselConfig.hullPerformance.SAVE",
    SAVE_SUCCESS: "vesselConfig.hullPerformance.SAVE_SUCCESS",
    SAVE_ERROR: "vesselConfig.hullPerformance.SAVE_ERROR",
    VALIDATE: "vesselConfig.hullPerformance.VALIDATE",
    VALIDATE_SUCCESS: "vesselConfig.hullPerformance.VALIDATE_SUCCESS",
    VALIDATE_ERROR: "vesselConfig.hullPerformance.VALIDATE_ERROR",
    FETCH_CALCULAION_RESULT:
      "vesselConfig.hullPerformance.FETCH_CALCULATION_RESULT",
    FETCH_CALCULAION_RESULT_SUCCESS:
      "vesselConfig.hullPerformance.FETCH_CALCULATION_RESULT_SUCCESS",
    FETCH_CALCULAION_RESULT_ERROR:
      "vesselConfig.hullPerformance.FETCH_CALCULATION_RESULT_ERROR",
    SHOW_POPUP: "vesselConfig.hullPerformance.SHOW_POPUP",
    HIDE_POPUP: "vesselConfig.hullPerformance.HIDE_POPUP",
    DOWNLOAD: "vesselConfig.hullPerformance.DOWNLOAD",
    DOWNLOAD_ERROR: "vesselConfig.hullPerformance.DOWNLOAD_ERROR",
    DOWNLOAD_SUCCESS: "vesselConfig.hullPerformance.DOWNLOAD_SUCCESS",
    RESET: "vesselConfig.hullPerformance.RESET",
    REQUEST_CALCULATION: "vesselConfig.hullPerformance.REQUEST_CALCULATION",
    REQUEST_CALCULATION_SUCCESS:
      "vesselConfig.hullPerformance.REQUEST_CALCULATION_SUCCESS",
    REQUEST_CALCULATION_ERROR:
      "vesselConfig.hullPerformance.REQUEST_CALCULATION_ERROR",
    RECALCULATE: "vesselConfig.hullPerformance.RECALCULATE",
    RECALCULATE_SUCCESS: "vesselConfig.hullPerformance.RECALCULATE_SUCCESS",
    RECALCULATE_ERROR: "vesselConfig.hullPerformance.RECALCULATE_ERROR",
  },
  energyFlow: {
    INITIALIZE: "vesselConfig.energyFlow.INITIALIZE",
    RESET: "vesselConfig.energyFlow.RESET",
    FETCH_LAYOUT: "vesselConfig.energyFlow.FETCH_LAYOUT",
    FETCH_LAYOUT_SUCCESS: "vesselConfig.energyFlow.FETCH_LAYOUT_SUCCESS",
    FETCH_LAYOUT_ERROR: "vesselConfig.energyFlow.FETCH_LAYOUT_ERROR",
    FETCH_ITEMS: "vesselConfig.energyFlow.FETCH_ITEMS",
    FETCH_ITEMS_SUCCESS: "vesselConfig.energyFlow.FETCH_ITEMS_SUCCESS",
    FETCH_ITEMS_ERROR: "vesselConfig.energyFlow.FETCH_ITEMS_ERROR",
    FETCH_RELATIONS: "vesselConfig.energyFlow.FETCH_RELATIONS",
    FETCH_RELATIONS_SUCCESS: "vesselConfig.energyFlow.FETCH_RELATIONS_SUCCESS",
    FETCH_RELATIONS_ERROR: "vesselConfig.energyFlow.FETCH_RELATIONS_ERROR",
    VESSELITEM_SELECTED: "vesselConfig.energyFlow.VESSELITEM_SELECTED",
    VESSELITEM_NEW_OPEN: "vesselConfig.energyFlow.VESSELITEM_NEW_OPEN",
    VESSELITEM_NEW_CLOSE: "vesselConfig.energyFlow.VESSELITEM_NEW_CLOSE",
    VESSELITEM_NEW_ADD: "vesselConfig.energyFlow.VESSELITEM_NEW_ADD",
    SAVE: "vesselConfig.energyFlow.SAVE",
    SAVE_SUCCESS: "vesselConfig.energyFlow.SAVE_SUCCESS",
    SAVE_ERROR: "vesselConfig.energyFlow.SAVE_ERROR",
    ADD_RELATION: "vesselConfig.energyFlow.ADD_RELATION",
    DELETE_RELATION: "vesselConfig.energyFlow.DELETE_RELATION",
    UPDATE_NODE: "vesselConfig.energyFlow.UPDATE_NODE",
    REMOVE_NODE: "vesselConfig.energyFlow.REMOVE_NODE",
  },
};

export const hullPerformance = {
  INITIALIZE: "hullPerformance.INITIALIZE",
  INITIALIZE_SUCCESS: "hullPerformance.INITIALIZE_SUCCESS",
  INITIALIZE_ERROR: "hullPerformance.INITIALIZE_ERROR",
  SELECT_VESSEL: "hullPerformance.SELECT_VESSEL",
  SELECT_VESSEL_SUCCESS: "hullPerformance.SELECT_VESSEL_SUCCESS",
  SELECT_VESSEL_ERROR: "hullPerformance.SELECT_VESSEL_ERROR",
  FETCH_DOCK_EVENTS: "hullPerformance.FETCH_DOCK_EVENTS",
  FETCH_DOCK_EVENTS_SUCCESS: "hullPerformance.FETCH_DOCK_EVENTS_SUCCESS",
  FETCH_DOCK_EVENTS_ERROR: "hullPerformance.FETCH_DOCK_EVENTS_ERROR",
  FETCH_PERFORMANCE_VALUES: "hullPerformance.FETCH_PERFORMANCE_VALUES",
  FETCH_PERFORMANCE_VALUES_SUCCESS:
    "hullPerformance.FETCH_PERFORMANCE_VALUES_SUCCESS",
  FETCH_PERFORMANCE_VALUES_ERROR:
    "hullPerformance.FETCH_PERFORMANCE_VALUES_ERROR",
  SET_INTERVAL: "hullPerformance.SET_INTERVAL",
  SET_INTERVAL_SUCCESS: "hullPerformance.SET_INTERVAL_SUCCESS",
  SET_INTERVAL_ERROR: "hullPerformance.SET_INTERVAL_ERROR",
  FETCH_VESSEL: "hullPerformance.FETCH_VESSEL",
  FETCH_VESSEL_SUCCESS: "hullPerformance.FETCH_VESSEL_SUCCESS",
  FETCH_VESSEL_ERROR: "hullPerformance.FETCH_VESSEL_ERROR",
};

export const admin = {
  devOps: {
    FETCH: "admin.devOps.FETCH",
    FETCH_ERROR: "admin.devOps.FETCH_ERROR",
    FETCH_SUCCESS: "admin.devOps.FETCH_SUCCESS",
    SORT_VESSELS: "admin.devOps.SORT_VESSELS",
    SET_DEVOPS_COMPANY_ID: "admin.devOps.SET_DEVOPS_COMPANY_ID",
    TOGGLE_VESSEL_INSIGHT_VESSELS: "admin.devOps.TOGGLE_VESSEL_INSIGHT_VESSELS",
    application: {
      version: {
        FETCH: "admin.devOps.application.version.FETCH",
        FETCH_ERROR: "admin.devOps.application.version.FETCH_ERROR",
        FETCH_SUCCESS: "admin.devOps.application.version.FETCH_SUCCESS",
      },
    },
    FETCH_MINIMUM_PROCESSOR_BUILD: "admin.devOps.FETCH_MINIMUM_PROCESSOR_BUILD",
    FETCH_MINIMUM_PROCESSOR_BUILD_SUCCESS:
      "admin.devOps.FETCH_MINIMUM_PROCESSOR_BUILD_SUCCESS",
    FETCH_MINIMUM_PROCESSOR_BUILD_ERROR:
      "admin.devOps.FETCH_MINIMUM_PROCESSOR_BUILD_ERROR",
  },
  dataStatus: {
    FETCH: "admin.dataStatus.FETCH",
    FETCH_ERROR: "admin.dataStatus.FETCH_ERROR",
    FETCH_SUCCESS: "admin.dataStatus.FETCH_SUCCESS",
    SORT_VESSELS: "admin.dataStatus.SORT_VESSELS",
    SET_CHECKED_ALL_VESSELS: "admin.dataStatus.SET_CHECKED_ALL_VESSELS",
    CHANGE_DATA_PAGE: "admin.dataStatus.CHANGE_DATA_PAGE",
    CHANGE_UPDATE_STATUS: "admin.dataStatus.CHANGE_UPDATE_STATUS",
    CHANGE_VESSEL_PAGE: "admin.dataStatus.CHANGE_VESSEL_PAGE",
  },
  users: {
    import: {
      OPEN: "admin.users.import.OPEN",
      CANCEL: "admin.users.import.CANCEL",
      SAVE: "admin.users.import.SAVE",
      SAVE_SUCCESS: "admin.users.import.SAVE_SUCCESS",
      SAVE_ERROR: "admin.users.import.SAVE_ERROR",
      SET_SELECTED_COMPANY: "admin.users.import.SET_SELECTED_COMPANY",
      SET_SELECTED_GROUP: "admin.users.import.SET_SELECTED_GROUP",
      SET_FILE: "admin.users.import.SET_FILE",
      SET_FILE_SUCCESS: "admin.users.import.SET_FILE_SUCCESS",
      SET_FILE_ERROR: "admin.users.import.SET_FILE_ERROR",
    },
    DEACTIVATE: "admin.users.DEACTIVATE",
    DEACTIVATE_SUCCESS: "admin.users.DEACTIVATE_SUCCESS",
    DEACTIVATE_ERROR: "admin.users.DEACTIVATE_ERROR",
    REACTIVATE: "admin.users.REACTIVATE",
    REACTIVATE_SUCCESS: "admin.users.REACTIVATE_SUCCESS",
    REACTIVATE_ERROR: "admin.users.REACTIVATE_ERROR",
    DELETE_POPUP_OPEN: "admin.users.DELETEDELETE_POPUP_OPEN",
    DELETE_POPUP_CLOSE: "admin.users.DELETEDELETE_POPUP_CLOSE",
    DELETE: "admin.users.DELETE",
    DELETE_SUCCESS: "admin.users.DELETE_SUCCESS",
    DELETE_ERROR: "admin.users.DELETE_ERROR",
    GENERATE_API_SECRET: "admin.users.GENERATE_API_SECRET",
    GENERATE_API_SECRET_SUCCESS: "admin.users.GENERATE_API_SECRET_SUCCESS",
    GENERATE_API_SECRET_ERROR: "admin.users.GENERATE_API_SECRET_ERROR",
    REQUEST_REGENERATE_API_SECRET: "admin.users.REQUEST_REGENERATE_API_SECRET",
    CONFIRM_REGENERATE_API_SECRET: "admin.users.CONFIRM_REGENERATE_API_SECRET",
    REQUEST_CLEAR_API_SECRET: "admin.users.REQUEST_CLEAR_API_SECRET",
    CONFIRM_CLEAR_API_SECRET: "admin.users.CONFIRM_CLEAR_API_SECRET",
    CLEAR_API_SECRET: "admin.users.CLEAR_API_SECRET",
    CLEAR_API_SECRET_SUCCESS: "admin.users.CLEAR_API_SECRET_SUCCESS",
    CLEAR_API_SECRET_ERROR: "admin.users.CLEAR_API_SECRET_ERROR",
    CLOSE_USER_API_SECRET_POPUP: "admin.users.CLOSE_USER_API_SECRET_POPUP",
    CLEAR_USER_ERRORS: "admin.users.CLEAR_USER_ERRORS",
    DOWNLOAD_USERS_EXAMPLE: "admin.users.DOWNLOAD_USERS_EXAMPLE",
    DOWNLOAD_USERS_EXAMPLE_ERROR: "admin.usres.DOWNLOAD_USERS_EXAMPLE_ERROR",
  },
  energyBudget: {
    FETCH_VESSELS: "admin.energyBudget.FETCH_VESSELS",
    FETCH_VESSELS_SUCCESS: "admin.energyBudget.FETCH_VESSELS_SUCCESS",
    FETCH_VESSELS_ERROR: "admin.energyBudget.FETCH_VESSELS_ERROR",
    FETCH: "admin.energyBudget.FETCH",
    FETCH_SUCCESS: "admin.energyBudget.FETCH_SUCCESS",
    FETCH_ERROR: "admin.energyBudget.FETCH_ERROR",
    INIT_MONTHS_FORM: "admin.energyBudget.INIT_MONTHS_FORM",
    SET_MONTH_VALUE: "admin.energyBudget.SET_MONTH_VALUE",
    FORMAT_MONTH_VALUE: "admin.energyBudget.FORMAT_MONTH_VALUE",
    RESET_MONTHS_FORM: "admin.energyBudget.RESET_MONTHS_FORM",
    SAVE_MONTHS_FORM: "admin.energyBudget.SAVE_MONTHS_FORM",
    SAVE_MONTHS_FORM_SUCCESS: "admin.energyBudget.SAVE_MONTHS_FORM_SUCCESS",
    SAVE_MONTHS_FORM_ERROR: "admin.energyBudget.SAVE_MONTHS_FORM_ERROR",
  },
  healthManagement: {
    FETCH_EVENTS: "admin.healthManagement.FETCH_EVENTS",
    FETCH_EVENTS_SUCCESS: "admin.healthManagement.FETCH_EVENTS_SUCCESS",
    FETCH_EVENTS_ERROR: "admin.healthManagement.FETCH_EVENTS_ERROR",
    FETCH_EVENT: "admin.healthManagement.FETCH_EVENT",
    FETCH_EVENT_SUCCESS: "admin.healthManagement.FETCH_EVENT_SUCCESS",
    FETCH_EVENT_ERROR: "admin.healthManagement.FETCH_EVENT_ERROR",
    FETCH_EVENT_DETAILS: "admin.healthmanagement.FETCH_EVENT_DETAILS",
    FETCH_EVENT_DETAILS_SUCCESS:
      "admin.healthmanagement.FETCH_EVENT_DETAILS_SUCCESS",
    FETCH_EVENT_DETAILS_ERROR:
      "admin.healthmanagement.FETCH_EVENT_DETAILS_ERROR",
    FETCH_STANDARD_TEXTS: "admin.healthmanagement.FETCH_STANDARD_TEXTS",
    UPDATE_EVENT_FIELD: "admin.healthmanagement.UPDATE_EVENT_FIELD",
    APPROVE_EVENT: "admin.healthmanagement.APPROVE_EVENT",
    APPROVE_EVENT_SUCCESS: "admin.healthmanagement.APPROVE_EVENT_SUCCESS",
    APPROVE_EVENT_ERROR: "admin.healthmanagement.APPROVE_EVENT_ERROR",
    SET_STATUS_FILTER: "admin.healthmanagement.SET_STATUS_FILTER",
    SORT_EVENTS: "admin.healthManagement.SORT_EVENTS",
    CLOSE_ERROR_POPUP: "admin.healthmanagement.CLOSE_ERROR_POPUP",
    SELECT_VESSEL: "admin.healthmanagement.SELECT_VESSEL",
  },
  cargoConfig: {
    RESET: "RESET",
    INIT: "INIT",
    FETCH_VESSELS: "admin.cargoConfig.FETCH_VESSELS",
    FETCH_VESSELS_SUCCESS: "admin.cargoConfig.FETCH_VESSELS_SUCCESS",
    FETCH_VESSELS_ERROR: "admin.cargoConfig.FETCH_VESSELS_ERROR",
    FETCH_CARGO: "admin.cargoConfig.FETCH_CARGO",
    FETCH_CARGO_SUCCESS: "admin.cargoConfig.FETCH_CARGO_SUCCESS",
    FETCH_CARGO_ERROR: "admin.cargoConfig.FETCH_CARGO_ERROR",
    SAVE_CARGO: "admin.cargoConfig.SAVE_CARGO",
    SAVE_CARGO_ERROR: "admin.cargoConfig.SAVE_CARGO_ERROR",
    UPDATE_CARGO: "admin.cargoConfig.UPDATE_CARGO",
  },
  dataMaintenance: {
    FETCH_VESSELS: "admin.dataMaintenance.FETCH_VESSELS",
    FETCH_VESSELS_SUCCESS: "admin.dataMaintenance.FETCH_VESSELS_SUCCESS",
    FETCH_VESSELS_ERROR: "admin.dataMaintenance.FETCH_VESSELS_ERROR",
    FETCH_DATA_COUNT: "admin.dataMaintenance.FETCH_DATA_COUNT",
    FETCH_DATA_COUNT_SUCCESS: "admin.dataMaintenance.FETCH_DATA_COUNT_SUCCESS",
    FETCH_DATA_COUNT_ERROR: "admin.dataMaintenance.FETCH_DATA_COUNT_ERROR",
    DELETE_DATA: "admin.dataMaintenance.DELETE_DATA",
    DELETE_DATA_SUCCESS: "admin.dataMaintenance.DELETE_DATA_SUCCESS",
    DELETE_DATA_ERROR: "admin.dataMaintenance.DELETE_DATA_ERROR",
  },
  vessels: {
    sensorReplication: {
      CONFIGURE: "admin.vessels.sensorReplication.CONFIGURE",
      CONFIGURE_CLOSE: "admin.vessels.sensorReplication.CONFIGURE_CLOSE",
      SAVE: "admin.vessels.sensorReplication.SAVE",
      SAVE_SUCCESS: "admin.vessels.sensorReplication.SAVE_SUCCESS",
      SAVE_ERROR: "admin.vessels.sensorReplication.SAVE_ERROR",
      SET_VALUE: "admin.vessels.sensorReplication.SET_VALUE",
    },
    remoteControl: {
      acknowledgeActionFailure: {
        SAVE: "admin.vessels.configureRemoteControl.acknowledgeActionFailure.SAVE",
        SAVE_ERROR:
          "admin.vessels.configureRemoteControl.acknowledgeActionFailure.SAVE_ERROR",
        SAVE_SUCCESS:
          "admin.vessels.configureRemoteControl.acknowledgeActionFailure.SAVE_SUCCESS",
      },
      remoteAccess: {
        REVOKE: "admin.vessels.remoteControl.remoteAccess.REVOKE",
        REVOKE_SUCCESS: "admin.vessels.remoteControl.remoteAccess.REVOKE_ERROR",
        REVOKE_ERROR: "admin.vessels.remoteControl.remoteAccess.REVOKE_SUCCESS",
        SAVE: "admin.vessels.remoteControl.remoteAccess.SAVE",
        SAVE_ERROR: "admin.vessels.remoteControl.remoteAccess.SAVE_ERROR",
        SAVE_SUCCESS: "admin.vessels.remoteControl.remoteAccess.SAVE_SUCCESS",
        SET_VALUE: "admin.vessels.remoteControl.remoteAccess.SET_VALUE",
      },
      updateSystem: {
        SAVE: "admin.vessels.remoteControl.updateSystem.SAVE",
        SAVE_ERROR: "admin.vessels.remoteControl.updateSystem.SAVE_ERROR",
        SAVE_SUCCESS: "admin.vessels.remoteControl.updateSystem.SAVE_SUCCESS",
      },
      updateTargets: {
        FETCH: "admin.vessels.remoteControl.updateTargets.FETCH",
        FETCH_ERROR: "admin.vessels.remoteControl.updateTargets.FETCH_ERROR",
        FETCH_SUCCESS:
          "admin.vessels.remoteControl.updateTargets.FETCH_SUCCESS",
      },
      CHANGE_REMOTE_CONTROL_TYPE:
        "admin.vessels.remoteControl.CHANGE_REMOTE_CONTROL_TYPE",
    },
  },
  modules: {
    FETCH: "admin.modules.FETCH",
    FETCH_ERROR: "admin.modules.FETCH_ERROR",
    FETCH_SUCCESS: "admin.modules.FETCH_SUCCESS",
  },
  itemConfig: {
    FETCH_CONTEXT_DATA: "admin.itemConfig.FETCH_CONTEXT_DATA",
    FETCH_CONTEXT_DATA_SUCCESS: "admin.itemConfig.FETCH_CONTEXT_DATA_SUCCESS",
    FETCH_CONTEXT_DATA_ERROR: "admin.itemConfig.FETCH_CONTEXT_DATA_ERROR",
    SORT_ITEM_TYPES: "admin.itemConfig.SORT_ITEM_TYPES",
    FETCH_ITEM_CONFIG: "admin.itemConfig.FETCH_ITEM_CONFIG",
    FETCH_ITEM_CONFIG_SUCCESS: "admin.itemConfig.FETCH_ITEM_CONFIG_SUCCESS",
    FETCH_ITEM_CONFIG_ERROR: "admin.itemConfig.FETCH_ITEM_CONFIG_ERROR",
    SET_FORM_VALUE: "admin.itemConfig.SET_FORM_VALUE",
    SAVE_ITEM_CONFIG: "admin.itemConfig.SAVE_ITEM_CONFIG",
    SAVE_ITEM_CONFIG_SUCCESS: "admin.itemConfig.SAVE_ITEM_CONFIG_SUCCESS",
    SAVE_ITEM_CONFIG_ERROR: "admin.itemConfig.SAVE_ITEM_CONFIG_ERROR",
    CANCEL_EDIT: "admin.itemConfig.CANCEL_EDIT",
  },
  telemetry: {
    FETCH_ADMIN_TELEMETRY: "FETCH_ADMIN_TELEMETRY",
    FETCH_ADMIN_TELEMETRY_SUCCESS: "FETCH_ADMIN_TELEMETRY_SUCCESS",
    FETCH_ADMIN_TELEMETRY_ERROR: "FETCH_ADMIN_TELEMETRY_ERROR",
    ADMIN_TELEMETRY_SORT: "ADMIN_TELEMETRY_SORT",
  },
};

export const pageLayoutConfiguration = {
  CREATE_GRID_LAYOUT: "CREATE_GRID_LAYOUT",
  UPDATE_GRID_LAYOUT: "UPDATE_GRID_LAYOUT",
  UPDATE_ROWS_COUNT: "UPDATE_ROWS_COUNT",
  CLEAR_PAGE_LAYOUT_CONFIGURATION: "CLEAR_PAGE_LAYOUT_CONFIGURATION",
  OPEN_SETTINGS_DIALOG: "OPEN_SETTINGS_DIALOG",
  CLOSE_SETTINGS_DIALOG: "CLOSE_SETTINGS_DIALOG",
  UPDATE_ROWS_CONFIG: "UPDATE_ROWS_CONFIG",
};
