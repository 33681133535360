export const WIDGET_INFO_TEXT = {
  FUEL_CONSUMPTION: {
    title: "Fuel Consumption",
    info: "Average fuel consumption in tons per hour per operation group for the selected fuel type. The percentage shows the average fuel usage in the selected time period compared against the previous time period. Trend line includes both the selected time period and the previous time period (February and January e.g.).",
  },
  EMISSION_TRENDS: {
    title: "Emissions trend",
    info: "Total CO2 and NOx in tons per month for the past six months.",
  },
  FUEL_AND_TARGET: {
    title: "Fuel & Target",
    info: "Total running hours per operational mode for the selected time period, the average fuel consumption in tons per hour and the target average fuel consumption (if configured). A red or green indicator on the actual fuel usage will be displayed indicating if the actual fuel usage is above or below the target fuel usage.",
  },
  EMISSIONS: {
    title: "Emissions",
    info: "Total CO2, NOx and SOx per operational mode calculated by the fuel consumption data.",
  },
  DATA_PER_OPERATION: {
    title: "Data per operation",
    info: "The run, CO2 and NOx donuts shows the total run hours, CO2 emissions and NOx emissions for each operation for the vessel in the selected period. Clicking one or more operation icons below the donuts will filter on the selected operations.",
  },
};
