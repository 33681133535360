import {
  operationClassIDSort,
  operationClassLegend,
} from "../../../common/operation/operationClassID";
import {
  operationIdSort,
  operationLegend,
} from "../../../common/operation/operationId";

// This is a hack to get the operationId from the legend.
// For some reason the legend is received from the backend as a unique identifier,
// even though the id should be used for that purpose.
// Therefore, this component will break if the legends change.
export const sortByOperationLegend = <T>(
  array: T[],
  operationLegendSelector: (item: T) => string
) => {
  return [...array].sort((a, b) => {
    const aOperationLegend = operationLegendSelector(a);
    const aOperationId = getOperationFromLegend(
      aOperationLegend,
      operationLegend
    );
    const bOperationLegend = operationLegendSelector(b);
    const bOperationId = getOperationFromLegend(
      bOperationLegend,
      operationLegend
    );
    return operationIdSort(aOperationId, bOperationId);
  });
};

export const sortByOperationClass = <T>(
  array: T[],
  operationLegendSelector: (item: T) => string
) => {
  return [...array].sort((a, b) => {
    const aOperationLegend = operationLegendSelector(a);
    const aOperationClass = getOperationFromLegend(
      aOperationLegend,
      operationClassLegend
    );
    const bOperationLegend = operationLegendSelector(b);
    const bOperationClass = getOperationFromLegend(
      bOperationLegend,
      operationClassLegend
    );
    return operationClassIDSort(aOperationClass, bOperationClass);
  });
};

export const getOperationFromLegend = <T extends number>(
  legend: string,
  obj: Record<T, string>
): T =>
  Number(
    Object.entries(obj)
      .find(([, value]) => value === legend)
      ?.at(0)
  ) as T;
