import { isNil, isFinite } from "lodash";
import React from "react";
import AutoSizer from "../../../common/components/AutoSizer/AutoSizer";
import { color } from "../../../common/colors";
import Sparkline from "../../../common/components/Sparkline/Sparkline";
import SparklineCustomElement from "../../../common/components/Sparkline/SparklineCustomElement";
import SparklineHover from "../../../common/components/Sparkline/SparklineHover";
import SparklineXAxis from "../../../common/components/Sparkline/SparklineXAxis";
import { COSMETIC_CONSTS } from "../../config";

const DATA = [[]];
const DOMAIN_Y = [0, 0];
const MARGIN = { bottom: 30, left: 30 };
const LABEL_OFFSET = 40;

export default class TrendXAxis extends React.PureComponent {
  render() {
    const { xDomain, tickData, minWidth, hoverContext, timeOffset } =
      this.props;
    const { xTicks, xTickFormat, activeTickFormat } = tickData;

    return (
      <AutoSizer>
        {({ width }) => (
          <Sparkline
            data={DATA}
            width={Math.max(minWidth, width)}
            height={60}
            xDomain={xDomain}
            yDomain={DOMAIN_Y}
            scaleX={tickData.scaleX.range([0, Math.max(minWidth, width)])}
            margin={MARGIN}
          >
            <SparklineHover context={hoverContext}>
              <SparklineXAxis
                ticks={xTicks}
                tickFormat={xTickFormat}
                timeOffset={timeOffset}
              />
              <SparklineCustomElement>
                {({ scaleX, hoveredXValue }) => {
                  if (isNil(hoveredXValue)) {
                    return null;
                  }
                  const x = scaleX(hoveredXValue);
                  if (!isFinite(x)) {
                    return null;
                  }
                  return (
                    <g>
                      <line
                        style={{
                          fill: "none",
                          stroke: color("--blue-base"),
                          strokeWidth: 1,
                        }}
                        x1={x}
                        y1={1}
                        x2={x}
                        y2={10}
                      />
                      <text
                        x={
                          x > scaleX.range()[1] - LABEL_OFFSET
                            ? x - LABEL_OFFSET
                            : x
                        }
                        y={20}
                        textAnchor="middle"
                        dominantBaseline="hanging"
                        fontFamily={COSMETIC_CONSTS.fontFamily}
                        fontSize={14}
                        fill={color("--blue-base")}
                      >
                        <tspan>{activeTickFormat(hoveredXValue)}</tspan>
                      </text>
                    </g>
                  );
                }}
              </SparklineCustomElement>
            </SparklineHover>
          </Sparkline>
        )}
      </AutoSizer>
    );
  }
}
