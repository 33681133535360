import createReducer from "../../createReducer";
import { vesselConfig } from "../../../actions/action.types";
import { startCase, get, isEqual, find, each } from "lodash";
import { getBase64 } from "../../../common/fileConverter";
import "regenerator-runtime";

export const NEW_DASHBOARD_PAGENAME = "dashboardNew";
export const DASHBOARD_CONFIG = {
  dashboardNewConfig: "dashboardNewConfig",
  dashboardConfig: "dashboardConfig",
};

export default createReducer(
  {},
  {
    [vesselConfig.onboardFrontendConfig.FETCH]: () => ({
      isLoading: true,
      isNewDashboard: false,
    }),
    [vesselConfig.onboardFrontendConfig.FETCH_SUCCESS]: (_state, { data }) => ({
      data,
      fields: getMenuConfigFields(data),
      dashboard: getDashboardConfig(
        data,
        data.frontendConfig?.pages?.dashboardNew
      ),
      vesselImage: { src: data.imageUrl },
      isNewDashboard: !!Object.entries(data.frontendConfig.pages ?? {}).find(
        ([key, value]) => key === NEW_DASHBOARD_PAGENAME && value
      ),
    }),
    [vesselConfig.onboardFrontendConfig.FETCH_ERROR]: (_state, { error }) => ({
      error: get(error, "message", error),
    }),
    [vesselConfig.onboardFrontendConfig.INPUT_CHANGED]: (
      state,
      { field, value }
    ) => ({
      ...state,
      fields: state.fields.map((f) => (f.name !== field ? f : { ...f, value })),
      ...(field === NEW_DASHBOARD_PAGENAME && {
        dashboard: getDashboardConfig(state.data, value),
      }),
      ...(field === NEW_DASHBOARD_PAGENAME && {
        isNewDashboard: field === NEW_DASHBOARD_PAGENAME && value,
      }),
    }),
    [vesselConfig.onboardFrontendConfig.DASHBOARD_INPUT_CHANGED]: (
      state,
      { row, column, component }
    ) => ({
      ...state,
      dashboard: Object.assign([...state.dashboard], {
        [row]: Object.assign([...state.dashboard[row]], {
          [column]: { value: component.name, label: component.label },
        }),
      }),
    }),
    [vesselConfig.onboardFrontendConfig.VESSEL_IMAGE_CHANGED]: (
      state,
      { file }
    ) => ({
      ...state,
      vesselImage: {
        file,
        fileName: file.name,
      },
    }),
    [vesselConfig.onboardFrontendConfig.VESSEL_IMAGE_CHANGED_ERROR]: (
      state,
      { errors }
    ) => ({
      ...state,
      vesselImageErrors: errors,
    }),
    [vesselConfig.onboardFrontendConfig.SAVE]: (state) => ({
      ...state,
      isSaving: true,
      error: null,
    }),
    [vesselConfig.onboardFrontendConfig.SAVE_SUCCESS]: (_state, { data }) => ({
      data,
      isSaving: false,
      fields: getMenuConfigFields(data),
      dashboard: getDashboardConfig(
        data,
        data.frontendConfig?.pages?.dashboardNew
      ),
      vesselImage: { src: data.imageUrl },
      isNewDashboard: !!Object.entries(data.frontendConfig.pages ?? {}).find(
        ([key, value]) => key === NEW_DASHBOARD_PAGENAME && value
      ),
    }),
    [vesselConfig.onboardFrontendConfig.SAVE_ERROR]: (state, { error }) => ({
      ...state,
      isSaving: false,
      error: get(error, "message", error),
    }),
    [vesselConfig.onboardFrontendConfig.DOWNLOAD]: (state) => ({
      ...state,
      isDownloading: true,
      error: null,
    }),
    [vesselConfig.onboardFrontendConfig.DOWNLOAD_SUCCESS]: (state) => ({
      ...state,
      isDownloading: false,
    }),
    [vesselConfig.onboardFrontendConfig.DOWNLOAD_ERROR]: (
      state,
      { error }
    ) => ({
      ...state,
      isDownloading: false,
      error: get(error, "message", error),
    }),
    [vesselConfig.onboardFrontendConfig.DEPLOY]: (state) => ({
      ...state,
      isDeploying: true,
      error: null,
    }),
    [vesselConfig.onboardFrontendConfig.DEPLOY_SUCCESS]: (
      _state,
      { data }
    ) => ({
      data,
      fields: getMenuConfigFields(data),
      dashboard: getDashboardConfig(
        data,
        data.frontendConfig?.pages?.dashboardNew
      ),
      isNewDashboard: !!Object.entries(data.frontendConfig.pages ?? {}).find(
        ([key, value]) => key === NEW_DASHBOARD_PAGENAME && value
      ),
    }),
    [vesselConfig.onboardFrontendConfig.DEPLOY_ERROR]: (state, { error }) => ({
      ...state,
      isDeploying: true,
      error: get(error, "message", error),
    }),
    [vesselConfig.onboardFrontendConfig.SHOW_VESSEL_IMAGE_PREVIEW]: (
      state
    ) => ({
      ...state,
      showPopup: true,
    }),
    [vesselConfig.onboardFrontendConfig.HIDE_VESSEL_IMAGE_PREVIEW]: (
      state
    ) => ({
      ...state,
      showPopup: false,
    }),
  }
);

export const getMenuConfigFields = ({ frontendConfig } = {}) => {
  const pageLabels = {
    elbalance: "El Balance",
    energyflow: "Energy Flow",
    dashboardNew: "New Dashboard",
  };

  if (frontendConfig !== undefined && frontendConfig.pages !== undefined) {
    let sortedPages = {};
    Object.keys(frontendConfig.pages)
      .sort()
      .forEach((key) => {
        sortedPages[key] = frontendConfig.pages[key];
      });

    return Object.entries(sortedPages).map((page) => ({
      name: page[0],
      value: page[1],
      label: get(pageLabels, page[0], startCase(page[0])),
    }));
  }
  return [];
};

export const getDashboardConfig = ({ frontendConfig } = {}, isNewDashboard) => {
  const dashboardConfigProp = isNewDashboard
    ? DASHBOARD_CONFIG.dashboardNewConfig
    : DASHBOARD_CONFIG.dashboardConfig;
  if (
    frontendConfig !== undefined &&
    frontendConfig[dashboardConfigProp] !== undefined
  ) {
    const { rows } = frontendConfig[dashboardConfigProp];
    rows && rows[0]?.length === 4 && rows[0].unshift("operation");
    rows && rows[1]?.length === 4 && rows[1].unshift("spacer");

    return (rows ?? []).map((row) => {
      return row.map((component) => ({
        value: component,
        label: find(componentLabels, ["name", component]).label,
      }));
    });
  }
  return [];
};

const componentLabels = [
  { name: "spacer", label: "Spacer" },
  { name: "sailingPerformance", label: "Sailing Performance" },
  { name: "speedOverGround", label: "Speed Over Ground" },
  { name: "passivePerformance", label: "Passive Performance" },
  { name: "dashboardPower", label: "Power" },
  { name: "voyageInformation", label: "Voyage" },
  { name: "operatingCost", label: "Operating Cost" },
  { name: "legInformation", label: "Leg" },
  { name: "weather", label: "Weather" },
  { name: "waveRadar", label: "Wave Radar" },
  { name: "operation", label: "Operation" },
  { name: "restLoad", label: "Rest Load" },
];

export const prepSave = async (
  data,
  fields,
  dashboard,
  vesselImage,
  configVersion,
  isNewDashboard
) => {
  let vesselImageBase64 = await getBase64(vesselImage.file);
  let config = {
    frontendConfig: {
      pages: {},
      dashboardConfig: { ...data.frontendConfig.dashboardConfig },
      dashboardNewConfig: { ...data.frontendConfig.dashboardNewConfig },
    },
    vesselImageBase64,
    vesselImageUrl: vesselImage.src,
    configVersion,
  };

  each(fields, (field) => {
    config.frontendConfig.pages[field.name] = field.value;
  });

  const dashboardProp = isNewDashboard
    ? DASHBOARD_CONFIG.dashboardNewConfig
    : DASHBOARD_CONFIG.dashboardConfig;

  config.frontendConfig[dashboardProp].rows = dashboard.map((row) => {
    return row.map((component) => component.value);
  });

  return config;
};

export const prepState = (state) => {
  const {
    data,
    fields,
    dashboard,
    isSaving,
    isDeploying,
    isLoading,
    error,
    vesselImage,
    vesselImageErrors,
    configVersion,
    isNewDashboard,
  } = state;
  let hasChanges = fields && !isEqual(getMenuConfigFields(data), fields);
  hasChanges =
    hasChanges ||
    (dashboard &&
      !isEqual(getDashboardConfig(data, isNewDashboard), dashboard));
  hasChanges = hasChanges || vesselImage?.file;
  const savedOn = data && (data.modifiedOn || data.createdOn);
  return {
    data,
    componentLabels,
    dashboard,
    fields,
    error,
    isLoading,
    savedOn,
    deployedOn: data?.deployedOn,
    canSave:
      !isSaving && (!savedOn || hasChanges) && !vesselImageErrors?.length,
    canDownload: !isSaving && !hasChanges && Boolean(savedOn),
    canDeploy: !isDeploying && !isSaving && !hasChanges && Boolean(savedOn),
    vesselImage,
    vesselImageErrors,
    configVersion,
    isNewDashboard,
  };
};
