import PropTypes from "prop-types";
import React from "react";
import moment from "moment";
import styles from "./Calendar.css";
import { createCalendarMonth } from "../../common/calendar";
import { isNil } from "lodash";
import ContextMenu from "../ContextMenu/ContextMenu";
import Button from "../../../common/components/Button";

const propTypes = {
  selectedMonth: PropTypes.number,
  selectedYear: PropTypes.number,
  tags: PropTypes.array,
  addEvent: PropTypes.func,
  addManualFuelData: PropTypes.func,
};

const defaultProps = {
  selectedMonth: moment().month(),
  selectedYear: moment().year(),
  tags: [],
};

class Calendar extends React.Component {
  state = {
    isClicked: false,
  };
  constructor(props) {
    super(props);
  }
  dayMountActions = [];
  options = [
    { id: 0, text: "Events", onClick: () => this.props.addEvent() },
    ...(this.props.useManualFuelDataOnly === true
      ? [
          {
            id: 1,
            text: "Manual Fuel Data",
            onClick: () => this.props.addManualFuelData(this.state.date),
          },
        ]
      : []),
  ];

  tooltip = [{ id: 1, text: "Add fuel details manually", onClick: () => {} }];

  doDayActions() {
    this.dayMountActions.forEach((a) => a());
  }

  componentDidMount() {
    this.doDayActions();
    window.addEventListener("click", this.handleTargetClick);
  }
  componentWillUnmount() {
    window.removeEventListener("click", this.handleTargetClick);
  }
  componentDidUpdate() {
    this.doDayActions();
  }
  renderList = (buttonRef, date) => {
    this.setState({
      isClicked: true,
      hover: false,
      buttonRef: buttonRef.current,
      date: date,
    });
  };
  handleTargetClick = () => {
    this.setState((prevState) => ({ ...prevState, isClicked: false }));
  };
  handleMouseEnter = (buttonRef) => {
    if (this.state.buttonRef === buttonRef.current && this.state.isClicked) {
      return;
    }
    this.setState({
      hover: true,
      buttonRef: buttonRef.current,
      isClicked: false,
    });
  };

  handleMouseLeave = () => {
    this.setState({
      hover: false,
    });
  };

  renderTags(tags) {
    return (
      <div className={styles.calendarTagContainer}>
        {tags.map((tag, tagIndex) => {
          return (
            <div
              className={styles.calendarTag}
              style={{
                borderColor: tag.color,
                cursor: !isNil(tag.onClick) ? "pointer" : "auto",
              }}
              key={tagIndex}
              onClick={tag.onClick}
            >
              <div className={styles.calendarTagHeader}>{tag.header}</div>
              <div className={styles.calendarTagText}>{tag.text}</div>
            </div>
          );
        })}
      </div>
    );
  }

  renderAddButton(tagsForDay, addButton, day, weeksLength, isCurrentMonth) {
    if (!isCurrentMonth) return;
    let bigButtonSize = weeksLength > 5 ? "70" : "80";
    return tagsForDay.length !== 0 ? (
      <div
        className={styles.addButtonSmall}
        ref={addButton}
        id={`${day.day} ${day.monthShort}`}
        onMouseEnter={() => this.handleMouseEnter(addButton)}
        onMouseLeave={() => this.handleMouseLeave(addButton)}
        onClick={(event) => {
          event.stopPropagation();
          this.renderList(addButton, day.date);
        }}
      >
        <Button
          width={"20px"}
          height={"20px"}
          value={
            <div className={styles.plusContainer}>
              <div className={styles.plusVertical} data-type={"small"}></div>
              <div className={styles.plusHorisontal} data-type={"small"}></div>
            </div>
          }
          type={"verySmallButton"}
          backgroundColor={"#3b4859"}
        />
      </div>
    ) : (
      <div
        className={styles.addButton}
        style={{
          width: bigButtonSize + "px",
          height: bigButtonSize + "px",
        }}
        ref={addButton}
        id={`${day.day} ${day.monthShort}`}
        onMouseEnter={() => this.handleMouseEnter(addButton)}
        onMouseLeave={() => this.handleMouseLeave(addButton)}
        onClick={(event) => {
          event.stopPropagation();
          this.renderList(addButton, day.date);
        }}
      >
        <Button
          circle={true}
          width={bigButtonSize}
          value={
            <div className={styles.plusContainer}>
              <div className={styles.plusVertical} data-type={"big"}></div>
              <div className={styles.plusHorisontal} data-type={"big"}></div>
            </div>
          }
          type={"dashed"}
          borderColor={"#3b4859"}
        />
      </div>
    );
  }

  renderDay(day, dayIdx, currentMonth, tags, weeksLength) {
    let isCurrentMonth = day.month === currentMonth;
    let isCurrentDay =
      moment
        .utc(day.date)
        .startOf("day")
        .diff(moment.utc().startOf("day"), "day") === 0;
    let tagsForDay = tags.filter((tag) => {
      return (
        moment
          .utc(day.date)
          .startOf("day")
          .diff(moment.utc(tag.date).startOf("day"), "day") === 0
      );
    });
    const dayRef = React.createRef();
    const addButton = React.createRef();
    const getCalendarDayIndicator = () =>
      isCurrentDay
        ? styles.calendarDayIndicatorCurrent
        : styles.calendarDayIndicator;
    return (
      <div key={dayIdx} className={styles.calendarDayContainer}>
        <div ref={dayRef}>
          <div
            className={
              isCurrentMonth
                ? getCalendarDayIndicator()
                : styles.calendarDayIndicatorDisabled
            }
          >
            <div className={styles.dayHeading}>
              {`${day.day} ${day.monthShort}`}
            </div>
          </div>
          {this.renderAddButton(
            tagsForDay,
            addButton,
            day,
            weeksLength,
            isCurrentMonth
          )}
          {this.renderTags(tagsForDay)}
        </div>
      </div>
    );
  }

  renderHeader() {
    const days = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ];
    return (
      <div className={styles.calendarHeader}>
        {days.map((day, dayIdx) => {
          return (
            <div key={dayIdx} className={styles.calendarHeaderDay}>
              <span>{day}</span>
            </div>
          );
        })}
      </div>
    );
  }

  renderCalendarGrid() {
    let { selectedMonth, selectedYear, tags } = this.props;
    let calendarMonth = createCalendarMonth(selectedYear, selectedMonth);
    let weeksLength = calendarMonth.weeks.length;
    return (
      <div className={styles.calendarDays}>
        {calendarMonth.weeks.map((week, rowIdx) => (
          <div key={rowIdx} className={styles.calendarDaysRow}>
            {week.days.map((day, dayIdx) =>
              this.renderDay(day, dayIdx, selectedMonth, tags, weeksLength)
            )}
          </div>
        ))}
      </div>
    );
  }

  render() {
    return (
      <div className={styles.container}>
        {this.renderHeader()}
        {this.renderCalendarGrid()}
        <div id={"calendarPopupContainer"} style={{ position: "absolute" }} />
        <div id={"test"} />
        <ContextMenu
          isVisible={this.state.isClicked}
          options={this.options}
          buttonRef={this.state.buttonRef}
          modalTargetId={"test"}
          width={200}
          height={40 * this.options.length}
        />
        <ContextMenu
          isVisible={this.state.hover}
          options={this.tooltip}
          buttonRef={this.state.buttonRef}
          modalTargetId={"test"}
          width={133}
          height={59}
          menuClassname={styles.hoverContextMenu}
        />
      </div>
    );
  }
}

Calendar.propTypes = propTypes;
Calendar.defaultProps = defaultProps;

export default Calendar;
