import React, { lazy, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Dashboard from "./views/Dashboard/Dashboard";
import AuthCallback from "./authentication/AuthCallback";
import SilentLoginView from "./authentication/SilentLoginView";
import LoggedOut from "./views/Auth/LoggedOut";
import EnsureLoggedIn from "./views/Auth/EnsureLoggedIn";
import NotFound from "./views/NotFound/NotFound";
import Vessel from "./views/Vessel/Vessel";
import Compare from "./views/Compare/Compare";
import Fleet from "./views/Fleet/Fleet";
import MapView from "./views/Map/Map";
import Trend from "./views/Trend/Trend";
import CalendarView from "./views/Calendar/CalendarView";
import FuelEmissionsView from "./views/FuelEmissions/FuelAndEmissionsView";
import UserProfileView from "./views/UserProfile/UserProfileView";
import DocumentsView from "./views/Documents/DocumentsView";
import MachineryReportView from "./views/Reports/MachineryReport/MachineryReportView";
import { LayoutContainer } from "./views/LayoutContainer";
import LoadingPage from "./views/LoadingPage/LoadingPage";
import HullPerformanceView from "./views/HullPerformance/HullPerformanceView";
import AnalysisView from "./views/Analysis/AnalysisView";
import { useQueryContext } from "./providers/QueryContextProvider";
import EnsureAccess from "./components/EnsureAccess/EnsureAccess";
import { Unauthorized } from "./authentication/Unauthorized";
import { PreliminaryLoginView } from "./authentication/PreliminaryLoginView";
import MainDashboard from "./views/MainDashboard/MainDashboard";
import { FleetPerformance } from "./views/FleetPerformance/FleetPerformance";
import { MockUserData } from "./mockServer/components/MockUserData";
import { useRootContext } from "./index";

export function RoutesOnshore() {
  const queryContext = useQueryContext();
  const { authService, apiClient } = useRootContext();

  return (
    <LayoutContainer queryContext={queryContext}>
      <Suspense fallback={<LoadingPage />}>
        <Routes>
          <Route
            path="/"
            exact
            element={
              <EnsureLoggedInAndHasAccess>
                <Dashboard />
              </EnsureLoggedInAndHasAccess>
            }
          />
          <Route path="/auth" element={<AuthCallback />} />
          <Route
            path="/authPrelim"
            element={
              <PreliminaryLoginView
                apiClient={apiClient}
                authService={authService}
              />
            }
          />
          <Route
            path="/unauthorized"
            element={<Unauthorized authService={authService} />}
          />
          <Route path="/signin-silent" element={<SilentLoginView />} />
          <Route path="/loggedOut" element={<LoggedOut />} />
          <Route
            path="/vessel"
            element={
              <EnsureLoggedInAndHasAccess>
                <Vessel />
              </EnsureLoggedInAndHasAccess>
            }
          />
          <Route
            path="/compare"
            element={
              <EnsureLoggedInAndHasAccess>
                <Compare queryContext={queryContext} />
              </EnsureLoggedInAndHasAccess>
            }
          />
          <Route
            path="/fleet"
            element={
              <EnsureLoggedInAndHasAccess>
                <Fleet queryContext={queryContext} />
              </EnsureLoggedInAndHasAccess>
            }
          />
          <Route
            path="/map"
            element={
              <EnsureLoggedInAndHasAccess>
                <MapView queryContext={queryContext} />
              </EnsureLoggedInAndHasAccess>
            }
          />
          <Route
            path="/trend"
            element={
              <EnsureLoggedInAndHasAccess>
                <Trend queryContext={queryContext} />
              </EnsureLoggedInAndHasAccess>
            }
          />
          <Route
            path="/calendar"
            element={
              <EnsureLoggedInAndHasAccess>
                <CalendarView queryContext={queryContext} />
              </EnsureLoggedInAndHasAccess>
            }
          />
          <Route
            path="/fuelEmissions"
            element={
              <EnsureLoggedInAndHasAccess>
                <FuelEmissionsView />
              </EnsureLoggedInAndHasAccess>
            }
          />
          <Route
            path="/analysis"
            element={
              <EnsureLoggedInAndHasAccess>
                <AnalysisView queryContext={queryContext} />
              </EnsureLoggedInAndHasAccess>
            }
          />
          <Route
            path="/hullPerformance"
            element={
              <EnsureLoggedInAndHasAccess>
                <HullPerformanceView queryContext={queryContext} />
              </EnsureLoggedInAndHasAccess>
            }
          />
          <Route
            path="/userProfile"
            element={
              <EnsureLoggedInAndHasAccess>
                <UserProfileView queryContext={queryContext} />
              </EnsureLoggedInAndHasAccess>
            }
          />
          <Route
            path="/documents"
            element={
              <EnsureLoggedInAndHasAccess>
                <DocumentsView queryContext={queryContext} />
              </EnsureLoggedInAndHasAccess>
            }
          />
          <Route
            path="/reports"
            element={<Navigate to={{ pathname: "/reports/machinery" }} />}
          />
          <Route
            path="/reports/machinery"
            element={
              <EnsureLoggedInAndHasAccess>
                <MachineryReportView queryContext={queryContext} />
              </EnsureLoggedInAndHasAccess>
            }
          />
          <Route
            path="/admin/*"
            element={
              <EnsureLoggedInAndHasAccess>
                <AdminViewLazy queryContext={queryContext} />
              </EnsureLoggedInAndHasAccess>
            }
          />
          <Route
            path="/mainDashboard"
            element={
              <EnsureLoggedInAndHasAccess>
                <MainDashboard />
              </EnsureLoggedInAndHasAccess>
            }
          />

          <Route
            path="/fleetPerformance"
            element={
              <EnsureLoggedInAndHasAccess>
                <FleetPerformance />
              </EnsureLoggedInAndHasAccess>
            }
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Suspense>
    </LayoutContainer>
  );
}

const EnsureLoggedInAndHasAccess = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { appConfig } = useRootContext();
  return appConfig.MOCK_ENABLED === "true" ? (
    <MockUserData>{children}</MockUserData>
  ) : (
    <EnsureLoggedIn>
      <EnsureAccess>{children}</EnsureAccess>
    </EnsureLoggedIn>
  );
};

const AdminViewLazy = lazy(() => import("./views/Admin"));
