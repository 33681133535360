import React from "react";
import styles from "./FuelConsumption.css";
import fuelEmissionsStyle from "../FuelEmissions.css";
import FuelConsumptionRow from "./FuelConsumptionRow";
import Loader from "../../../../common/components/Loader";
import { IFuelConsumptionState } from "../commonTypes";
import { sortByOperationClass } from "../sortByOperationLegend";

interface Props {
  fuelConsumption: IFuelConsumptionState;
}

export function FuelConsumption({ fuelConsumption }: Props): JSX.Element {
  const consumptions = fuelConsumption.selected
    ? sortByOperationClass(
        fuelConsumption.selected,
        (item) => item.operationsGroup
      )
    : [];
  return fuelConsumption.isLoading ? (
    <div className={fuelEmissionsStyle.girdItemLoader}>
      <Loader text="Loading fuel consumption details" />
    </div>
  ) : (
    <div className={styles.fuelConsumptionContainer}>
      {consumptions.map((data) => (
        <FuelConsumptionRow fuelConsumption={data} key={data.operationsGroup} />
      ))}
    </div>
  );
}

export default FuelConsumption;
