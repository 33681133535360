import React from "react";
import PropTypes from "prop-types";
import styles from "./DataGrid.css";
import {
  Column,
  defaultTableHeaderRenderer,
  defaultTableRowRenderer,
  SortDirection,
  Table,
} from "react-virtualized";
import AutoSizer from "../../../common/components/AutoSizer/AutoSizer";
import classNames from "../../../common/classNames";

class DataGrid extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};

    this.handleRowClick = this.handleRowClick.bind(this);
    this.renderRow = this.renderRow.bind(this);
    this.calcRowHeight = this.calcRowHeight.bind(this);
    this.sort = this.sort.bind(this);
    this.renderColumn = this.renderColumn.bind(this);
    this.renderHeader = this.renderHeader.bind(this);
  }

  componentDidUpdate(_, oldState) {
    if (oldState.editIndex !== this.state.editIndex) {
      this.table.recomputeRowHeights(
        Math.max(
          Math.min(
            this.state.editIndex - 1,
            oldState.editIndex >= 0 ? oldState.editIndex - 1 : Number.MAX_VALUE
          ),
          0
        )
      );
    }
  }

  handleRowClick({ index: rowIndex, rowData }) {
    const { onRowClicked } = this.props;
    const { editIndex } = this.state;
    if (editIndex !== rowIndex) {
      if (onRowClicked) {
        onRowClicked(rowIndex, rowData);
      }
      this.edit(rowIndex);
    }
  }

  edit(index) {
    const { editIndex } = this.state;
    const { onEditBegin, onEditEnd, editMode, editTemplate } = this.props;
    if (editMode && editTemplate) {
      if (editIndex && onEditEnd) {
        onEditEnd(editIndex);
      }
      this.setState({
        editIndex: index,
      });
      if (index && onEditBegin) {
        onEditBegin(index);
      }
    }
  }

  calcRowHeight({ index }) {
    const { rowHeight } = this.props;
    const { editIndex } = this.state;
    if (index !== editIndex) {
      return rowHeight;
    } else {
      const { editTemplateHeight } = this.props;
      return rowHeight + editTemplateHeight;
    }
  }

  sort({ sortBy, sortDirection }) {
    if (!this.props.onSort) {
      return;
    }
    this.props.onSort({
      sortBy,
      sortDirection: sortDirectionToString(sortDirection),
    });
  }

  renderHeader(headerProps) {
    const { columnData } = headerProps;
    return (
      <div
        className={classNames(
          styles.headerCellContainer,
          columnData.align
            ? styles[`${columnData.align}Align`]
            : styles.centerAlign,
          this.props.onSort ? styles.sortableRow : ""
        )}
      >
        {defaultTableHeaderRenderer(headerProps)}
        {columnData.headerSecondary && (
          <span className={styles.headerSecondary}>
            {columnData.headerSecondary}
          </span>
        )}
      </div>
    );
  }

  static renderCell(cellProps) {
    const { columnData, rowData } = cellProps;
    const template = columnData.template;
    let content;

    const extendedFormat = columnData.extendedFormat
      ? columnData.extendedFormat(rowData)
      : null;

    if (template) {
      content = template(rowData);
    } else {
      const format = columnData.format;
      const value = rowData[columnData.field];
      content = format ? format(value) : value;
    }

    return (
      <span
        onMouseEnter={(event) => {
          columnData.actions &&
            columnData.actions.onMouseEnter &&
            columnData.actions.onMouseEnter(event, rowData, columnData.field);
        }}
        onMouseLeave={(event) => {
          columnData.actions &&
            columnData.actions.onMouseLeave &&
            columnData.actions.onMouseLeave(event, rowData, columnData.field);
        }}
        style={extendedFormat && extendedFormat.style}
        className={classNames(
          styles.content,
          extendedFormat && extendedFormat.className
        )}
      >
        {content}
      </span>
    );
  }

  renderColumn(col, index) {
    return (
      <Column
        key={`column_index_${index}`}
        label={col.header}
        dataKey={col.field || ""}
        columnData={col}
        width={col.width || 120}
        flexGrow={
          col.flexGrow !== undefined &&
          col.flexGrow !== null &&
          col.flexGrow !== ""
            ? col.flexGrow
            : 1
        }
        flexShrink={
          col.flexShrink !== undefined &&
          col.flexShrink !== null &&
          col.flexShrink !== ""
            ? col.flexShrink
            : 1
        }
        className={classNames(
          styles.cell,
          col.align ? styles[`${col.align}Align`] : styles.centerAlign
        )}
        cellRenderer={DataGrid.renderCell}
        headerRenderer={this.renderHeader}
      />
    );
  }

  renderRow(renderProps) {
    const { editIndex } = this.state;
    const { index } = renderProps;
    if (editIndex !== index) {
      return defaultTableRowRenderer(renderProps);
    } else {
      const { editTemplate, rowHeight, editTemplateHeight } = this.props;
      const { rowData, columnData, className, key, style } = renderProps;
      renderProps = {
        ...renderProps,
        style: {
          ...style,
          top: "0px",
          height: rowHeight,
        },
        className: classNames(renderProps.className, styles.selectedRow),
        key: `${key}_inner_row`,
      };
      const templateStyle = {
        position: "absolute",
        left: "0px",
        top: `${rowHeight}px`,
        height: `${editTemplateHeight}px`,
        width: "100%",
      };
      return (
        <div className={className} key={key} style={style}>
          {defaultTableRowRenderer(renderProps)}
          <div
            className={styles.editTemplate}
            style={templateStyle}
            key={`${key}_editRow_template`}
          >
            {editTemplate(rowData, columnData)}
          </div>
        </div>
      );
    }
  }

  render() {
    const {
      columns,
      data,
      headerHeight,
      editMode,
      sortBy,
      sortDirection,
      noWrapHeader,
    } = this.props;
    let headerClasses = [styles.header];
    if (noWrapHeader) {
      headerClasses.push(styles.nowrap);
    }

    return (
      <AutoSizer>
        {({ height, width }) => {
          return (
            <Table
              ref={(el) => (this.table = el)}
              height={height}
              width={width - 10}
              rowCount={data ? data.length : 0}
              rowGetter={({ index }) => data[index]}
              rowHeight={this.calcRowHeight}
              headerHeight={headerHeight}
              overscanRowCount={50}
              rowClassName={editMode ? styles.editableRow : styles.row}
              headerClassName={classNames(headerClasses)}
              rowRenderer={this.renderRow}
              onRowClick={this.handleRowClick}
              sortBy={sortBy}
              sortDirection={toSortDirection(sortDirection)}
              sort={this.sort}
            >
              {columns.map(this.renderColumn)}
            </Table>
          );
        }}
      </AutoSizer>
    );
  }
}

DataGrid.defaultProps = {
  rowHeight: 50,
  headerHeight: 50,
};

DataGrid.propTypes = {
  data: PropTypes.array,
  columns: PropTypes.array.isRequired,
  rowHeight: PropTypes.number,
  headerHeight: PropTypes.number,
  noWrapHeader: PropTypes.bool,
  editTemplate: PropTypes.func,
  editMode: PropTypes.oneOf(["templateBelow"]),
  editTemplateHeight: PropTypes.number,
  sortBy: PropTypes.string,
  sortDirection: PropTypes.oneOf(["asc", "desc"]),
  onEditBegin: PropTypes.func,
  onEditEnd: PropTypes.func,
  onRowClicked: PropTypes.func,
  onSort: PropTypes.func,
};

function toSortDirection(sortDirection) {
  return (
    SortDirection[String(sortDirection).toLocaleUpperCase()] ||
    SortDirection.ASC
  );
}

function sortDirectionToString(sortDirection) {
  return sortDirection.toLocaleLowerCase();
}

export default DataGrid;
