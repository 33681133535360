import { IFuelAndTarget } from "../../reducers/reducer.fuelEmissions";

export const fuelAndTargetResponse: IFuelAndTarget = {
  fuelTypes: ["Mdo"],
  rows: [
    {
      operationMode: "M",
      runHours: 617.0419,
      consumptions: {
        Mdo: { actual: 0.221477, target: 0.0 },
      },
    },
    {
      operationMode: "O",
      runHours: 0.205555,
      consumptions: {
        Mdo: { actual: 0.035858, target: 0.0 },
      },
    },
    // {
    //   operationMode: "P",
    //   runHours: 1730.103,
    //   consumptions: {
    //     Mdo: { actual: 0.092723, target: 0.0 },
    //   },
    // },
    // {
    //   operationMode: "T",
    //   runHours: 1745.503,
    //   consumptions: {
    //     Mdo: { actual: 0.540275, target: 0.0 },
    //   },
    // },
    // {
    //   operationMode: "ET",
    //   runHours: 466.7775,
    //   consumptions: {
    //     Mdo: { actual: 0.430988, target: 0.0 },
    //   },
    // },
    // {
    //   operationMode: "FT",
    //   runHours: 371.7516,
    //   consumptions: {
    //     Mdo: { actual: 0.861581, target: 0.0 },
    //   },
    // },
    // {
    //   operationMode: "DP2",
    //   runHours: 1085.666,
    //   consumptions: {
    //     Mdo: { actual: 0.311477, target: 0.0 },
    //   },
    // },
    // {
    //   operationMode: "DP",
    //   runHours: 2669.036,
    //   consumptions: {
    //     Mdo: { actual: 0.190596, target: 0.0 },
    //   },
    // },
  ],
};
