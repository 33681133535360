import {
  FETCH_CALENDAR_OPERATIONALDATA,
  FETCH_CALENDAR_OPERATIONALDATA_SUCCESS,
  FETCH_CALENDAR_DOCUMENTS,
  FETCH_CALENDAR_DOCUMENTS_SUCCESS,
  FETCH_CALENDAR_HEALTH_EVENTS,
  FETCH_CALENDAR_HEALTH_EVENTS_SUCCESS,
  TOGGLE_PERFORMANCE_OPERATOR,
  TOGGLE_CALENDAR_DOCUMENTS,
  TOGGLE_CALENDAR_HEALTH_EVENTS,
  calendarEvents,
  calendarManualFuelData,
  FETCH_CALENDAR_OPERATIONALDATA_ERROR,
  FETCH_CALENDAR_DOCUMENTS_ERROR,
  FETCH_CALENDAR_HEALTH_EVENTS_ERROR,
} from "../actions/action.types";
import { calendarConfig } from "../common/calendarConfig";
import {
  formatDateWithTimeOffset,
  parseDateTimeWithoutTimeZoneOffset,
} from "../common/dates";
import { validate } from "../components/Form/FormTextBox/FormTextBox";
import { filter, isNil, flatMap, uniq, get, compact } from "lodash";
import { color } from "../../common/colors";
import { FuelType } from "../../onboard/models/FuelType";

export const filterValidEventTypes = (eventTypes) =>
  filter(Object.values(eventTypes), (x) => x.isSystemGenerated !== true);

const calendar = (
  state = {
    performanceOperator: {
      ...calendarConfig.performanceOperator.properties,
      active: true,
      data: [],
    },
    documents: {
      ...calendarConfig.documents.properties,
      active: false,
      data: [],
    },
    healthEvents: {
      ...calendarConfig.healthEvents.properties,
      active: false,
      data: [],
    },
    events: {
      ...calendarConfig.events.properties,
      active: true,
      data: [],
    },
    eventDialog: {
      visible: false,
    },
    manualFuelData: {
      ...calendarConfig.manualFuelData.properties,
      data: [],
      active: true,
    },
    manualFuelDataDialog: {
      visible: false,
    },
    manualFuelDataConfig: {},
    date: {},
  },
  action = {}
) => {
  switch (action.type) {
    case FETCH_CALENDAR_OPERATIONALDATA: {
      return {
        ...state,
        performanceOperator: {
          ...state.performanceOperator,
          data: [],
          isLoading: true,
        },
      };
    }
    case FETCH_CALENDAR_OPERATIONALDATA_SUCCESS: {
      return {
        ...state,
        performanceOperator: {
          ...state.performanceOperator,
          data: prepareOperationData(action.data, action.timeOffset),
          isLoading: false,
        },
      };
    }
    case FETCH_CALENDAR_OPERATIONALDATA_ERROR: {
      return {
        ...state,
        performanceOperator: {
          ...state.performanceOperator,
          error: action.error,
          isLoading: false,
        },
      };
    }
    case FETCH_CALENDAR_DOCUMENTS: {
      return {
        ...state,
        documents: {
          ...state.documents,
          data: [],
          isLoading: true,
        },
      };
    }
    case FETCH_CALENDAR_DOCUMENTS_SUCCESS: {
      return {
        ...state,
        documents: {
          ...state.documents,
          data: addTimeOffsetToDate(action.data, action.timeOffset),
          isLoading: false,
        },
      };
    }
    case FETCH_CALENDAR_DOCUMENTS_ERROR: {
      return {
        ...state,
        documents: {
          ...state.documents,
          error: action.error,
          isLoading: false,
        },
      };
    }
    case FETCH_CALENDAR_HEALTH_EVENTS: {
      return {
        ...state,
        healthEvents: {
          ...state.healthEvents,
          data: [],
          isLoading: true,
        },
      };
    }
    case FETCH_CALENDAR_HEALTH_EVENTS_SUCCESS: {
      return {
        ...state,
        healthEvents: {
          ...state.healthEvents,
          data: addTimeOffsetToDate(action.data, action.timeOffset),
          isLoading: false,
        },
      };
    }
    case FETCH_CALENDAR_HEALTH_EVENTS_ERROR: {
      return {
        ...state,
        healthEvents: {
          ...state.healthEvents,
          error: action.error,
          isLoading: false,
        },
      };
    }
    case calendarEvents.FETCH_CALENDAR_EVENTS: {
      return {
        ...state,
        events: {
          ...state.events,
          data: [],
          isLoading: true,
        },
      };
    }
    case calendarEvents.FETCH_CALENDAR_EVENTS_SUCCESS: {
      return {
        ...state,
        events: {
          ...state.events,
          data: addTimeOffsetToEventsDates(action.data, action.timeOffset),
          isLoading: false,
        },
        date: {
          ...state.date,
          range: action.dateRange,
        },
      };
    }
    case calendarEvents.FETCH_CALENDAR_EVENTS_ERROR: {
      return {
        ...state,
        events: {
          ...state.events,
          error: action.error,
          isLoading: false,
        },
      };
    }
    case TOGGLE_PERFORMANCE_OPERATOR: {
      return {
        ...state,
        performanceOperator: {
          ...state.performanceOperator,
          active: !state.performanceOperator.active,
        },
      };
    }
    case TOGGLE_CALENDAR_DOCUMENTS: {
      return {
        ...state,
        documents: {
          ...state.documents,
          active: !state.documents.active,
        },
      };
    }
    case TOGGLE_CALENDAR_HEALTH_EVENTS: {
      return {
        ...state,
        healthEvents: {
          ...state.healthEvents,
          active: !state.healthEvents.active,
        },
      };
    }
    case calendarEvents.TOGGLE_CALENDAR_EVENTS: {
      return {
        ...state,
        events: {
          ...state.events,
          active: !state.events.active,
        },
      };
    }
    case calendarEvents.ADD_EVENT: {
      return {
        ...state,
        eventDialog: {
          visible: true,
          mode: "new",
          event: {
            title: {
              required: true,
            },
            eventTypeId: {
              required: true,
            },
            startTime: {
              type: "dateTime",
              required: true,
            },
            endTime: {
              type: "dateTime",
            },
            description: {},
          },
        },
      };
    }
    case calendarEvents.EDIT_EVENT: {
      const { event, eventType } = action;
      return {
        ...state,
        eventDialog: {
          visible: true,
          mode: "edit",
          event: {
            id: {
              value: event.id,
            },
            title: {
              required: true,
              value: event.title,
            },
            eventTypeId: {
              required: true,
              value: eventType,
            },
            startTime: {
              required: true,
              value: !isNil(event.startTime)
                ? // We compensate for the local time zone in order to show the date in UTC.
                  parseDateTimeWithoutTimeZoneOffset(event.startTime)
                : null,
            },
            endTime: {
              value: !isNil(event.endTime)
                ? parseDateTimeWithoutTimeZoneOffset(event.endTime)
                : null,
            },
            description: {
              value: event.description,
            },
          },
        },
      };
    }
    case calendarEvents.CLOSE_EVENT_DIALOG:
    case calendarEvents.CONFIRM_DELETE_EVENT_SUCCESS:
    case calendarEvents.SAVE_EVENT_SUCCESS: {
      return {
        ...state,
        eventDialog: {
          visible: false,
        },
      };
    }
    case calendarEvents.EVENT_FIELD_VALUE_CHANGED: {
      const event = {
        ...state.eventDialog.event,
        [action.field]: {
          ...state.eventDialog.event[action.field],
          value: action.value,
          type: action.properties ? action.properties.type : undefined,
        },
      };
      return {
        ...state,
        eventDialog: {
          ...state.eventDialog,
          event,
          ...validateEvent(event),
        },
      };
    }
    case calendarEvents.SAVE_EVENT:
    case calendarEvents.CONFIRM_DELETE_EVENT: {
      return {
        ...state,
        eventDialog: {
          ...state.eventDialog,
          isSaving: true,
        },
      };
    }
    case calendarEvents.DELETE_EVENT: {
      return {
        ...state,
        eventDialog: {
          visible: true,
          mode: "delete",
          event: action.event,
        },
      };
    }
    case calendarManualFuelData.TOGGLE_CALENDAR_MANUAL_FUEL_DATA: {
      return {
        ...state,
        manualFuelData: {
          ...state.manualFuelData,
          active: !state.manualFuelData.active,
        },
      };
    }
    case calendarManualFuelData.FETCH_CALENDAR_MANUAL_FUEL_DATA: {
      return {
        ...state,
        manualFuelData: {
          ...state.manualFuelData,
          data: [],
          isLoading: true,
        },
      };
    }
    case calendarManualFuelData.FETCH_CALENDAR_MANUAL_FUEL_DATA_SUCCESS: {
      return createManualFuelDataState(action, state);
    }
    case calendarManualFuelData.FETCH_MANUAL_FUEL_DATA_CONFIG: {
      return {
        ...state,
        manualFuelDataConfig: {
          ...state.manualFuelDataConfig,
          isLoading: true,
        },
      };
    }
    case calendarManualFuelData.FETCH_MANUAL_FUEL_DATA_CONFIG_SUCCESS: {
      const fuelTypes = action.data.fuelTypes.map((x) => FuelType[x]);
      return {
        ...state,
        manualFuelDataConfig: {
          operations: action.data.operations,
          fuelTypes: fuelTypes,
          isLoading: false,
        },
      };
    }
    case calendarManualFuelData.ADD_MANUAL_FUEL_DATA: {
      return createManualFuelDataDialogState(action, state);
    }
    case calendarManualFuelData.MANUAL_FUEL_DATA_FIELD_VALUE_CHANGED: {
      return createManualFuelDataDialogChangeState(action, state);
    }
    case calendarManualFuelData.ADD_ROW_MANUAL_FUEL_DATA_DIALOG: {
      return createAddRowManualFuelData(state);
    }
    case calendarManualFuelData.REMOVE_ROW_MANUAL_FUEL_DATA_DIALOG: {
      return createRemoveRowManualFuelData(action, state);
    }
    case calendarManualFuelData.CLOSE_MANUAL_FUEL_DATA_DIALOG: {
      return {
        ...state,
        manualFuelDataDialog: {
          visible: false,
        },
      };
    }
    case calendarManualFuelData.SAVE_MANUAL_FUEL_DATA: {
      return {
        ...state,
        manualFuelDataDialog: {
          ...state.manualFuelDataDialog,
          isSaving: true,
        },
      };
    }
    case calendarManualFuelData.SAVE_MANUAL_FUEL_DATA_SUCCESS: {
      return {
        ...state,
        manualFuelDataDialog: {
          visible: false,
        },
      };
    }
    default:
      return state;
  }
};

const prepareOperationData = ({ metricData, performanceData }, timeOffset) => {
  const dates = uniq(flatMap(metricData, "values").map((d) => d.date));
  return dates.map((d) => {
    return {
      date: formatDateWithTimeOffset(d, timeOffset),
      values: compact([
        ...metricData.map((m) => ({
          unit: m.symbol,
          value: get(
            m.values.find((v) => v.date === d),
            "value",
            0
          ),
          name: m.name,
        })),
        ...performanceData.map((p) => {
          const value = get(
            p.values.find((v) => v.date === d),
            "value"
          );

          return (
            value && {
              unit: "%",
              color:
                value < 0 ? color("--normal-red") : color("--normal-green"),
              value,
              name: `${p.name} ${p.symbol}`,
            }
          );
        }),
      ]),
    };
  });
};

const addTimeOffsetToDate = (data, timeOffset) => {
  data.forEach((d) => {
    d.date = formatDateWithTimeOffset(d.date, timeOffset);
  });
  return data;
};

const addTimeOffsetToEventsDates = (data, timeOffset) => {
  data.forEach((d) => {
    d.startTime = formatDateWithTimeOffset(d.startTime, timeOffset);
    d.endTime = isNil(d.endTime)
      ? null
      : formatDateWithTimeOffset(d.endTime, timeOffset);
  });
  return data;
};

export const loadingStatus = ({
  performanceOperator,
  documents,
  events,
  healthEvents,
  manualFuelData,
}) => {
  const categories = Object.values({
    performanceOperator,
    documents,
    events,
    healthEvents,
    manualFuelData,
  });
  return {
    isLoading: categories.some((x) => x.active && x.isLoading),
    error: categories
      .filter((x) => x.error)
      .reduce((acc, { error }) => acc + error, ""),
  };
};

const validateEvent = (event) => {
  const isValid = Object.values(event).every(
    (e) => validate(e.type, e.value, e.required).valid === true
  );
  return {
    isValid,
  };
};
const createManualFuelDataState = (action, state) => {
  let data = action.data.map((d) => {
    let consumptions = Object.keys(d.consumptions).map((fuelType) => {
      return {
        fuelType: fuelType.toUpperCase(),
        consumption: d.consumptions[fuelType] / 1000,
      };
    });
    return {
      ...d,
      consumptions: consumptions,
    };
  });
  let grouped = data
    .map((fd) => {
      return { date: fd.date, consumptions: fd.consumptions };
    })
    .reduce((acc, { date, consumptions }) => {
      acc[date] = [...(acc[date] || []), consumptions];
      return acc;
    }, {});

  let tags = Object.entries(grouped).map((g) => {
    return { date: g[0], consumptions: g[1] };
  });

  return {
    ...state,
    manualFuelData: {
      ...state.manualFuelData,
      tags: tags,
      data: data,
      isLoading: false,
    },
  };
};
const createManualFuelDataDialogState = (action, state) => {
  let date = action.day;
  let data = state.manualFuelData.data.filter((data) => data.date === date);
  let mode = "edit";

  if (data.length === 0) {
    let fuelTypes = state.manualFuelDataConfig.fuelTypes;
    let consumptions = fuelTypes.map((ft) => {
      return { fuelType: ft };
    });
    data = [
      {
        date,
        operationName: "",
        operationLegend: "",
        duration: 0,
        consumptions: consumptions,
        availableOperations: state.manualFuelDataConfig.operations,
      },
    ];
    mode = "new";
  }
  let selectedOperationLegeds = data.map((x) => x.operationLegend);
  let selectedOperations = state.manualFuelDataConfig.operations.filter((x) =>
    selectedOperationLegeds.includes(x.legend)
  );
  let availableOperations = state.manualFuelDataConfig.operations.filter(
    (x) => !selectedOperations.includes(x)
  );
  let dataWithOperations = data.map((x) => {
    return {
      ...x,
      availableOperations: [
        ...availableOperations,
        ...state.manualFuelDataConfig.operations.filter(
          (y) => y.legend === x.operationLegend
        ),
      ],
    };
  });

  return {
    ...state,
    manualFuelDataDialog: {
      visible: true,
      isSaving: false,
      mode: mode,
      date: date,
      data: dataWithOperations,
    },
  };
};

const createAddRowManualFuelData = (state) => {
  let selectedOperations = state.manualFuelDataDialog.data.map(
    (x) => x.operationLegend
  );
  let availableOperations = state.manualFuelDataConfig.operations.filter(
    (x) => !selectedOperations.includes(x.legend)
  );
  let data = {
    date: state.manualFuelDataDialog.date,
    operationName: "",
    operationLegend: "",
    duration: 0,
    consumptions: [],
    availableOperations: availableOperations,
  };
  return {
    ...state,
    manualFuelDataDialog: {
      ...state.manualFuelDataDialog,
      data: [...state.manualFuelDataDialog.data, data],
    },
  };
};

const createRemoveRowManualFuelData = (action, state) => {
  let removedOperationLegend =
    state.manualFuelDataDialog.data[action.index].operationLegend;
  let removedOperation = state.manualFuelDataConfig.operations.find(
    (y) => y.legend === removedOperationLegend
  );

  let mappedData = state.manualFuelDataDialog.data.map((x) => {
    let availableOperations =
      removedOperation === undefined || removedOperation === null
        ? x.availableOperations
        : [...x.availableOperations, removedOperation];
    return {
      ...x,
      availableOperations: availableOperations,
    };
  });

  return {
    ...state,
    manualFuelDataDialog: {
      ...state.manualFuelDataDialog,
      data: [
        ...mappedData.slice(0, action.index),
        ...mappedData.slice(action.index + 1),
      ],
    },
  };
};

const createManualFuelDataDialogChangeState = (action, state) => {
  if (action.field === "operation") {
    let operation = state.manualFuelDataConfig.operations.find(
      (o) => o.legend === action.value
    );
    let usedOperationLegends = state.manualFuelDataDialog.data
      .map((x) => x.operationLegend)
      .filter(
        (x) =>
          x !== state.manualFuelDataDialog.data[action.index].operationLegend
      );
    usedOperationLegends.push(operation.legend);
    let availableOperations = state.manualFuelDataConfig.operations.filter(
      (x) => !usedOperationLegends.includes(x.legend)
    );
    let mappedData = state.manualFuelDataDialog.data.map((x, index) => {
      if (index === action.index) {
        return {
          ...state.manualFuelDataDialog.data[action.index],
          operationLegend: action.value,
          operationName: operation.name,
          availableOperations: [...availableOperations, operation],
        };
      }
      return {
        ...x,
        availableOperations: [
          ...availableOperations,
          ...state.manualFuelDataConfig.operations.filter(
            (y) => y.legend === x.operationLegend
          ),
        ],
      };
    });
    return {
      ...state,
      manualFuelDataDialog: {
        ...state.manualFuelDataDialog,
        data: mappedData,
      },
    };
  } else if (action.field === "consumptions") {
    let value = action.value;
    let consumptionsValues =
      state.manualFuelDataDialog.data[action.index].consumptions;
    let consumptionIndex = consumptionsValues.indexOf(
      consumptionsValues.find((c) => c.fuelType === action.options.fuelType)
    );
    if (consumptionIndex !== -1) {
      return {
        ...state,
        manualFuelDataDialog: {
          ...state.manualFuelDataDialog,
          data: [
            ...state.manualFuelDataDialog.data.slice(0, action.index),
            {
              ...state.manualFuelDataDialog.data[action.index],
              consumptions: [
                ...state.manualFuelDataDialog.data[
                  action.index
                ].consumptions.slice(0, consumptionIndex),
                { fuelType: action.options.fuelType, consumption: value },
                ...state.manualFuelDataDialog.data[
                  action.index
                ].consumptions.slice(consumptionIndex + 1),
              ],
            },
            ...state.manualFuelDataDialog.data.slice(action.index + 1),
          ],
        },
      };
    }
    return {
      ...state,
      manualFuelDataDialog: {
        ...state.manualFuelDataDialog,
        data: [
          ...state.manualFuelDataDialog.data.slice(0, action.index),
          {
            ...state.manualFuelDataDialog.data[action.index],
            consumptions: [
              ...state.manualFuelDataDialog.data[action.index].consumptions,
              { fuelType: action.options.fuelType, consumption: action.value },
            ],
          },
          ...state.manualFuelDataDialog.data.slice(action.index + 1),
        ],
      },
    };
  } else if (action.field === "duration") {
    let seconds =
      Number(action.value.hh) * 3600 +
      Number(action.value.mm) * 60 +
      Number(action.value.ss);
    return {
      ...state,
      manualFuelDataDialog: {
        ...state.manualFuelDataDialog,
        data: [
          ...state.manualFuelDataDialog.data.slice(0, action.index),
          {
            ...state.manualFuelDataDialog.data[action.index],
            [action.field]: seconds,
          },
          ...state.manualFuelDataDialog.data.slice(action.index + 1),
        ],
      },
    };
  }
};

export default calendar;
