import React from "react";
import styles from "./Emissions.css";
import DataGridView from "../DataGridView/DataGridView";
import { IEmissionsByOperationsState, IEmissionsData } from "../commonTypes";
import Loader from "../../../../common/components/Loader";
import { Column } from "../DataGridView/Types";
import { sortByOperationLegend } from "../sortByOperationLegend";

interface IProps {
  data: IEmissionsByOperationsState;
}

function Emissions({ data }: IProps): JSX.Element {
  const sortedData = data.data
    ? sortByOperationLegend(data.data, (item) => item.operationMode)
    : [];
  return data.isLoading ? (
    <Loader text="Loading Emissions" />
  ) : (
    <div className={styles.emissionsContainer}>
      <DataGridView
        data={sortedData}
        idSelector={(dataItem) => dataItem.operationMode}
        columns={columns}
      />
    </div>
  );
}

const columns: Column<IEmissionsData>[] = [
  {
    field: "operationMode",
    unit: "",
    total: false,
  },
  {
    name: "CO2",
    field: "cO2",
    unit: "t",
    total: true,
    toFixed: 2,
  },
  {
    name: "NOx",
    field: "nOx",
    unit: "t",
    total: true,
    toFixed: 2,
  },
  {
    name: "SOx",
    field: "sOx",
    unit: "t",
    total: true,
    toFixed: 2,
  },
];

export default Emissions;
