import {
  takeLatest,
  takeEvery,
  call,
  getContext,
  put,
  fork,
  select,
} from "redux-saga/effects";
import { isNil } from "lodash";
import { cargoConfigApi } from "../../api";
import { admin } from "../../actions/action.types";
import {
  fetchVesselSuccess,
  fetchVesselError,
  fetchCargoSuccess,
  fetchCargoError,
  fetchCargo,
  saveCargoError,
} from "../../actions/admin/action.cargoconfig";
import { FUEL_TYPE_NAMES } from "../../config";

const VESSEL_HAS_NO_CARGO_MESSAGE =
  "Selected vessel does not have configured cargo type";

export function* cargoConfigSaga() {
  yield fork(initWatchers);
}

function* initWatchers() {
  yield takeEvery([admin.cargoConfig.FETCH_VESSELS], fetchVesselsSaga);
  yield takeLatest([admin.cargoConfig.FETCH_CARGO], fetchVesselCargo);
  yield takeEvery([admin.cargoConfig.SAVE_CARGO], saveCargoSaga);
}

function* fetchVesselsSaga() {
  try {
    const { vessels } = yield select((s) => s.pageContext);
    const data = Object.keys(vessels || {}).map((x) => ({
      id: vessels[x].id,
      name: vessels[x].name,
    }));
    yield put(fetchVesselSuccess(data));
  } catch (err) {
    yield put(fetchVesselError(err));
  }
}

function* fetchVesselCargo(args) {
  const { dateRange, vesselId } = args.payload;
  try {
    const data = yield call(getData, vesselId, dateRange);
    if (data.cargoType) {
      yield put(fetchCargoSuccess(data));
    } else {
      yield put(fetchCargoError(VESSEL_HAS_NO_CARGO_MESSAGE));
    }
  } catch (err) {
    yield put(fetchCargoError(err));
  }
}

function* getData(vesselId, dateRange) {
  const http = yield getContext("http");
  const res = yield call(
    http.get,
    cargoConfigApi.getEEOI(vesselId, dateRange.from, dateRange.to)
  );

  const { id, trips, cargoType, eeoi } = res.data;
  let cargos = trips || [];
  const fuelConsumptionColumns = (
    (cargos.length && cargos[0].fuelConsumptions) ||
    []
  ).map((x) => ({
    fuelTypeId: x.fuelTypeId,
    name: FUEL_TYPE_NAMES[x.fuelTypeId],
    unit: x.unit.symbol,
  }));
  cargos.forEach((cargo) => {
    const fuelConsumptions = cargo.fuelConsumptions;
    fuelConsumptionColumns.forEach((fuelConsumptionColumn) => {
      cargo[fuelConsumptionColumn.fuelTypeId] = fuelConsumptions.find(
        (x) => x.fuelTypeId === fuelConsumptionColumn.fuelTypeId
      )?.value;
    });
  });

  return { id, fuelConsumptionColumns, cargos, dateRange, cargoType, eeoi };
}

function* saveCargoSaga() {
  try {
    const { cargos, id, dateRange } = yield select(
      (s) => s.admin.cargoConfig.data
    );
    const data = cargos
      .filter((x) => !isNil(x.cargo))
      .map((x) => ({
        id: x.legId,
        value: x.cargo,
        startTime: x.from,
        endTime: x.to,
      }));
    const http = yield getContext("http");
    yield call(http.post, cargoConfigApi.saveCargo(id), data);
    yield put(fetchCargo({ vesselId: id, dateRange }));
  } catch (err) {
    yield put(saveCargoError(err));
  }
}
