import React from "react";
import PropTypes from "prop-types";
import AutoSizer from "../../../common/components/AutoSizer/AutoSizer";
import Sparkline from "../../../common/components/Sparkline/Sparkline";
import SparklineYAxis from "../../../common/components/Sparkline/SparklineYAxis";
import { groupBy, flatten, find, get, isFinite } from "lodash";
import * as d3 from "d3";
import { color, ansiGraphColors, ansiColor } from "../../../common/colors";
import SparklineLegends from "../../../common/components/Sparkline/SparklineLegends";
import SparklineGrid from "../../../common/components/Sparkline/SparklineGrid";
import SparklineHover from "../../../common/components/Sparkline/SparklineHover";
import SparklineTooltip from "../../../common/components/Sparkline/SparklineTooltip";
import SparklineLine from "../../../common/components/Sparkline/SparklineLine";
import SparklineCustomElement from "../../../common/components/Sparkline/SparklineCustomElement";
import DataDetailsHeaderContainer from "./DataDetailsHeaderContainer";
import DataDetailsComponentContainer from "./DataDetailsComponentContainer";
import SparklineXAxis from "../../../common/components/Sparkline/SparklineXAxis";
import SparklineZoom from "../../../common/components/Sparkline/SparklineZoom";
import setYDomainMargins from "./SetYDomainMargins";
import { getYAxisConfig } from "./Helpers";

const oneDomainColors = (_domainIndex, seriesIndex, dataSeries) => {
  const series = dataSeries[seriesIndex];
  if (series.color) {
    return ansiColor(series.color);
  }
  return oneDomainLineColors[seriesIndex];
};

const multipleDomainsColors = (domainIndex, seriesIndex, dataSeries) => {
  const series = dataSeries[seriesIndex];
  if (series.color) {
    return ansiColor(series.color);
  }
  return lineColors[domainIndex][seriesIndex];
};

function getDomains(groupedSeries) {
  return Object.keys(groupedSeries).map((key, index) => {
    const seriesGroup = groupedSeries[key];
    const yDomain = computeYDomain(seriesGroup);
    const series = transformSeriesGroup(seriesGroup, index);

    return { unit: key, yDomain, domainIndex: index, series };
  });
}

const computeYDomain = (seriesGroup) => {
  const initialYDomain = [
    d3.min(seriesGroup, (s) => (s.yDomain ? d3.min(s.yDomain) : undefined)),
    d3.max(seriesGroup, (s) => (s.yDomain ? d3.max(s.yDomain) : undefined)),
  ];

  if (isFinite(initialYDomain[0]) && isFinite(initialYDomain[1])) {
    return initialYDomain;
  }

  return [
    ...Object.values(
      setYDomainMargins(
        d3.min(seriesGroup, (s) => d3.min(s.values, (v) => v.y)),
        d3.max(seriesGroup, (s) => d3.max(s.values, (v) => v.y))
      )
    ),
  ];
};

const transformSeriesGroup = (seriesGroup, domainIndex) =>
  seriesGroup.map((s, seriesIndex) => ({
    xUnit: s.xUnit,
    xDataType: s.xDataType,
    legend: s.legend,
    lineBreakOptions: {
      continuous: s.lineBreakOptions?.continuous,
      minimumBreakMinutes: s.lineBreakOptions?.minute,
    },
    color: s.color,
    domainIndex,
    seriesIndex,
    values: transformValues(s),
    alerts: transformAlerts(s),
  }));

const transformValues = (series) =>
  series.values?.map((v) => DataDetailsLineChart.parseDataValue(series, v));

const transformAlerts = (series) =>
  series.values
    ?.filter((v) => v.properties?.some((p) => p.propertyType === "Alert"))
    .map((v) => DataDetailsLineChart.parseDataValue(series, v));

const renderSparklineGrid = (dataSeries, domains, yDomains, colorFunction) =>
  domains.map((d) => (
    <SparklineGrid
      key={`grid_${d.domainIndex}`}
      showX={false}
      yDomainIndex={d.domainIndex}
      color={
        yDomains.length === 1
          ? undefined
          : colorFunction(d.domainIndex, 0, dataSeries)
      }
    />
  ));

const renderSparklineYAxis = (dataSeries, domains, yDomains, colorFunction) =>
  domains.map((d, index) => (
    <SparklineYAxis
      key={`yAxis_${d.domainIndex}`}
      position={index === 0 ? "left" : "right"}
      yDomainIndex={d.domainIndex}
      color={
        yDomains.length === 1
          ? undefined
          : colorFunction(d.domainIndex, 0, dataSeries)
      }
      label={d.unit}
    />
  ));

const renderSparklineXAxis = (xDataType) => (
  <SparklineXAxis
    tickFormat={DataDetailsLineChart.getTickFormat(xDataType)}
    xDataType={xDataType}
  />
);

const renderSparklineLines = (dataSeries, colorFunction) =>
  dataSeries.map((s, index) => (
    <SparklineLine
      key={`line_${s.domainIndex}`}
      dataSetIndex={index}
      yDomainIndex={s.domainIndex}
      color={colorFunction(s.domainIndex, s.seriesIndex, dataSeries)}
      showHoverPoint={true}
      lineBreakOptions={s.lineBreakOptions}
    />
  ));

const renderSparklineHover = (dataSeries, colorFunction) => (
  <SparklineHover>
    <SparklineTooltip
      tooltips={dataSeries.map((s, index) => ({
        dataSetIndex: index,
        yDomainIndex: s.domainIndex,
        color: colorFunction(s.domainIndex, s.seriesIndex, dataSeries),
      }))}
    />
  </SparklineHover>
);

const renderSparklineCustomElements = (dataSeries) => (
  <SparklineCustomElement>
    {({ scaleY, scaleX }) =>
      dataSeries
        .filter((s) => s.alerts)
        .map((s, si) =>
          s.alerts.map((a, ai) => (
            <circle
              pointerEvents={"none"}
              key={`alert_${si}_${ai}`}
              cx={scaleX(a.x)}
              cy={scaleY[s.domainIndex](a.y)}
              r={10}
              fill={color("--bright-red")}
              fillOpacity={0.75}
              stroke={color("--bright-red")}
              strokeWidth={2}
            />
          ))
        )
    }
  </SparklineCustomElement>
);

const renderSparklineLegends = (
  dataSeries,
  legendTilting,
  xDataType,
  colorFunction
) => (
  <SparklineLegends
    legends={dataSeries
      .filter((s) => s.legend)
      .map((s, index) => ({
        text: s.legend,
        color: colorFunction(s.domainIndex, s.seriesIndex, dataSeries),
        dataSetIndex: index,
      }))}
    tilting={legendTilting}
    xDataType={xDataType}
  />
);

class DataDetailsLineChart extends React.Component {
  static parseDataValue(series, value) {
    return {
      x:
        series.xDataType === "Date" || series.xDataType === "DateTime"
          ? new Date(value.x)
          : value.x,
      y: value.y,
    };
  }

  static getTickFormat(dataType) {
    switch (dataType) {
      case "Date":
        return d3.timeFormat("%Y-%m-%d");
      case "DateTime":
        return d3.timeFormat("%Y-%m-%d %H:%M:%S");
      default:
        return null;
    }
  }

  render() {
    const {
      series,
      title,
      fullWidth,
      showEqualAspectRatio,
      legendTilting,
      componentDescription,
      yAxisConfig,
    } = this.props;
    const groupedSeries = groupBy(series, "yUnit");
    const domains = getDomains(groupedSeries);
    const dataValues =
      flatten(domains.map((d) => d.series.map((s) => s.values))) || [];
    const yDomains = domains.map((d) => d.yDomain);
    const dataSeries = flatten(domains.map((d) => d.series));
    const xDataType = get(find(dataSeries, "xDataType"), "xDataType");
    let colorFunction = multipleDomainsColors;
    if (yDomains.length === 1) {
      colorFunction = oneDomainColors;
    }
    return (
      <DataDetailsComponentContainer fullWidth={fullWidth}>
        <DataDetailsHeaderContainer
          contentTitle={title}
          componentDescription={componentDescription}
          showHeader={false}
          showEqualAspectRatio={showEqualAspectRatio}
        >
          {dataValues && (
            <AutoSizer>
              {({ width, height }) => {
                return (
                  <Sparkline
                    data={dataValues}
                    width={width}
                    height={height}
                    yDomain={yDomains}
                    showEqualAspectRatio={showEqualAspectRatio}
                    {...getYAxisConfig(yAxisConfig)}
                  >
                    <SparklineZoom>
                      {renderSparklineGrid(
                        dataSeries,
                        domains,
                        yDomains,
                        colorFunction
                      )}
                      {renderSparklineYAxis(
                        dataSeries,
                        domains,
                        yDomains,
                        colorFunction
                      )}
                      {renderSparklineXAxis(xDataType)}
                      {renderSparklineLines(dataSeries, colorFunction)}
                      {renderSparklineHover(dataSeries, colorFunction)}
                      {renderSparklineCustomElements(dataSeries)}
                    </SparklineZoom>

                    {renderSparklineLegends(
                      dataSeries,
                      legendTilting,
                      xDataType,
                      colorFunction
                    )}
                  </Sparkline>
                );
              }}
            </AutoSizer>
          )}
        </DataDetailsHeaderContainer>
      </DataDetailsComponentContainer>
    );
  }
}

DataDetailsLineChart.propTypes = {
  fullWidth: PropTypes.bool,
  series: PropTypes.arrayOf(
    PropTypes.shape({
      legend: PropTypes.string,
      values: PropTypes.arrayOf(
        PropTypes.shape({
          x: PropTypes.any,
          y: PropTypes.any,
        })
      ),
    })
  ).isRequired,
};

const lineColors = [
  [
    color("--blue-dark"),
    color("--blue-base"),
    color("--blue-bright"),
    color("--blue-darkest"),
    color("--blue-brightest"),
  ],
  [
    color("--red-dark"),
    color("--red-base"),
    color("--red-bright"),
    color("--red-darkest"),
    color("--red-brightest"),
  ],
  [
    color("--orange-dark"),
    color("--orange-base"),
    color("--orange-bright"),
    color("--orange-darkest"),
    color("--orange-brightest"),
  ],
  [
    color("--yellow-dark"),
    color("--yellow-base"),
    color("--yellow-bright"),
    color("--yellow-darkest"),
    color("--yellow-brightest"),
  ],
];

const oneDomainLineColors = ansiGraphColors;

export default DataDetailsLineChart;
