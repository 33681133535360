/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from "react";
import { createSelector } from "reselect";
import { useSelector, useDispatch } from "react-redux";
import styles from "./FuelEmissions.css";
import Header, { IHeaderSelectOptions } from "./Header";
import {
  changeFuelType,
  changeSelectedEmissionTrend,
  fetchEmissionsDonutData,
  fetchFuelConsumptions,
  fetchEmissionsTrend,
  fetchEmissionsByOperationModes,
  fetchFuelAndTarget,
  setTargetFuelDataCancel,
  setTargetFuelDataSave,
  setTargetFuelDataHide,
  changeFuelAndTargetFuelType,
} from "../../actions/action.fuelEmissions";
import FuelTarget from "./FuelTarget";
import Emissions from "./Emissions";
import FuelConsumption from "./FuelConsumption";
import EmissionDonuts from "./Donuts";
import EmissionsTrend from "./EmissionsTrend";
import SetFuelTargetsDialog from "./SetFuelTargets";
import classNames from "../../../common/classNames";
import {
  IFuelEmissionsState,
  IOperationTargetsData,
  IState,
} from "./commonTypes";
import { AnyAction } from "redux";
import { Option } from "src/common/components/RadioButton";
import { EditTargetButton } from "./FuelTarget/EditTargetButton";
import { WIDGET_INFO_TEXT } from "./infoText";

const selectFilters = (state: any) => state.fuelEmissions.filters;

const selectEmissionsFilters = createSelector(
  selectFilters,
  (filters) => filters || {}
);

export function FuelEmissions(): JSX.Element {
  const filters = useSelector(selectEmissionsFilters);
  const fuelEmissions: IFuelEmissionsState = useSelector(
    (state: IState) => state.fuelEmissions
  );
  const dispatch = useDispatch();
  const dispatchAction = (action: any) => dispatch(action) as AnyAction;

  useEffect(() => {
    if (!filters?.vesselId || !filters?.dateFilter) return;

    dispatchAction(fetchFuelConsumptions(filters));
    dispatchAction(fetchEmissionsDonutData(filters));
    dispatchAction(fetchEmissionsByOperationModes(filters));
    dispatchAction(fetchEmissionsTrend(filters));
    dispatchAction(fetchFuelAndTarget(filters));
  }, [filters]);

  const fuelOptions: IHeaderSelectOptions = {
    options: fuelEmissions?.fuelConsumption.fuels,
    onChange: (o: Option) => dispatchAction(changeFuelType(o.id)),
  };
  const emissionsTrendOptions: IHeaderSelectOptions = {
    options: fuelEmissions?.emissionsTrend.options,
    onChange: (o: Option) =>
      dispatchAction(changeSelectedEmissionTrend(o.name)),
  };
  const fuelAndTargetOptions: IHeaderSelectOptions = {
    options: fuelEmissions?.fuelAndTarget.fuels ?? [],
    onChange: (o: Option) => dispatchAction(changeFuelAndTargetFuelType(o.id)),
  };

  const setFuelTargetsDialogCancel = () => {
    dispatchAction(setTargetFuelDataCancel());
  };
  const setFuelTargetsDialogHide = () => {
    dispatchAction(setTargetFuelDataHide());
  };
  const setFuelTargetsDialogSave = () => {
    const changes: IOperationTargetsData[] =
      fuelEmissions.setTargetFuelDataDialog.data?.data.filter(
        (d) => !!d.hasChanges
      ) ?? [];
    dispatchAction(setTargetFuelDataSave(filters.vesselId, changes));
  };
  return (
    <div className={styles.topGridContainer}>
      <div className={styles.gridItem}>
        <Header
          title={WIDGET_INFO_TEXT.FUEL_CONSUMPTION.title}
          headerSelectOptions={fuelOptions}
          info={WIDGET_INFO_TEXT.FUEL_CONSUMPTION.info}
        />
        <div style={{ overflowY: "auto" }}>
          <FuelConsumption fuelConsumption={fuelEmissions.fuelConsumption} />
        </div>
      </div>
      <div className={styles.gridItem}>
        <Header
          title={WIDGET_INFO_TEXT.EMISSION_TRENDS.title}
          headerSelectOptions={emissionsTrendOptions}
          info={WIDGET_INFO_TEXT.EMISSION_TRENDS.info}
        />
        <EmissionsTrend />
      </div>
      <div className={styles.gridItem}>
        <Header
          title={WIDGET_INFO_TEXT.FUEL_AND_TARGET.title}
          headerSelectOptions={fuelAndTargetOptions}
          info={WIDGET_INFO_TEXT.FUEL_AND_TARGET.info}
        >
          <EditTargetButton />
        </Header>

        <FuelTarget data={fuelEmissions.fuelAndTarget} />
      </div>
      <div className={classNames(styles.gridItem, styles.donut)}>
        <Header
          isTitleHidden
          title={WIDGET_INFO_TEXT.DATA_PER_OPERATION.title}
          info={WIDGET_INFO_TEXT.DATA_PER_OPERATION.info}
        />
        <EmissionDonuts donutData={fuelEmissions.donutData} />
      </div>
      <div className={styles.gridItem}>
        <Header
          title={WIDGET_INFO_TEXT.EMISSIONS.title}
          info={WIDGET_INFO_TEXT.EMISSIONS.info}
        />
        <Emissions data={fuelEmissions.emissionsData} />
      </div>

      <div className={styles.hidden}>
        <SetFuelTargetsDialog
          visible={fuelEmissions.setTargetFuelDataDialog.visible}
          vesselId={filters.vesselId}
          lastUpdate={fuelEmissions.setTargetFuelDataDialog.data?.lastUpdate}
          cancel={setFuelTargetsDialogCancel}
          save={setFuelTargetsDialogSave}
          hide={setFuelTargetsDialogHide}
        />
      </div>
    </div>
  );
}

export default FuelEmissions;
