import React from "react";
import styles from "./DataGridView.css";
import { Headers } from "./Headers";
import { Row } from "./Row";
import { Footer } from "./Footer";
import { Column } from "./Types";

export type DataGridViewProps<T> = {
  columns: Column<T>[];
  data: T[];
  idSelector: (dataItem: T) => string;
};

const maxRowHeightInRem = 5;
const DataGridView = <T,>({
  data,
  columns,
  idSelector,
}: DataGridViewProps<T>) => {
  const gridStyle = {
    gridTemplateColumns: columns.reduce(
      (total, _, i) => total + (i ? " max-content" : "1fr"),
      ""
    ),
    maxHeight: `${data.length * maxRowHeightInRem}rem`,
  };

  return (
    <div className={styles.gridContainer} style={gridStyle}>
      {columns && <Headers columns={columns} />}

      {data.map((row) => (
        <Row
          rowData={row}
          columns={columns}
          rowIdSelector={idSelector}
          key={`row_${idSelector(row)}`}
        />
      ))}
      <Footer data={data} columns={columns} />
    </div>
  );
};

export default DataGridView;
