import React, { useEffect } from "react";
import commonStyles from "../MainDashboard/MainDashboard.css";
import styles from "./FleetCIIScore.css";
import FleetCIIScoreSparkline from "./FleetCIIScoreSparkline";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../common/components/Loader";
import { fetchCIIScores } from "../../actions/action.fetchCIIScores";
import { organizeScores } from "./CIIScoreOrganizer";
import { OperationClassID } from "../../../common/operation/operationClassID";
import { CIIScore, CiiState, FleetCIIScoreProps } from "./Types";
import {
  operationClassToOperations,
  operationLegend,
} from "../../../common/operation/operationId";
import { useRefSize } from "../../../hooks/useRefSize";
import ComponentInfo from "../../../common/components/ComponentInfo/ComponentInfo";

const FleetCIIScore: React.FC<FleetCIIScoreProps> = ({
  group,
}: FleetCIIScoreProps) => {
  const ciiScores = useSelector((state: CiiState) => {
    return state.ciiScores;
  });

  function getOperationClassesToInclude(): OperationClassID[] {
    return [
      OperationClassID.DP,
      OperationClassID.Transit,
      OperationClassID.AHTS,
      OperationClassID.Fishing,
      OperationClassID.Jacking,
    ];
  }

  function getOperationModesToInclude(): string[] {
    const classes = getOperationClassesToInclude();
    const operationIds = classes
      .map((x) => {
        return operationClassToOperations[x];
      })
      .flat();

    return operationIds.map((operationId) => {
      return operationLegend[operationId];
    });
  }

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCIIScores(getOperationModesToInclude(), group));
  }, [group]);

  if (ciiScores === undefined || ciiScores.data === undefined) {
    return <Loader text={"Loading"} expand={true} />;
  } else {
    return <Content data={ciiScores.data} />;
  }
};

interface ContentProps {
  data: CIIScore[];
}

const Content = ({ data }: ContentProps) => {
  const [ref, width, height] = useRefSize<HTMLDivElement>();

  return (
    <ComponentInfo
      modalTargetId="popupContainer"
      title={toolTipHeader}
      text={toolTip}
    >
      <div className={styles.fleetCIIScoreActivity}>
        <div className={commonStyles.componentHeader}>Fleet CII Score</div>
        <div className={styles.sparkline} ref={ref}>
          <FleetCIIScoreSparkline
            data={organizeScores(data)}
            width={width}
            height={height}
            padding={50}
          />
        </div>
      </div>
    </ComponentInfo>
  );
};

const toolTipHeader = "Fleet CII Score";
const toolTip =
  "Shows the fleet wide CII scores aggregated per week over the last three years.";

export default FleetCIIScore;
